import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { ListGroup, Pagination, Nav, NavDropdown, Modal } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import SystemPrev from '../Systems/SystemPrev';
import '../../scss/pages/systems.scss';
import {ReactComponent as PlusIcon} from '../../images/icons/plus.svg';
import {ReactComponent as BaseballIcon} from '../../images/icons/sports/baseball.svg';
import {ReactComponent as FootballIcon} from '../../images/icons/sports/football.svg';
import FilterDropdown from '../custom/FilterDropdown';
import AuthProtected from '../HOCs/AuthProtected';

const MySystems = () => {
    // systems 
    const userFollowedSystems = useSelector((state) => state.user.followedSystems);
    const privateSystems = useSelector((state) => state.user.privateSystems);
    const nflPubSystems = useSelector((state)=> state.nfl.publicSystems);
    const mlbPubSystems = useSelector((state)=> state.mlb.publicSystems);
    const [filtSystems, setFiltSystems] = useState([]);
    const systemsPerPage = 5;
    const navigate = useNavigate();
    const [view, setView] = useState({val: "private", label: "My Systems"});
    const [page, setPage] = useState(1);
    const [sport, setSport] = useState("mlb");
    const [sportLabel, setSportLabel] = useState(<></>);
    const [pageCount, setPageCount] = useState(1);
    const [showCreateModal, setShowCreateModal] = useState(false);

    // show component once rendered to avoid FOUC
    useEffect(()=> {
        const page = document.getElementById('comp-container');
        page.classList.remove('hidden-until-css-load');
    },[]);

    const calcPages = () => {
        setPageCount(Math.ceil(filtSystems.length / systemsPerPage));
    };

    // function to filter systems based on view / sport
    const filterSystems = (sport, viewObj) => {
        const viewVal = viewObj.val;
        switch(viewVal){
            case("private"):
                setFiltSystems(privateSystems.filter(sys => sys.sport === sport));
                break;
            case("public"):
                let followedIds =  userFollowedSystems[sport].map((obj) => obj._id);;
                let tmpFiltered = []
                if(sport === "mlb"){
                    tmpFiltered = mlbPubSystems.filter((system) => !followedIds.includes(system._id));
                    setFiltSystems(tmpFiltered);
                }else if(sport === "nfl"){
                    tmpFiltered = nflPubSystems.filter((system) => !followedIds.includes(system._id));
                    setFiltSystems(tmpFiltered);
                }
                break;
            case("followed"):
                setFiltSystems(userFollowedSystems[sport]);
                break; 
            default: 
                break;
        };
    };

    // run whenever sport selection, view, public / followed systems are updated - filter systems accordingly
    useEffect(()=>{
        filterSystems(sport, view);
    }, [sport, view, userFollowedSystems, mlbPubSystems, nflPubSystems]);

    // calc pages whenevr filtered systems is set
    useEffect(()=>{
        calcPages();
        setPage(1);
    }, [filtSystems]);

    // when sport selection changes update the private, public, and followed systems and the sport label
    useEffect(()=>{
        if(sport==="mlb"){
            setSportLabel(<span id='sportLabel'>
                <BaseballIcon className='labelIcon'/>
                <span>MLB</span>
            </span>)
        }else if(sport==="nfl"){
            setSportLabel(<span id='sportLabel'>
                <FootballIcon className='labelIcon'/>
                <span>NFL</span>
            </span>)
        }
    },[sport])

    const handlePageChange = (value)=> {
        if(value < 1){
            setPage(1);
        }else if(value > pageCount){
            setPage(pageCount);
        }else{
            setPage(value);
        };
    };
    
    const handleViewChange = (value) =>{
        let label;
        if(value === "private"){
            label = "My Systems";
        }else if(value === "followed"){
            label = "Followed Systems";
        }else if(value === "public"){
            label = "Public Systems";
        };
        setView({val: value, label: label});
    };

    // Generate Pagination Items for private systems
    const pageItems = [];
    for(let i = 1; i <= pageCount; i++){
        pageItems.push(
            <Pagination.Item className="customPageItem" key={`private${i}`} active={i === page} onClick={() => handlePageChange(i, "private")}>{i}</Pagination.Item>
        );
    };
    const pagination = <Pagination>
        <Pagination.First className="customPageItem" onClick={()=>handlePageChange(1)}/>
        <Pagination.Prev className="customPageItem" onClick={()=>handlePageChange(page-1)} />
        {pageItems}
        <Pagination.Next className="customPageItem" onClick={()=>handlePageChange(page+1)}/>
        <Pagination.Last className="customPageItem" onClick={()=>handlePageChange(pageCount)}/>
    </Pagination>

    return (
        <div id='comp-container' className='full-page hidden-until-css-load'>
            <div className='page-section'>
                <Nav className='top_row'>
                    <NavDropdown id="sysViewSelect" title={view.label}>
                            <NavDropdown.Item  onClick={()=>handleViewChange("private")}>My Systems</NavDropdown.Item>
                            <NavDropdown.Item  onClick={()=>handleViewChange("followed")}>Followed Systems</NavDropdown.Item>
                            <NavDropdown.Item  onClick={()=>handleViewChange("public")}>Public Systems</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown id="sportSelection" title={sportLabel}>
                        <NavDropdown.Item id='baseball' className='sportSel' onClick={()=>setSport("mlb")}>
                            <BaseballIcon className='sportsIcon'/>
                            <span>MLB</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item id='football' className='sportSel' onClick={()=>setSport("nfl")}>
                            <FootballIcon className='sportsIcon'/>
                            <span>NFL</span>
                        </NavDropdown.Item>
                    </NavDropdown>


                    <PlusIcon id="plusIcon" className='button' onClick={()=>navigate('/system-builder', {state:{pageType:"new"}})}/>
                </Nav>
                {pagination}
                {view.val === "private" ? (
                <>
                    {filtSystems.length > 0 ? (
                        <ListGroup>
                            {filtSystems.slice((page-1) * systemsPerPage, page*systemsPerPage).map(system=>{
                                return(
                                    <SystemPrev system={system} key={system._id} sysType="private"/>
                                )
                            })}
                        </ListGroup> 
                    ):(
                        <div><h6>Looks like you have no private systems. <a className='reg-link' onClick={()=>navigate('/system-builder', {state:{pageType:"new"}})}>Create One</a></h6> </div>
                    )}
                </>
                ):null}
                {(view.val === "followed" && userFollowedSystems !== undefined) && (
                    <>
                        {filtSystems.length > 0 || !filtSystems ? (
                            <ListGroup>
                                {filtSystems.slice((page-1) * systemsPerPage, page*systemsPerPage).map(system=>{
                                    return(
                                        <SystemPrev system={system} key={system._id} sysType="followed"/>
                                    )
                                })}
                            </ListGroup>
                        ):(
                            <div><h6>Looks like you don't follow any systems. <a className='reg-link' onClick={()=>handleViewChange("public")}>View available systems</a></h6> </div>
                        )}
                    </>
                )}
                {view.val === "public" ? (
                    <>
                        {filtSystems.length > 0 ? (
                            <ListGroup>
                                {filtSystems.slice((page-1) * systemsPerPage, page*systemsPerPage).map(system=>{
                                    return(
                                        <SystemPrev system={system} key={system._id} sysType="public"/>
                                    )
                                })}
                            </ListGroup>
                        ): (
                            <div><h6>No public systems available.</h6></div>
                        )}
                    </>
                ):null}
            </div>
        </div>
    );
};

export default AuthProtected(MySystems);