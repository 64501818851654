import React, { useEffect, useState } from "react";
// services
import { useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { useLogout } from "../hooks/user-hooks";
// css and images
import '../scss/navs.scss';
import lightlogo from '../images/logos/lightlogo.svg';
import account from '../images/icons/account.svg';
import alert from '../images/icons/alert-black.svg';
import logout_icon from '../images/icons/logout.svg';
import faq from '../images/icons/faq.svg';
import tutorial from '../images/icons/tutorial.svg';
import build from '../images/icons/build-black.svg';
import upcoming from '../images/icons/upcoming.svg';
import about from '../images/icons/about.svg';
import caret from '../images/icons/caret-black.svg';

//components 
import { Navbar, NavDropdown, Nav, Image, Container, Offcanvas} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AccessControl from './AccessControl';

const NavBar = () => {
  const logout = useLogout();
  const matches = useSelector((state) => state.user.matches);
  const permissions = useSelector((state) => state.user.permissions);
  const loggedIn = useSelector((state)=> state.user.loggedIn);
  const [show, setShow] = useState(false)
  const navigate = useNavigate();

  const handleBtnClick = (route) => {
    setShow(false);
    navigate(route)  
  };
  const handleLogout = ()=>{
    setShow(false);
    logout();
  }
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);

  // Determine if the navbar is in collapsed state based on window width
  useEffect(() => {
    const handleResize = () => {
      // This assumes the collapsing point is at 992px (Bootstrap's 'lg' breakpoint)
      setIsNavbarCollapsed(window.innerWidth < 992);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Navbar expand="lg" className="main-nav" collapseOnSelect sticky="top">
      <Container fluid>
        <Navbar.Brand><Image src={lightlogo} width="auto" height="55px" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" id="menuBtn" onClick={()=>setShow(true)}/>
        <Navbar.Offcanvas placement="end" id="offcanvasNavbar" show={show}>
          <Offcanvas.Header>
            <Image src={lightlogo} width="auto" height="55px" />
            <button type="button" className="btn-close" aria-label="Close" onClick={()=>setShow(false)}/>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="links justify-content-end flex-grow-1 pe-3">
              <div className="navLinks">
                <LinkContainer className="navLink" to='/about' id="nav-about">
                  <Nav.Link onClick={()=>setShow(false)}>
                    {isNavbarCollapsed && <Image src={about} className="nav-icon" width="25px" height="auto"  />}
                    About
                  </Nav.Link>
                </LinkContainer>
                {loggedIn && (
                  <>
                    <LinkContainer className="navLink" to="/systems" id="nav-systems">
                      <Nav.Link onClick={()=>setShow(false)}>
                        {isNavbarCollapsed && <Image src={build} className="nav-icon" width="25px" height="auto"  />}
                        Systems
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer className="navLink" to="/upcoming-games" id="nav-upcoming">
                      <Nav.Link onClick={()=>setShow(false)}>
                        {isNavbarCollapsed && <Image src={upcoming} className="nav-icon" width="25px" height="auto"  />}
                        Upcoming{matches.length > 0? <span id="dotBadge"></span>: null}
                      </Nav.Link>
                    </LinkContainer>                  
                    <AccessControl className="navLink" permissions={permissions} allowedPermissions={["admin"]} protectedResource={
                      <LinkContainer to="/admin" id="nav-admin">
                        <Nav.Link onClick={()=>setShow(false)}>Admin</Nav.Link>
                      </LinkContainer>
                    } />
                    <NavDropdown
                      title={
                        <>
                          {isNavbarCollapsed && <Image src={account} className="nav-icon" width="25px" height="auto"  />}
                          Account
                          <Image 
                            src={caret} 
                            className="nav-icon" 
                            width="15px" 
                            height="auto"
                          />
                        </>
                        } 
                        id="account-dropdown"
                      >
                      <LinkContainer to="/settings">
                        <NavDropdown.Item onClick={() => setShow(false)}>
                          <Image src={alert} className="nav-icon" width="20px" height="auto" />
                          Notifications
                        </NavDropdown.Item>
                      </LinkContainer>
                      <NavDropdown.Item onClick={handleLogout}>
                        <Image src={logout_icon} className="nav-icon" width="19px" height="auto" />
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                <LinkContainer className="navLink" to="/tutorials" id="nav-tutorials">
                  <Nav.Link onClick={()=>setShow(false)}>
                    {isNavbarCollapsed && <Image src={tutorial} className="nav-icon" width="25px" height="auto"  />}
                    Tutorials
                  </Nav.Link>
                </LinkContainer>  
                <LinkContainer className="navLink" to="/faq" id="nav-faq">
                  <Nav.Link onClick={()=>setShow(false)}>
                    {isNavbarCollapsed && <Image src={faq} className="nav-icon" width="25px" height="auto"  />}
                    FAQ
                  </Nav.Link>
                </LinkContainer>  
              </div>
              <div className="navButtons">
                {!loggedIn && 
                (
                  <>
                    <button className='primaryBtn loginSignupBtn' onClick={() => handleBtnClick('/signup')}>Sign Up</button>
                    <button className='secondaryBtn loginSignupBtn' onClick={() => handleBtnClick('/signin')}>Log In</button>    
                  </>
                )}
              </div>

            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default NavBar;