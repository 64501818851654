import React from 'react'
import {nflTeams} from '../../../utils/nflUtils';
import {formatDatetimeString} from '../../../utils/general';

const GamePrev = (props) => {
    const sport = props.sport;
    const game_id = props.game_id;
    const game = props.game
    let sportLabel = '';
    let home_team, away_team = '';


    function formatDateString(dateString) {
        const days = ['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const [year, month, day] = dateString.split("-");
        const date = new Date(Date.UTC(year, month - 1, day));
    
        const dayName = days[date.getDay()];
        const monthUTC = date.getUTCMonth() + 1;  // JavaScript months are 0-indexed.
        const dayUTC = date.getUTCDate();
    
        return `${dayName}, ${monthUTC}-${dayUTC}`;
    }

    if(sport === "mlb"){
        const dateLabel = formatDateString(game.date)

        sportLabel = 'MLB';
        home_team = game.home.team;
        away_team = game.away.team; 
        return(
            <div key={game_id} className={`gameItem-${sport}`} onClick={props.onClick}>
                {props.match_count !== null && (<span className='matchesBadge'>{props.match_count}</span>)}
                <div className='sport'>
                    <h5>{sportLabel}</h5> 
                    <h5>{dateLabel}</h5>
                </div>
                <h2 className='away'>{away_team}</h2>
                <h2 className='at'>@</h2>
                <h2 className='home'>{home_team}</h2>
            </div>  
        )
    }else if(sport === "nfl"){
        const dt_object = formatDatetimeString(game.date)

        sportLabel = 'NFL';
        home_team = nflTeams[game.home.team_id];
        away_team = nflTeams[game.away.team_id]; 
        return(
            <div key={game_id} className={`gameItem-${sport}`} onClick={props.onClick}>
                {props.match_count !== null && (<span className='matchesBadge'>{props.match_count}</span>)}
                <h4 className='away'>{away_team}</h4>
                <h4 className='at'>@</h4>
                <h4 className='home'>{home_team}</h4>
                <div className='info'>
                    <h6>{dt_object.date}</h6>
                    <h6>{dt_object.time}</h6>
                </div>
            </div>  
        )
    };



}

export default GamePrev