import React, {useState, useEffect} from 'react';
import {Form, FloatingLabel, Row, Col, Button, Image, ToggleButtonGroup, ToggleButton, OverlayTrigger, Popover} from 'react-bootstrap';
import NflWizard from './NflWizard';
import NflCustomCrit from './NflCustomCrit';
import write from '../../../images/icons/sm-write.svg';
import close from '../../../images/icons/noun_Close_3850069.svg';
import {ReactComponent as HelpIcon} from '../../../images/icons/help.svg';
import {ReactComponent as PlusIcon} from '../../../images/icons/plus.svg';

const NflCriteria = (props) => {
    const [disp, setDisp] = useState("view");
    const [critStep, setCritStep] = useState(1);
    const [type, setType] = useState("");
    const [critFormType, setCritFormType] = useState(null);
    const [statLabel, setStatLabel] = useState("");
    const [stat, setStat] = useState("");
    const [stat2Label, setStat2Label] = useState("");
    const [stat2, setStat2] = useState("");
    const [comp, setComp] = useState("");
    const [compLabel, setCompLabel] = useState("");
    const [cond, setCond] = useState("");
    const [op, setOp] = useState("");
    const [opLabel, setOpLabel] = useState("");
    const [critLabel, setCritLabel] = useState("");
    const offStats = {
        first_downs: "offensive first downs",
        rushing: {
            att: "rushing attempts",
            yds: "rushing yards",
            tds: "rushing tds",
            long: "longest rush",
            first_downs: "first downs rushing",
            ybc: "yards before contact",
            yac: "yards after contact",
            broken_tackles: "broken tackles"
        },
        passing: {
            att: "passing attempts",
            comp: "passing completions",
            yds: "passing yards",
            tds: "passing touchdowns",
            ints: "interceptions thrown",
            net_yds: "net passing yards",
            pass_rating: "pass rating",
            long: "longest pass",
            first_downs: "first downs passing",
            intended_air_yds: "intended air yards",
            comp_air_yds: "completed air yards",
            yac: "yards after catch",
            poor_throws: "poor throws",
            blitzed: "qb times blitzed",
            hurried: "qb times hurried",
            hits_taken: "qb hits taken",
            pressured: "qb times pressured",
            scrambles: "scrambles",
            scramble_yds: "scramble yards",
            dropbacks: "dropbacks",
            spikes_throwaways: "spikes and throwaways"
        },
        sacks: {
            taken: "sacks taken",
            yds_lost: "yards lost"
        },
        total_yds: "total offensive yards",
        fumbles: "fumbles",
        fumbles_lost: "fumbles lost", 
        turnovers: "turnovers",
        third_downs: {
            att: "third down attempts", 
            conv: "third down conversions"
        },
        fourth_downs: {
            att: "fourth down attempts", 
            conv: "fourth down conversions"
        },
        possession: "minutes of possession",
        receiving: {
            ybc: "receiving yards before contact",
            yac: "receiving yards after contact",
            broken_tackles: "broken tackles",
            first_downs: "first downs receiving",
            drops: "passes dropped"
        }
    };
    const defStats = {
        passing: {
            ints: "defensive interceptions",
            int_yds: "interception yards returned",
            int_tds: "pick sixes",
            int_long: "longest interception return",
            passes_defended: "passes defended"
        },
        tackles: {
            sacks: "defensive sacks",
            solo: "solo tackles",
            assists: "assisted tackles",
            for_loss: "tackles for a loss",
            qb_hits: "defensive qb hits"
        },
        fumbles: {
            recovered: "fumbles recovered",
            yds_ret: "fumble yards returned",
            tds: "fumbles returned for a touchdown",
            forced: "fumbles forced"
        },
        player_blitzes: "players sent on a blitz",
        player_hurries: "total players who hurried the qb",
        qb_knockdowns: "qb knockdowns",
        player_pressures: "total players who pressured the qb",
        missed_tackles: "missed tackles"
    };
    const stStats = {
        kicking: {
            punt: "punts", 
            punt_yds: "punt yards",
            punt_long: "longest punt",
            xpm: "extra points made",
            xpa: "extra points attempted",
            fgm: "field goals made",
            fga: "field goals attempted"
        }
    };

    // function to add crit
    const newCrit = ()=>{
        props.setUpdateIdx(-1);
        setDisp("add");
    };

    // function to change criteria step 
    const changeStep = (val) => {
        let tmpStep = critStep;
        tmpStep += val;
        setCritStep(tmpStep)
    }

    // function to handle cancelling of criteria form
    const clearCrit = ()=>{
        setType("");
        setStat("");
        setStatLabel("");
        setStat2("");
        setStat2Label("");
        setComp("");
        setCond("");
        setOp("");
        setDisp("view");
        setCritStep(1);
        setCritFormType(null)
    };

    // function to set crit form type and 
    const handleCritFormType = (type) => {
        setCritFormType(type);
        changeStep(1);
    };

    // function to handle stat selection
    const handleStatChange = (stat_num, stat_, statLab) =>{
        // set stat1 or stat2
        if(stat_num === 1){
            setStat(stat_);
            setStatLabel(statLab);
        }else if(stat_num === 2){
            setStat2(stat_);
            setStat2Label(statLab);
        };
    };

    // function to handle attempt to submit
    const submit = ()=> {
        const crit = {
            label: critLabel,
            stat: stat,
            stat2: stat2, 
            op: op, 
            comp: comp, 
            cond: cond
        }
        props.addCriteria(crit, props.updateIdx);
        clearCrit();
        setDisp("view");
    };

    // // function to edit crit
    // const editCrit = (crit, i) => {
    //     if(crit.stat2 !== ""){
    //         setType("two-stats");
    //     };
    //     setOp(crit.op);
    //     setStat(crit.stat);
    //     setStatLabel(crit.statLabel);
    //     setStatRef(crit.statRef);
    //     setStat2(crit.stat2);
    //     setStat2Label(crit.stat2Label);
    //     setStat2Ref(crit.stat2Ref);
    //     setComp(crit.comp);
    //     setCond(crit.cond);
    //     props.setUpdateIdx(i);
    //     setDisp("add");
    // };

    return (
        <div id="nflCriteriaDiv">
            <div id="header-row">
                <div className='left-align'>
                    <h5>System Criteria</h5>
                    <OverlayTrigger trigger="click" placement='bottom' rootClose={true}
                        overlay={
                        <Popover>
                            <Popover.Header>Criteria FAQs</Popover.Header>
                            <Popover.Body>
                                <p>How do the stats work when backtesting?</p> 
                                <ul>
                                    <li>When backtesting your systems we look at every game individually, using the season averages up to that point in the season.</li>
                                    <li>E.g. if looking at the home team's homeruns, and it was their 5th game - we would look at the average from their 4 prior games to compare against your condition.</li>
                                </ul>
                                <p>What does the operation field do?</p> 
                                <ul>
                                    <li>The operation field will either look at the difference between, or the sum of, the two statistics you choose.</li>
                                </ul>
                                <p>What is the condition field and why does it look weird?</p> 
                                <ul>
                                    <li>The condition field is what allows you to find the games you would like to bet on.</li>
                                    <li>Examples: 
                                        <ul>
                                            <li>you could look at games where the home team averages at least 3 more runs than the away team.</li>
                                            <li>you could also look at games where both teams combines average at least 2 more runs than the total for the game. (total+2)</li>
                                        </ul>
                                    </li>
                                </ul>
                            </Popover.Body>
                        </Popover>
                        }
                    >  
                        <HelpIcon className="button" id="help-crit"/>
                    </OverlayTrigger>
                </div>
                <div className='right-align'>
                    <PlusIcon className="button" id="addCritBtn" onClick={()=>newCrit()} />
                </div>
            </div>
            {disp === "add" ?
                (
                    <NflCustomCrit 
                        type={type} setType={setType} 
                        op={op} setOp={setOp} opLabel={opLabel} setOpLabel={setOpLabel}
                        stat={stat} statLabel={statLabel} 
                        stat2={stat2} stat2Label={stat2Label} 
                        handleStatChange={handleStatChange}
                        critLabel={critLabel}setCritLabel={setCritLabel}
                        comp={comp} setComp={setComp} setCompLabel={setCompLabel} compLabel={compLabel}
                        cond={cond} setCond={setCond}
                        offStats={offStats} defStats={defStats} stStats={stStats}
                        clearCrit={clearCrit} submit={submit}
                    />                 
                    // <div id="addCritDiv">
                    //     {critStep === 1 && 
                    //         <>
                    //             <h6>How do you want to build your criteria?</h6>
                    //             <Button className='secondaryBtn' onClick={()=>{handleCritFormType("wizard")}}>Use criteria wizard</Button>
                    //             <Button className='secondaryBtn' onClick={()=>{handleCritFormType("custom")}}>Create custom criteria</Button>                        
                    //         </>
                    //     }
                    //     {critStep === 2 && (
                    //         <>
                    //           {critFormType === "wizard" && <NflWizard />} 
                    //         </>  
                    //     )}
                    //     <div id='submit-btns'>
                    //         <Button className="secondaryBtn" id="cancelCritBtn" onClick={()=>clearCrit()}>Cancel</Button>
                    //         <Button className="primaryBtn" id="saveCritBtn" onClick={()=>submit()}>Save Criteria</Button>
                    //     </div>
                    // </div>       
                )
                :
                (
                    <div id='critList'>
                        {props.criteria.map((crit,i)=>{
                            return(
                            <div key={i} className="criteria">
                                <Row key={i}>
                                    <Col>{crit.label}</Col>
                                    {/* <Col xs={2} s={1}>
                                        <Image src={write} width="15" height="auto" onClick={()=>editCrit(crit, i)}/>
                                    </Col> */}
                                    <Col xs={2} s={1}>
                                        <Image src={close} width="15" height="auto" onClick={()=>props.removeCrit(i)}/>
                                    </Col>
                                </Row>
                            </div>
                            )
                        })}
                    </div>
                )
            }
            
        </div>
    )
}

export default NflCriteria