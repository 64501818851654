import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    sports: [],
    leagues:[],
    missing_odds:[],
    nfl_missing_odds:[],
    info: {
        upcoming_timestamp: null
    }
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers:{
        setSports: (state, action) => {
            state.sports = action.payload 
        },
        setLeagues: (state, action) =>{
            state.leagues = action.payload
        },
        setMissingOdds: (state, action) =>{
            state.missing_odds = action.payload
        },
        setNflMissingOdds: (state, action) =>{
            state.nfl_missing_odds = action.payload
        },
        setAppInfo: (state, action) =>{
            state.info = action.payload
        }
    }
});

export const { setSports, setLeagues, setMissingOdds, setNflMissingOdds, setAppInfo } = appSlice.actions;
export default appSlice.reducer;