import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Form, FloatingLabel} from 'react-bootstrap';
import styles from '../../scss/pages/loginSignup.module.scss';

const SignUpForm = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);
  const [errs, setErrs] = useState("");
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  
  // function to validate form fields 
  const validateForm = (event)=>{
    const form = event.target;
    if(form.checkValidity() === false){
      event.preventDefault();
      event.stopPropagation();
    }else{
      handleSubmit(event)
    }
    setValidated(true);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    // body of post request
    const body = {
      username:username, 
      password: password,
      email: email
    };

    //api parameters to get sys matches
    const url = 'https://api.lineleaders.net/accounts/signup';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    };

    let signUpProm = await fetch(url, options);
    let result = await signUpProm.json();  
    // handle response 
    if(result.ok === true){
      setStep(2);
    }else if(result.ok === false){
      let e = String(result.error)
      if(e.includes("UsernameExistsException")){  
        setErrs("This username is already taken")
      }else if(e.includes("Email address is already in use")){
        setErrs("Email address is already in use")
      }else if(e.includes("InvalidPasswordException")){
        setErrs("Invalid password, please make sure all criteria are met")
      };
    };
  };
  switch(step){
    case 1:
      return (
        <>
          <Form onSubmit={validateForm} validated={validated} noValidate className={styles.SignUpForm}>
            <FloatingLabel className={styles.formField} label='Username'>
              <Form.Control type="text" id="username" minLength={4} required value={username} onChange={(event) => setUsername(event.target.value)} placeholder="Enter Username"/>
              <Form.Control.Feedback type="invalid">Username must be at least 4 characters.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel className={styles.formField} label="Email">
              <Form.Control type="email" id="email" required value={email} onChange={(event) => setEmail(event.target.value)} placeholder="Email Address"/>
              <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel className={styles.formField} label="Password">
              <Form.Control type="password" id="pass" required pattern="(?=.*\d)(?=.*[!@#$%^&?*])(?=.*[a-z])(?=.*[A-Z]).{8,}"  value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Password"/>
              <Form.Control.Feedback type="invalid">Password must contain at least 8 characters, including uppercase and lowercase letters, numbers, and special characters.</Form.Control.Feedback>
            </FloatingLabel>
            <Form.Check className={styles.formField} type="checkbox" required id='accept'
              label={<span>I accept these <a className={styles.pageLink} onClick={()=>{navigate('/tos')}}>terms and conditions</a> </span>}
            />
            <div className='invalid-feedback' style={{display:"block"}}>{errs}</div>
            <button className={`primaryBtn ${styles.loginSignupPageBtn}`} type="submit">Next</button>
          </Form>
          <div className={styles.footer}>
            <p>Already have an account? <a className={styles.pageLink} onClick={()=>navigate("/signin")}>Sign In</a></p>
          </div>
        </>
      );
    case 2:
      return (
        <div>
          <h5>Thank you for signing up!</h5>
          <h2>Verify your email address</h2>
          <p>Make sure to check your junk/spam folders if you do not see the confirmation email shortly.</p>
            <button className={`primaryBtn ${styles.loginSignupPageBtn}`}  onClick={()=>{navigate('/signin')}}>Sign In</button>
        </div>
      );
  };
};

export default SignUpForm;