import React from 'react';

const ResultsOverview = ({resultSets, strNumGames, changeNumGames}) => {

    if(resultSets && resultSets.hasOwnProperty('type')){
        const overviewObj = {
            "last5": 'L5',
            "last10": 'L10',
            "last15": 'L15',
            "last20": 'L20',
            "last30": 'L30'
        };

        const setNumGamesFromStr = (val) => {
            const numGames = parseInt(val.slice(1));
            changeNumGames(numGames);
        };

        return(
            <>  
                <div id='resultLabels'>
                    {   resultSets.type === 'inning-runs' ? 
                        (
                            <div id='inning-labels'>
                                <span id='split'>Split</span>
                                <div id='labels-div'>
                                    <span id='team-label'>Team</span>
                                    <span id='record-label'>Record</span>
                                    <span id='hitrate-label'>Hit Rate</span>
                                    <span id='avg-label'>Avg Runs</span>
                                </div>
                            </div>
                        ) :
                        resultSets.type === 'total-runs' ? 
                        (
                            <div id='totals-labels'>
                                <span id='split'>Split</span>
                                <div id='labels-div'>
                                    <span id='record-label'>Record</span>
                                    <span id='hitrate-label'>Hit Rate</span>
                                    <span id='team-label'>Team</span>
                                    <span id='avg-label'>Avg Runs</span>
                                </div>
                            </div>
                        ) :
                        resultSets.type === 'runline' && 
                        (
                            <div id='runline-labels'>
                                <span id='split'>Split</span>
                                <div id='labels-div'>
                                    <span id='record-label'>Record</span>
                                    <span id='hitrate-label'>Hit Rate</span>
                                    <span id='avg-label'>Avg ATS</span>
                                </div>
                            </div>
                        )

                    }

                </div>
                <div id='resultSets'>
                    {Object.entries(overviewObj).map( ([gamesKey,gamesVal]) => {
                        const selectedClass = gamesVal === strNumGames ? 'selected' : '';
                        switch(resultSets.type){
                            case 'inning-runs':
                                return(
                                    <div key={gamesKey} className={`${gamesKey}-games inning-overview ${selectedClass}`} onClick={()=>setNumGamesFromStr(gamesVal)}>
                                        <h5 className='num-games'>{gamesVal}</h5>
                                        <div className='inning-data'> 
                                            {Object.entries(resultSets).map( ([teamKey,teamObj]) => {
                                                return( !['type', 'subType'].includes(teamKey) ) && (
                                                    <div className='team-div' key={`${gamesKey}-${teamKey}`}>
                                                        <span className='team-data'>{teamKey}</span>
                                                        <span className='record-data'>{teamObj[gamesKey].results.record}</span>
                                                        <span className='hitrate-data'>{`${teamObj[gamesKey].results.winPer}%`}</span>
                                                        <span className='avg-data'>{teamObj[gamesKey].average}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                );
                            case 'total-runs':
                                return(
                                    <div key={gamesKey} className={`${gamesKey}-games totals-overview  ${selectedClass}`} onClick={()=>setNumGamesFromStr(gamesVal)}>
                                        <h5 className='num-games'>{gamesVal}</h5>
                                        <div className='totals-data'>
                                            <span className='record-data'>{resultSets[gamesKey].results.record}</span>
                                            <span className='hitrate-data'>{`${resultSets[gamesKey].results.winPer}%`}</span>
                                            {Object.entries(resultSets[gamesKey].average).map( ([teamKey,teamAvgVal]) => {
                                                return(
                                                    <div className='team-div' key={`${gamesKey}-${teamKey}`}>
                                                        <span className='team-data'>{teamKey}</span>
                                                        <span className='avg-data'>{teamAvgVal}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                );
                            case 'runline':
                                return(
                                    <div key={gamesKey} className={`${gamesKey}-games runline-overview  ${selectedClass}`} onClick={()=>setNumGamesFromStr(gamesVal)}>
                                        <h5 className='num-games'>{gamesVal}</h5>
                                        <div className='runline-data'>
                                            <span className='record-data'>{resultSets[gamesKey].results.record}</span>
                                            <span className='hitrate-data'>{`${resultSets[gamesKey].results.winPer}%`}</span>
                                            <span className='avg-data'>{resultSets[gamesKey].average}</span>
                                        </div>
                                    </div>
                                );
                            default:
                                break; 
                        };  
                    })}
                </div>
            </>
        );
    }else{
        return(
            <>
                <div className='last5-games'></div>
                <div className='last10-games'></div>
                <div className='last15-games'></div>
                <div className='last20-games'></div>
                <div className='last30-games'></div>
            </>
        );
    };
};

export default ResultsOverview;