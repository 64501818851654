// calculate profit for american odds
const calcProfitAm = (odds, units)=>{
    let prof = 0; 
    if(odds > 0){
        prof = (units*odds)/100;
    }else if(odds < 0){
        prof = (units/Math.abs(odds))*100;
    }else{
        prof = units;
    };
    return prof;
}

export default calcProfitAm; 