import React, {useEffect, useState} from 'react'
import { useClearUserSession } from '../../hooks/user-hooks';
import { Navigate  } from 'react-router-dom';
import { refreshSession } from '../../utils/apiService';

const decodeToken = (token) => {
    // Split the JWT into its three parts: Header, Payload, and Signature
    const parts = token.split('.');

    // Decode the payload from Base64Url to a JSON string
    const payload = parts[1];
    const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));

    // Parse the JSON string to an object
    const payloadObj = JSON.parse(decodedPayload);

    return payloadObj;
};

const tokenExpired = (token) => {
    // check if token is expired
    const isExpired = token.exp * 1000 <= Date.now();
    return isExpired;
}; 

const AuthProtected = (LowerComponent) => { 
    // Define a new functional component inside AuthProtected
    const WrappedComponent = (props) => {
        const [authenticated, setAuth] = useState(false);
        const clearSession = useClearUserSession();
        const [isCheckingAuth, setIsCheckingAuth] = useState(true);

        useEffect(()=>{
            const checkAuth = async () => {
                // Get access token 
                const accessToken = localStorage.getItem("access_token");
                if (accessToken) {
                    // Check if token is expired
                    try {
                        const decodedAccess = decodeToken(accessToken);
                        const accessExpired = tokenExpired(decodedAccess);
                        if (!accessExpired) {
                            setAuth(true);
                        }else{
                            // Check for refresh token 
                            const refreshToken = localStorage.getItem("refresh_token");
                            if(refreshToken){
                                const sessionRefreshed = await refreshSession();
                                // if refreshed, set auth to true - otherwise clear session
                                if(sessionRefreshed){
                                    setAuth(true);
                                }else clearSession();
                            }else{
                                clearSession();
                            }
                        }
                    }catch(error){
                        clearSession();
                    }
                }else{
                    setAuth(false);
                };
                setIsCheckingAuth(false);
            };

            checkAuth();
        }, []);

        if(isCheckingAuth){
            return null;
        }else if(authenticated){
            return <LowerComponent {...props}/>;
        }else{
            return <Navigate to="/signin" replace={true}/>;
        };
    };

    return WrappedComponent;
};

export default AuthProtected;