import React, {useState, useEffect} from 'react';
import {Row, Navbar, Nav} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {setMissingOdds} from '../../../redux/applicationSlice';

import AuthProtected from '../../HOCs/AuthProtected';
import FailedGamelogs from './FailedGamelogs';
import FailedOdds from './FailedOdds';
import MissingOdds from './MissingOdds';
import MissingNFLOdds from './MissingNFLodds';
import apiService from '../../../utils/apiService';
import { useClearUserSession } from '../../../hooks/user-hooks';
import { useNavigate } from 'react-router-dom';

const AdminPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clearSession = useClearUserSession();
    const [component, setComponent] = useState(<></>)
    const permissions = useSelector(state=>state.user.permissions)
    const [permitted, setPermitted] = useState(false)
    const [failedGamelogs, setFailedGamelogs] = useState([]);
    const [failedOdds, setFailedOdds] = useState([]);

    // make sure user has correct permissions 
    useEffect(()=>{
        let perm = false;
        for(let i = 0; i < permissions.length; i++){
            if(permissions[i] === "admin"){
                perm = true;
                break; 
            };
        }
        setPermitted(perm)
    },[permissions])

    // // function to fetch failed gamelogs
    // const fetchFailedGamelogs = async() =>{
    //     const response = await apiService('mlb/failed-gamelogs')
    //     if(response !== 'Server Error' && response !== 'Session Expired'){
    //         setFailedGamelogs(response);
    //     }else{ // else redirect to login and clear user data
    //         clearSession();
    //         navigate('/signin');
    //     };
    // };

    // // function to fetch failed odds
    // const fetchFailedOdds = async() =>{
    //     const response = await apiService('mlb/failed-odds')
    //     if(response !== 'Server Error' && response !== 'Session Expired'){
    //         setFailedOdds(response);
    //     }else{ // else redirect to login and clear user data
    //         clearSession();
    //         navigate('/signin');
    //     };
    // };

    // function to fetch failed odds
    const fetchMlbMissingOdds = async() =>{
        const response = await apiService('mlb/missing-odds')
        if(response !== 'Server Error' && response !== 'Session Expired'){
            dispatch(setMissingOdds(response));
        }else{ // else redirect to login and clear user data
            clearSession();
            navigate('/signin');
        };
    };

    // fetch failed odds and gamelogs
    useEffect(()=>{
        // fetchFailedGamelogs();
        // fetchFailedOdds();
        fetchMlbMissingOdds();
    },[])



    const close = () => {
        setComponent(<></>)
    }

    return (
        <div className='page-section'>
            <Row>
                {!permitted ?
                    (<h4>Access Denied</h4>) 
                        :
                    (<Navbar>
                        <Nav>
                            <Nav.Item>
                                <Nav.Link onClick={()=>setComponent(<FailedGamelogs games={failedGamelogs}/>)}>Failed Gamelogs</Nav.Link>
                           </Nav.Item>
                           <Nav.Item>
                                <Nav.Link onClick={()=>setComponent(<FailedOdds games={failedOdds}/>)}>Failed Odds</Nav.Link>
                           </Nav.Item>
                           <Nav.Item>
                                <Nav.Link onClick={()=>setComponent(<MissingOdds/>)}>Missing Odds</Nav.Link>
                           </Nav.Item>
                           <Nav.Item>
                                <Nav.Link onClick={()=>setComponent(<MissingNFLOdds/>)}>Missing NFL Odds</Nav.Link>
                           </Nav.Item>
                        </Nav>
                    </Navbar>
                    )
                }

            </Row>
            <Row>   
                {component}        
            </Row>
        </div>
    )

}

export default AuthProtected(AdminPage);