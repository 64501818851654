import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
import RoiChart from './RoiChart';
import {ReactComponent as Forward} from '../../images/icons/forward.svg';
import {ReactComponent as RedCaret} from '../../images/icons/caret-red.svg';
import {ReactComponent as GreenCaret} from '../../images/icons/caret-green.svg';

const SystemPrev = (props) => {
    const system = props.system;
    const navigate = useNavigate();
    // run whenever system is set, add red / green classes to Win / ROI 
    useEffect(()=>{
        // get elements 
        let winDiv = document.getElementById(`win-div-${system._id}`);
        let roiDiv = document.getElementById(`roi-div-${system._id}`);
        let winCaret = document.getElementById(`win-caret-${system._id}`);
        let roiCaret = document.getElementById(`roi-caret-${system._id}`);

        // if Win is under 55%, color red, otherwise green
        if(system.roi !== "NaN"){
            if(system.w/(system.w+system.l)*100 > 55){
                winDiv.classList.add("green")
                winCaret !== null && winCaret.classList.add("up-caret")
            }else{
                winDiv.classList.add("red");
                winCaret !== null && winCaret.classList.add("down-caret")
            };

            // if ROI is + color green, otherwise red
            if(system.roi > 0){
                roiDiv.classList.add("green")
                roiCaret !== null && roiCaret.classList.add("up-caret")
            }else{
                roiDiv.classList.add("red");
                roiCaret !== null && roiCaret.classList.add("down-caret")
            } 
        };
    },[system]);

    return (
    <div className="systemPrev">
        <div className='sysHeader'>
            <h4>{system.name}</h4>
            <Forward className="button" onClick={()=>navigate(`/system/${system._id}`, {state: { system, sysType: props.sysType }})}/>
        </div>
        <div className="bet-div">
            <h5>Bet</h5> 
            {system.bet}
        </div>
        <div className="record-div">
            <h5>Record</h5> 
            {system.p > 0 ? (system.w +"-"+system.l+"-"+system.p) : (system.w+"-"+system.l)}
        </div>
        <div className="crit-div">
            <h5>Criteria</h5> 
            {system.criteria.length > 1 ?
                (
                    <>
                        <span>{system.criteria[0].label}</span><br/>
                        <span>+{system.criteria.length -1} Criteria</span>
                    </>
                )
                :
                (
                    <span>{system.criteria[0].label}</span>
                )
            }
        </div>
        {system.roi !== "NaN" && <RoiChart system={system} />}
        <div id={`win-div-${system._id}`} className="win-div">
            {system.w/(system.w+system.l)*100 > 55 && <GreenCaret id={`win-caret-${system._id}`} alt=""/>}
            <div>
                <h5>Win</h5>
                {system.roi !== "NaN" ? (system.w/(system.w+system.l)*100).toFixed(2) : 0}%
            </div>
            {system.w/(system.w+system.l)*100 < 55 && <RedCaret id={`win-caret-${system._id}`} alt=""/>}

        </div>
        <div id={`roi-div-${system._id}`} className="roi-div">
            
            {system.roi > 0 && <GreenCaret id={`roi-caret-${system._id}`} alt=""/>}
            <div>
                <h5>Roi</h5>
                <span>{system.roi !== "NaN" ? system.roi : 0}% </span>
            </div>
            {system.roi < 0 && <RedCaret id={`roi-caret-${system._id}`} alt=""/>}
        </div>
    </div>
    )

}

export default SystemPrev