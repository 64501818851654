import { useState } from 'react';
import {Form, FloatingLabel} from 'react-bootstrap'
import styles from '../../scss/pages/loginSignup.module.scss';

const ResetPasswordForm = (props) => {
  const [isValid, setIsValid] = useState(false);

  const validateEmail = (e) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      props.setEmail(e.target.value);
      setIsValid(emailRegex.test(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
  };

  return (
    <Form onSubmit={(e)=>handleSubmit(e)} noValidate className={styles.resetPassForm}>
      <FloatingLabel label='Email' className={styles.formField}>
        <Form.Control type="text" id="email" minLength={4} required value={props.email} onChange={validateEmail} placeholder="Enter Password"/>
      </FloatingLabel>
      <a className={`${styles.pageLink} ${styles.footer}`} onClick={()=>props.navigate('/signin')}>Return To Login</a>   
      <div className='invalid-feedback' style={{display:"block"}}>{props.err}</div>
      <button className={`primaryBtn ${styles.loginSignupPageBtn}`} disabled={!isValid} type="submit">Send Email</button> 
    </Form>
  );
};

export default ResetPasswordForm;