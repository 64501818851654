import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    id: null, 
    permissions: [],
    privateSystems: [],
    followedSystems: {},
    matches:[],
    user: null,
    loggedIn: false,
    emailSub: false
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers:{
        setUserId: (state, action) => {
            state.id = action.payload
        },
        setPermissions: (state, action) =>{
            state.permissions = action.payload
        },
        setPrivateSystems: (state, action) =>{
            state.privateSystems = action.payload
        },
        setFollowedSystems: (state, action) =>{
            state.followedSystems = action.payload
        },
        setMatches:(state, action) =>{
            state.matches = action.payload
        },
        setUser:(state, action)=>{
            state.user = action.payload
        },
        setLoggedIn:(state, action)=>{
            state.loggedIn = action.payload
        },
        setEmailSub:(state, action)=>{
            state.emailSub = action.payload
        }
    }
});

export const { setUserId, setPermissions, setPrivateSystems, setFollowedSystems, setMatches, setUser, setLoggedIn, setEmailSub} = userSlice.actions;
export default userSlice.reducer;
