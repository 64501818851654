import React, { useState, useEffect } from 'react'
import { Form, FloatingLabel } from 'react-bootstrap';

const FavoriteUnderdog = (props) => {
    const [selection, setSelection] = useState("");
    const [critVal, setCritVal] = useState(null);
    const [err, setErr] = useState(false);
    const [initSel, setInitSel] = useState(null);
    const [critIdx, setCritIdx] = useState(null);
    const critValues = {
        "home.favorite": {
            "label": "Home team is favorite",
            "selection": "home.favorite"
        },
        "home.underdog": {
            "label": "Home team is underdog",
            "selection": "home.underdog"
        },
        "away.favorite": {
            "label": "Away team is favorite",
            "selection": "away.favorite"
        },
        "away.underdog": {
            "label": "Away team is underdog",
            "selection": "away.underdog"
        }
    };

    // run when init crit changes
    useEffect(()=> {
        // check if user is editing an existing criteria
        if(props.initCrit){
            const critCopy = {...props.initCrit.crit};
            setCritVal(critCopy);
            setSelection(critCopy.selection);
            setInitSel(critCopy.selection);
            setCritIdx(props.initCrit.index);
        };
    },[props.initCrit])

    // set submit function in parent component
    const validateSubmit = (e) => {
        e.preventDefault();
        // make sure criteria is not null
        if(critVal !== null){
            // if existing criteria was modified - add / update criteria
            if(critVal.selection !== initSel){
                // modify criteria 
                props.sysContext.changeCrit(critVal, critIdx);
            };
            props.sysContext.setStep(2);
            props.sysContext.setCritDisp(null);
        }else{
            setErr(true);
        };
    };

    // run when selection changes - update crit
    useEffect(()=> {
        if(selection !== null && selection !== ""){
            let tmpCrit = critValues[selection];
            tmpCrit["type"] = "team.fav-dog";
            setCritVal(tmpCrit);
            setErr(false);
        }else if(selection === "" || selection == null){
            setCritVal(null);
        };
    },[selection]);

    return (
        <>
            <div id='sysFormTopRow'>
                <h2>Favorite / Underdog</h2>
            </div>
            <Form>
                <FloatingLabel label='Choose Criteria' id="fav-dog-div" className='sysField'>
                    <Form.Control id="fav-dog" as="select" value={selection} onChange={(e)=>setSelection(e.target.value)}>
                        <option value={""}></option>
                        <option value="home.favorite" key="home.favorite" >Home Favorite</option>
                        <option value="home.underdog" key="home.underdog" >Home Underdog</option>
                        <option value="away.favorite" key="away.favorite" >Away Favorite</option>
                        <option value="away.underdog" key="away.underdog" >Away Underdog</option>
                    </Form.Control>
                </FloatingLabel>
                {err && (<p className='errMsg'>Please make a selection, or click cancel to go back.</p>)}
                <div id='submit-btns'>
                    <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>props.cancel()}>Cancel</button>
                    <button className="primaryBtn" id="saveCritBtn" onClick={(e)=>validateSubmit(e)}>Save Criteria</button>
                </div>
            </Form>
        </>
    )
}

export default FavoriteUnderdog;