import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import history from "./utils/history";
import {Provider} from "react-redux";
import store, {persistor} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Components
import MlbBeta from './components/mlb/beta/MlbBeta';
import MySystems from './components/pages/MySystems';
import System from './components/pages/System/System';
import About from './components/pages/About';
import UpcomingGames from './components/pages/UpcomingGames/UpcomingGames';
import UpcomingGame from './components/pages/Game/UpcomingGame';
import AdminPage from './components/pages/Admin/Admin';
import SignUpPage from './components/pages/SignUpPage';
import SignInPage from './components/pages/SignInPage';
import ResetPassPage from './components/pages/ResetPassPage';
import ScrollToTop from './components/utils/ScrollToTop';
import BuildSystem from './components/nfl/systems/BuildSystem';
import TermsOfService from './components/pages/TOS';
import Tutorials from './components/pages/Tutorials';
import FAQ from './components/pages/FAQ';
import Settings from './components/pages/Settings';
import SystemBuilder from './components/createSystem/SystemBuilder';
import GameAnalzer from './components/pages/Game/MlbGameAnalyzer';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter  history={history}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="mlb/beta" element={<MlbBeta />} />
              <Route path="nfl/build-system" element={<BuildSystem />} />
              <Route path="systems" element={<MySystems />} />
              <Route path="system/:id" element={<System />} />
              <Route index element={<About />} />
              <Route path="about" element={<About />} />
              <Route path="upcoming-games" element={<UpcomingGames />} />
              <Route path="upcoming-game/:id" element={<UpcomingGame />} />
              <Route path="admin" element={<AdminPage />} />
              <Route path="signup" element={<SignUpPage />} />
              <Route path="signin" element={<SignInPage />} />
              <Route path="resetpassword" element={<ResetPassPage />} />
              <Route path="tos" element={<TermsOfService />} />
              <Route path="tutorials" element={<Tutorials />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="settings" element={<Settings />} />
              <Route path="system-builder" element={<SystemBuilder />} />
              <Route path="game-analyzer" element={<GameAnalzer />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);


// register service worker 
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
