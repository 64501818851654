import { useState } from 'react';
import {Form, FloatingLabel} from 'react-bootstrap'
import {useDispatch} from 'react-redux';
import {setUser, setLoggedIn, setUserId, setPermissions} from '../../redux/userSlice';
import styles from '../../scss/pages/loginSignup.module.scss';

const SignInForm = (props) => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errs, setErrs] = useState("");

  const handleSubmit = async(event) => {
    event.preventDefault();
    // body of post request
    const body = {
      username:username, 
      password: password
    };
    //api parameters to get sys matches
    const url = 'https://api.lineleaders.net/accounts/signin';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    };

    let signInProm = await fetch(url, options);
    let result = await signInProm.json();  
    // handle response 
    if(result.ok === true){
      const token = result.result.accessToken
      localStorage.setItem('access_token', token.jwtToken);
      localStorage.setItem('refresh_token', result.result.refreshToken.token);
      localStorage.setItem('id_token', result.result.idToken.jwtToken);
      dispatch(setUser(token.payload.username));
      dispatch(setUserId(token.payload.sub));
      dispatch(setLoggedIn(true));
      dispatch(setPermissions(result.userGroups));
      props.navigate('/');
    }else if(result.ok === false){
      let e = String(result.error)
      if(e.includes("NotAuthorizedException: Incorrect username or password.")){  
        setErrs("Incorrect username or password.");
      };
    };

  };
  return (
      <Form onSubmit={handleSubmit} className={styles.signInForm}>
        <div className={styles.formInputs}>
          <FloatingLabel label='Username' className={styles.formField}>
            <Form.Control type="text" id="username" minLength={4} required value={username} onChange={(event) => setUsername(event.target.value)} placeholder="Enter Username"/>
            <Form.Control.Feedback type="invalid">Username must be at least 4 characters.</Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel label="Password" className={styles.formField}>
            <Form.Control type="password" id="pass" required pattern="(?=.*\d)(?=.*[!@#$%^&?*])(?=.*[a-z])(?=.*[A-Z]).{8,}"  value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Password"/>
            <Form.Control.Feedback type="invalid">Password must contain at least 8 characters, including uppercase and lowercase letters, numbers, and special characters.</Form.Control.Feedback>
          </FloatingLabel>
        </div>
        <div className='invalid-feedback' style={{display:"block"}}>{errs}</div>
        <a className={styles.pageLink} onClick={()=>props.navigate('/resetpassword')}>Forgot password?</a>
        <button className={`primaryBtn ${styles.loginSignupPageBtn}`} type="submit">Log In</button>
      </Form>
  );
};

export default SignInForm;