import React, {useState, useEffect, useContext} from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import calcProfitAm from '../../utils/systems/calcProfit';
import {useNavigate} from 'react-router-dom';
import { setFollowedSystems, setPrivateSystems, setMatches } from '../../redux/userSlice';
import SystemDisp from '../pages/System/SystemDisp';
import apiService from '../../utils/apiService';
import { useClearUserSession } from '../../hooks/user-hooks';
import { formatSystem } from '../../utils/systems/systemUtils';
import { SystemContext } from './SystemBuilder';
import { useDeepCompareEffect } from '../../hooks/deepCompareHook';

const BacktestDisp = (props) => {
    const sysContext = useContext(SystemContext)
    const clearSession = useClearUserSession();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [complete, setComplete] = useState(false);
    const user_id = useSelector((state) => state.user.id);
    const privateSystems = useSelector(state => state.user.privateSystems);
    const followedSystems = useSelector(state => state.user.followedSystems);
    const allSysMatches = useSelector(state => state.user.matches);
    const [system, setSystem] = useState({});

    // set system when system context changes
    useEffect(()=>{
        const tmpSys = {
            ...system,
            sport: sysContext.sport,
            name: sysContext.name,
            criteria: sysContext.criteria,
            bet: sysContext.bet,
            matches: sysContext.matches,
            units: 1,
            roi: props.roi,
            w: props.w,
            l: props.l, 
            p: props.p
        };
        setSystem(tmpSys);
    },[sysContext, props.roi, props.w, props.l, props.p]);

    // function get to get wl data
    const analyzeSystemPerformance = async() => {
        // total win loss and push count
        let total_w = 0, total_l = 0, total_p = 0, total_prof = 0;
        let seasons = Object.keys(sysContext.matches);
        // tmp sznWL arr
        let tmpSznWL = [];
        for(const szn of seasons){
            let games = sysContext.matches[szn];
            // season win loss and push count
            let sznW = 0, sznL = 0, sznP = 0, sznProf = 0;
            if(games.length > 0){
                for(const game of games){
                    let betProf;
                    if(game.bet.result === "W"){
                        total_w = total_w+1;
                        sznW = sznW+1;
                        betProf = calcProfitAm(game.bet.odds, sysContext.units);
                        sznProf = sznProf + betProf;
                        total_prof = total_prof + betProf;
                    }else if(game.bet.result === "L"){
                        sznL = sznL+1;
                        total_l = total_l+1;
                        sznProf = sznProf - sysContext.units;
                        total_prof = total_prof - sysContext.units;
                    }else if(game.bet.result === "P"){
                        sznP = sznP+1;
                        total_p = total_p+1;
                    };
                };
            };
            tmpSznWL.push({season: szn, w: sznW, l:sznL, p:sznP, prof:sznProf.toFixed(2)});
        };
        let tmpRoi = (total_w + total_l) > 0 ? (total_prof / ((total_w + total_l) * sysContext.units)) * 100 : 0;

        console.log(`roi: ${tmpRoi} - w: ${total_w} - l: ${total_l} - p: ${total_p}`)
        props.setRoi(parseFloat(tmpRoi.toFixed(2)))
        props.setW(total_w);
        props.setL(total_l);
        props.setP(total_p);
        props.setSznWL(tmpSznWL);
    };

    // Analyze system performance when sysMatches changes
    useDeepCompareEffect(() => {
        if (Object.keys(sysContext.matches).length > 0) {
            console.log("analyze")
            analyzeSystemPerformance();
        };
    }, [sysContext.matches]);

    // function to handle the finishing of backtesting
    const finished_backtesting = async(sysMatches, upcomingMatches) =>{
        // set state variables
        props.setSysMatches(sysMatches);
        props.setUpcomingMatches(upcomingMatches);
        setComplete(true);
        props.setBacktested({
            tested: true, 
            modified: false
        });
    };

    // function to backtest the system
    const fetch_backtest = async() => {
        // body of post request
        const body = {
            sport: sysContext.sport,
            name: sysContext.name,
            criteria: sysContext.criteria,
            bet: sysContext.bet,
        };
        try{
            // response of api request
            const payload = await apiService('backtest-system', "POST", body, {}, 'v2');
            // set data once returned
            finished_backtesting(payload.system_matches, payload.upcoming_matches);
        }catch(error){
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };

    // run when system changes, backtest system
    useEffect(()=>{
        // backtest only if initial backtest request or modifications were made
        if(props.backtested.tested === false || props.backtested.modified === true){
            fetch_backtest();
        }else{
            setComplete(true);
        };
    },[props.backtested]);
    
    //Function to create system record in database
    async function createSystem() {
        // body of post request
        const body = {
            system:{
                sport: sysContext.sport,
                name: sysContext.name,
                criteria: sysContext.criteria,
                bet: sysContext.bet,
                matches: sysContext.matches,
                user_id: user_id
            },
            upcomingMatches: sysContext.upcomingMatches,
            type: sysContext.sysType
        };
        try{
            // call api
            const response = await apiService('systems/create', 'POST', body);
            if('system_id' in response){
                let tmpSys = formatSystem({
                    sport: sysContext.sport,
                    name: sysContext.name,
                    criteria: sysContext.criteria,
                    bet: sysContext.bet,
                    matches: sysContext.matches,
                    user_id: user_id,
                    _id: response.system_id
                });
                if(sysContext.sysType === "private"){
                    let tmpSystems = [...privateSystems];
                    tmpSystems.push(tmpSys);
                    dispatch(setPrivateSystems(tmpSystems));
                }else if(sysContext.sysType === "public"){
                    let tmpSystems = {
                        ...followedSystems,
                        [tmpSys.sport]: [...(followedSystems[tmpSys.sport] || []), tmpSys]
                    };
                    dispatch(setFollowedSystems(tmpSystems));
                };
                // add sys matches 
                if(response.matches.length > 0){
                    let tmpMatches = [...allSysMatches];
                    tmpMatches = tmpMatches.concat(response.matches);
                    dispatch(setMatches(tmpMatches));
                };
                // navigate back to systems page
                navigate('/systems');
            }else{
                console.log("resource not created");
            };
        }catch(error){
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };

    //Function to update system record in database
    async function updateSystem() {
        // body of post request
        const body = {
            sys: {
                sport: sysContext.sport,
                name: sysContext.name,
                criteria: sysContext.criteria,
                bet: sysContext.bet,
                matches: sysContext.matches,
                user_id: user_id
            },
            id: sysContext.id,
            upcomingMatches: sysContext.upcomingMatches
        };
        try{
            // call api
            const response = await apiService('systems/update', 'POST', body)
            // if record updated in database, update redux store
            if(response.updated){
                let tmpSystems = [...privateSystems];
                // find index of system
                const i = tmpSystems.findIndex(sys => sys._id === sysContext.id);
                // if index found, format system and update redux store
                if(i !== -1){
                    let tmpSys = formatSystem({
                        sport: sysContext.sport,
                        name: sysContext.name,
                        criteria: sysContext.criteria,
                        bet: sysContext.bet,
                        matches: sysContext.matches,
                        user_id: user_id,
                        _id: sysContext.id,
                    });
                    tmpSystems[i] = tmpSys;
                    dispatch(setPrivateSystems(tmpSystems));
                };
                // remove system matches from redux store and add new ones 
                let tmpMatches = [...allSysMatches];
                tmpMatches = tmpMatches.filter(match => match.system_id !== sysContext.id);
                if(response.matches.length > 0){
                    tmpMatches = tmpMatches.concat(response.matches);
                };
                dispatch(setMatches(tmpMatches));
                // navigate back to systems page 
                navigate('/systems');
            }else{
                console.log('not modified');
            };
        }catch(error){
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };

    // function to handle submit
    const submit = () =>{
        // either create system or update depending on type
        if(props.pageType === "edit"){
            updateSystem();
        }else createSystem();
    };

    if(complete){
        return(
            <div>
                <SystemDisp system={system} sznWL={props.sznWL} pageType="new"/>
                <Row className="form-row">
                    <Col className='right-align'>
                        <Button className='secondaryBtn' type="submit" onClick={()=>sysContext.setStep(2)} >Edit</Button>
                    </Col>
                    <Col className='left-align'>
                        <Button className='primaryBtn' type="submit" onClick={()=>submit()} >Save System</Button>
                    </Col>
                </Row>
            </div>
        )
    }else{
        return(
        <div className='text-center'>
            <Spinner className="mx-auto" animation="border"/>
        </div>)
    }
};
export default BacktestDisp