import React, {useContext} from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';
import { SystemContext } from '../SystemBuilder';

const CriteriaSelection = () => {
    const sysContext = useContext(SystemContext)
    const critList = {
        "TeamFilters": [{type: "fav-dog", label: "Favorite / Underdog"}, {type: "home-team-sel", label: "Home Team Selector"}, {type: "away-team-sel", label: "Away Team Selector"}],
        "LineFilters": [{type: "odds-filter", label: "Odds Filter"}, {type: "team-spread", label: "Spread Filter"}, {type: "game-totals", label: "Totals Filter"}],
        "StatFilters": [{type: "avg-comp", label: "Compare / Combine Stats"}, {type: "avg", label: "Single Stat Criteria"}]
    };

    return (
        <>
            <div id="header-row">
                <h2>Select Criteria Type</h2>
            </div>
            <Accordion>
                <Accordion.Item eventKey='Team'>
                    <Accordion.Header>Team Filters</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant='flush'>
                            {critList["TeamFilters"].map(filt=>(
                                <ListGroup.Item action key={filt.type} onClick={()=>sysContext.displayCrit(`team.${filt.type}`)}>{filt.label}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='LineFilters'>
                    <Accordion.Header>Line Filters</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant='flush'>
                            {critList["LineFilters"].map(filt=>(
                                <ListGroup.Item action key={filt.type} onClick={()=>sysContext.displayCrit(`line.${filt.type}`)}>{filt.label}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='StatFilters'>
                    <Accordion.Header>Stat Filters</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup variant='flush'>
                            {critList["StatFilters"].map(filt=>(
                                <ListGroup.Item action key={filt.type} onClick={()=>sysContext.displayCrit(`stat.${filt.type}`)}>{filt.label}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div id='submit-btns'>
                <button className="primaryBtn" id="cancelCritBtn" onClick={()=>sysContext.setStep(2)}>Back</button>
            </div>
        </>

    )
}

export default CriteriaSelection;