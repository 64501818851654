import React, {useState, useEffect} from 'react'
import { Form, FloatingLabel } from 'react-bootstrap';

const OddsRange = (props) => {
    const [formType, setFormType] = useState("");
    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);
    const [critVal, setCritVal] = useState({
        type: "line.odds-filter",
        rangeType: ""
    });
    const [initCrit, setInitCrit] = useState(null);
    const [critIdx, setCritIdx] = useState(null);
    const [errs, setErrs] = useState([]);
    const [errMsg, setErrMsg] = useState(null);
    const formFields = {
        min:
            <FloatingLabel label='Minimum Odds (American)' className='sysField'>
                <Form.Control id="min" type="number" value={min === null ? '' : min} onChange={(e)=>setMin(e.target.value)}/>
            </FloatingLabel>,
        max: 
            <FloatingLabel label='Maximum Odds (American)' className='sysField'>
                <Form.Control id="max" type="number" value={max === null ? '' : max} onChange={(e)=>setMax(e.target.value)}/>
            </FloatingLabel>    
    };
    const forms = {
        "between": 
            <>
                {formFields.min}
                {formFields.max}
            </>,
        "greater": formFields.min,
        "less": formFields.max    
    };

    // run when init crit changes
    useEffect(()=> {
        // check if user is editing an existing criteria
        if(props.initCrit){
            const critCopy = {...props.initCrit.crit};
            setCritVal(critCopy);
            setFormType(critCopy.rangeType);
            setInitCrit(critCopy);
            setCritIdx(props.initCrit.index);
            switch(critCopy.rangeType){
                case "between":
                    setMin(critCopy.min);
                    setMax(critCopy.max);
                    break;
                case "greater":
                    setMin(critCopy.min);
                    break;
                case "less":
                    setMax(critCopy.max);
                    break;
                default: 
                    break;
            };
        };
    },[props.initCrit])

    // function to check if init criteria has changed
    const checkCritChange = () => {
        let modified = false;
        if(critIdx  != null){
            if(critVal.rangeType !== initCrit.rangeType){
                modified = true;
            };
            switch(critVal.rangeType){
                case "between":
                    if(critVal.min !== initCrit.min || critVal.max !== initCrit.max){
                        modified = true;
                    };
                    break;
                case "greater":
                    if(critVal.min !== initCrit.min){
                        modified = true;
                    };
                    break;
                case "less":
                    if(critVal.max !== initCrit.max){
                        modified = true;
                    };
                    break;
            };
        }else{
            modified = true;
        };
        return modified;
    };

    // function to check for errors
    const checkErrs = () => {
        let tmpErrs = [];
        let tmpErrMsg = null;
        switch(critVal.rangeType){
            case "between":
                if(isNaN(critVal.min) || critVal.min === ""){
                    tmpErrs.push("min");
                };
                if(isNaN(critVal.max) || critVal.max === ""){
                    tmpErrs.push("max");
                };
                if(!isNaN(critVal.min) && critVal.min !== "" && !isNaN(critVal.max) && critVal.max !== ""){
                    if(critVal.min >= critVal.max){
                        tmpErrs.push("min");
                        tmpErrs.push("max");
                        tmpErrMsg = "Please ensure the minimum odds value is less than the maximum";
                    };
                };
                break;
            case "greater":
                if(isNaN(critVal.min) || critVal.min === ""){
                    tmpErrs.push("min");
                };
                break;
            case "less":
                if(isNaN(critVal.max) || critVal.max === ""){
                    tmpErrs.push("max");
                };
                break;
            default:
                tmpErrs.push("odds-type");
                break; 
        };
        return({errs: tmpErrs, msg: tmpErrMsg});
    };

    // set submit function in parent component
    const validateSubmit = (e) => {
        e.preventDefault();
        // check for errors
        const errCheck = checkErrs(); 
        setErrMsg(errCheck.msg);
        setErrs(errCheck.errs);
        if(errCheck.errs.length === 0){
            // check for crit change - returns true if initCrit was modified or not passed
            const modified = checkCritChange();
            if(modified){
                props.sysContext.changeCrit(critVal, critIdx);
                props.sysContext.setCritDisp(null);
            };
            props.sysContext.setStep(2);
        };
    };

    useEffect(()=>{
        let tmpCrit = {
            type: "line.odds-filter",
            rangeType: formType,
        };
        tmpCrit.rangeType = formType;
        const minLbl = min > 0 ? `+${min}` : min;
        const maxLbl = max > 0 ? `+${max}` : max;
        switch(formType){
            case "between": 
                tmpCrit.min = parseFloat(min);
                tmpCrit.max = parseFloat(max);
                tmpCrit.label = `Odds are between ${minLbl} and ${maxLbl}`;
                break;
            case "greater":
                tmpCrit.min = parseFloat(min);
                tmpCrit.label = `Odds are ${minLbl} or greater`;
                break
            case "less":
                tmpCrit.max = parseFloat(max);
                tmpCrit.label = `Odds are ${maxLbl} or less`;
                break
            default:
                break;
        };

        setCritVal(tmpCrit);
    }, [formType, min, max]);

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            const elem = document.getElementById(err);
            if(elem){
                elem.classList.add("err");
            };
        });
    },[errs]);

    return (
        <>
            <div id="header-row">
                <h2>Odds Filter</h2>
            </div>
            <Form>
                <FloatingLabel label='Choose odds filter' id="odds-div" className='sysField'>
                    <Form.Control id="odds-type" as="select" value={formType} onChange={(e)=>setFormType(e.target.value)}>
                        <option value=""></option>
                        <option value="between">Odds are between</option>
                        <option value="greater">Odds are greater than</option>
                        <option value="less">Odds are less than</option>
                    </Form.Control>
                </FloatingLabel>
                {formType !== "" && forms[formType]}
                {errMsg && (<p className='errMsg'>{errMsg}</p>)}
                <div id='submit-btns'>
                    <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>props.cancel()}>Cancel</button>
                    <button className="primaryBtn" id="saveCritBtn" onClick={(e)=>validateSubmit(e)}>Save Criteria</button>
                </div>
            </Form>
        </>
    );
};

export default OddsRange;