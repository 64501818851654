import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch} from 'react-redux';
import { ListGroup, Modal, Button, Form, FloatingLabel, Row, Col } from 'react-bootstrap';
import { setMissingOdds } from '../../../redux/applicationSlice';
import apiService from '../../../utils/apiService';
import { useClearUserSession } from '../../../hooks/user-hooks';
import { useNavigate } from 'react-router-dom';

const MissingOdds = () => {
    const clearSession = useClearUserSession();
    const navigate = useNavigate();
    
    let missing = useSelector((state) => state.app.missing_odds);
    const dispatch = useDispatch();
    const [game, setGame] = useState({});
    const [show, setShow] = useState(false);
    const [totalRuns, setTotalRuns] = useState('');
    const [totalOver , setTotalOver] = useState('');
    const [totalUnder , setTotalUnder] = useState('');
    const [runlineHomeRuns , setRunlineHomeRuns ] = useState('');
    const [runlineHomeOdds , setRunlineHomeOdds] = useState('');
    const [runlineAwayRuns , setRunlineAwayRuns] = useState('');
    const [runlineAwayOdds , setRunlineAwayOdds] = useState('');
    const [homeML , setHomeML] = useState('');
    const [awayML , setAwayML] = useState('');
    const [errs, setErrs] = useState([]);
    const [odds, setOdds] = useState({});
    const [reviewing, setReviewing] = useState(false);

    // run when any criteria var changes - set odds object
    useEffect(()=>{
        // make sure game is set
        if("gamelog_id" in game){
            // structure for prior to 2020
            if(parseInt(game.date.substr(0,4)) < 2022){
                setOdds({
                    gamelog_id: game.gamelog_id,
                    season : game.date.substr(0,4),
                    totals: {
                        open: {
                            runs: parseFloat(totalRuns),
                            over: parseInt(totalOver),
                            under: parseInt(totalUnder)
                        },
                        close: {
                            runs: parseFloat(totalRuns),
                            over: parseInt(totalOver),
                            under: parseInt(totalUnder)
                        }
                    },
                    ml: {
                        home:{
                            open: parseInt(homeML),
                            close: parseInt(homeML)
                        },
                        away:{
                            open: parseInt(awayML),
                            close: parseInt(awayML)
                        }
                    },
                    runline: {
                        home:{
                            odds: parseInt(runlineHomeOdds),
                            runs: parseFloat(runlineHomeRuns)
                        },
                        away:{
                            odds: parseInt(runlineAwayOdds),
                            runs: parseFloat(runlineAwayRuns)
                        }
                    }
                });
            // structure for 2022 and later
            }else{
                setOdds({
                    gamelog_id: game.gamelog_id,
                    season : game.date.substr(0,4),
                    totals: [
                        {
                            book: "N/A",
                            lines: [
                                {
                                    timestamp: game.date,
                                    runs: parseFloat(totalRuns),
                                    over: parseInt(totalOver),
                                    under: parseInt(totalUnder)
                                }
                            ]
                        }
                    ],
                    runline: [
                        {
                            book: "N/A",
                            lines: [
                                {
                                    timestamp: game.date,
                                    home:{
                                        odds: parseInt(runlineHomeOdds),
                                        runs: parseFloat(runlineHomeRuns)
                                    },
                                    away:{
                                        odds: parseInt(runlineAwayOdds),
                                        runs: parseFloat(runlineAwayRuns)
                                    }
                                }
                            ]
                        }
                    ],
                    ml: [
                        {
                            book: "N/A",
                            lines: [
                                {
                                    timestamp: game.date,
                                    home: parseFloat(homeML),
                                    away: parseFloat(awayML)
                                }
                            ]
                        }
                    ]
                });
            };
        };

    },[totalRuns, totalOver, totalUnder, runlineAwayOdds, runlineAwayRuns, runlineHomeOdds, runlineHomeRuns, homeML, awayML, game]);

    // run when game changes - reset state if empty 
    useEffect(()=>{
        if(!game.hasOwnProperty("gamelog_id")){
            setTotalRuns('');
            setTotalOver('');
            setTotalUnder('');
            setRunlineAwayOdds('');
            setRunlineAwayRuns('');
            setRunlineHomeOdds('');
            setRunlineHomeRuns('');
            setAwayML('');
            setHomeML('');
        };
    },[game]);

    // handle modal closure
    const handleClose = () => {
        setShow(false);
        setGame({});
        setReviewing(false);
    };

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };    
        // add err class based on updated errs
        errs.forEach(err=>{
            document.getElementById(err).classList.add("err");
        });
    },[errs]);

    // function to open game modal
    const openGame = (game)=>{
        setGame(game);
        setShow(true);
    };

    const validateForm = () => {
        // array to hold bad fields
        let tmpErrs = []
        // validate fields
        if(totalRuns === '') tmpErrs.push("total-runs");
        if(totalOver === '') tmpErrs.push("total-over");
        if(totalUnder === '') tmpErrs.push("total-under");
        if(runlineAwayOdds === '') tmpErrs.push("away-rl-odds");
        if(runlineAwayRuns === '') tmpErrs.push("away-rl-runs");
        if(runlineHomeOdds === '') tmpErrs.push("home-rl-odds");
        if(runlineHomeRuns === '') tmpErrs.push("home-rl-runs");
        if(homeML === '') tmpErrs.push("home-ml-odds");
        if(awayML === '') tmpErrs.push("away-ml-odds");

        setErrs(tmpErrs);
        if(tmpErrs.length === 0){
            setReviewing(true);
        };

    };

    // function to insert odds record to database   
    const insertOdds = async() => {
        // api params to create odds record
        const body = odds;
        const response = await apiService('mlb/create-odds', 'POST', body);
        if(response !== 'Server Error' && response !== 'Session Expired'){
            // remove from missing redux array
            const tmpMissing = missing.filter(record =>{return(record.gamelog_id !== game.gamelog_id)});
            dispatch(setMissingOdds(tmpMissing));
            // close modal
            handleClose();
        }else{ // else redirect to login and clear user data
            clearSession();
            navigate('/signin');
        };
    };

    // function to handle submission 
    const submit = () =>{
        // create odds record 
        insertOdds();
    };



    return(
        <>
            <ListGroup>
                {missing.map(game=>{
                    return(
                        <ListGroup.Item key={game.gamelog_id} onClick={()=>openGame(game)}>
                            <p>Gamelog: {game.gamelog_id}</p>
                            <p>Date: {game.date}</p>
                            <p>Home: {game.home}</p>
                            <p>Away: {game.away}</p>
                            <p>Game Number: {game.game_number}</p>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Game Lines</Modal.Title>
                </Modal.Header>
                {!reviewing ?
                    <>
                        <Modal.Body>
                            <div>
                                <p>Gamelog: {game.gamelog_id}</p>
                                <p>Date: {game.date}</p>
                                <p>Home: {game.home}</p>
                                <p>Away: {game.away}</p>
                                <p>Game Number: {game.game_number}</p>
                            </div>
                            <Form> 
                                <h3>Totals</h3>
                                <Form.Group>
                                    <>
                                        <FloatingLabel
                                            controlId="total-runs"
                                            label="Runs"
                                            className="mb-3"
                                        >
                                            <Form.Control type="number" defaultValue={totalRuns} onChange={(e)=>setTotalRuns(e.target.value)}/>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="total-over"
                                            label="Over"
                                            className="mb-3"
                                        >
                                            <Form.Control type="number" defaultValue={totalOver} onChange={(e)=>setTotalOver(e.target.value)}/>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="total-under"
                                            label="Under"
                                            className="mb-3"
                                        >
                                            <Form.Control type="number" defaultValue={totalUnder} onChange={(e)=>setTotalUnder(e.target.value)}/>
                                        </FloatingLabel>
                                    </>                        
                                </Form.Group>

                                <h3>Runline</h3>
                                <Form.Group>
                                    <>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                controlId="home-rl-runs"
                                                label={`${game.home} Runs`}
                                                className="mb-3"
                                                >
                                                    <Form.Control type="number" defaultValue={runlineHomeRuns} onChange={(e)=>setRunlineHomeRuns(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    controlId="home-rl-odds"
                                                    label={`${game.home} Odds`}
                                                    className="mb-3"
                                                    >
                                                    <Form.Control type="number" defaultValue={runlineHomeOdds} onChange={(e)=>setRunlineHomeOdds(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                controlId="away-rl-runs"
                                                label={`${game.away} Runs`}
                                                className="mb-3"
                                                >
                                                    <Form.Control type="number" defaultValue={runlineAwayRuns} onChange={(e)=>setRunlineAwayRuns(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    controlId="away-rl-odds"
                                                    label={`${game.away} Odds`}
                                                    className="mb-3"
                                                    >
                                                    <Form.Control type="number" defaultValue={runlineAwayOdds} onChange={(e)=>setRunlineAwayOdds(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </>                        
                                </Form.Group>
                                
                                <h3>Moneyline</h3>
                                <Form.Group>
                                    <FloatingLabel
                                        controlId="home-ml-odds"
                                        label={`${game.home} ML`}
                                        className="mb-3"
                                        >
                                        <Form.Control type="number" defaultValue={homeML} onChange={(e)=>setHomeML(e.target.value)}/>
                                    </FloatingLabel>          
                                    <FloatingLabel
                                        controlId="away-ml-odds"
                                        label={`${game.away} ML`}
                                        className="mb-3"
                                        >
                                        <Form.Control type="number" defaultValue={awayML} onChange={(e)=>setAwayML(e.target.value)}/>
                                    </FloatingLabel>                    
                                </Form.Group>
                            </Form>
                        </Modal.Body>   
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={()=>validateForm()}>
                                Review Lines
                            </Button>
                        </Modal.Footer>
                    </>
                    :
                    <>
                        <Modal.Body>
                            <div style={{whiteSpace: "pre"}}>
                                {JSON.stringify(odds, null, 4)}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>setReviewing(false)}>
                                Back
                            </Button>
                            <Button variant="primary" onClick={submit}>
                                Save Lines
                            </Button>
                        </Modal.Footer>
                    </>

                }
            </Modal>
        </>
    )

}

export default MissingOdds