export const mlbOffStats = {
    "batting.AB": "at bats",
    "batting.R": "runs scored",
    "batting.H": "hits",
    "batting.HR": "home runs",
    "batting.RBI": "RBIs",
    "batting.BB": "walks taken",
    "batting.SO": "strike outs",
    "batting.PA": "plate appearances",
    "batting.BA": "batting average",
    "batting.OBP": "on base percentage",
    "batting.SLG": "slugging percentage",
    "batting.OPS": "OPS",
    "batting.Pit": "pitches taken",
    "batting.Str": "strikes taken",
    "batting.doubles": "doubles",
    "batting.triples": "triples",
    "batting.SF": "sac flies",
    "batting.SB": "stolen bases",
    "batting.CS": "runners caught stealing",
    "batting.LOB": "runners left on base"
};

export const mlbOffStatVerbs = {
    "batting.AB": "are",
    "batting.R": "are",
    "batting.H": "are",
    "batting.HR": "are",
    "batting.RBI": "are",
    "batting.BB": "are",
    "batting.SO": "are",
    "batting.PA": "are",
    "batting.BA": "is",
    "batting.OBP": "is",
    "batting.SLG": "is",
    "batting.OPS": "is",
    "batting.Pit": "are",
    "batting.Str": "are",
    "batting.doubles": "are",
    "batting.triples": "are",
    "batting.SF": "are",
    "batting.SB": "are",
    "batting.CS": "are",
    "batting.LOB": "are"
};

export const mlbDefStats = {
    "pitching.IP": "innings pitched",
    "pitching.H": "hits given up",
    "pitching.R": "runs given up",
    "pitching.ER": "earned runs",
    "pitching.BB": "batters walked",
    "pitching.SO": "strike outs thrown",
    "pitching.HR": "home runs given up",
    "pitching.ERA": "ERA",
    "pitching.BF": "batters faced",
    "pitching.Pit": "pitches thrown",
    "pitching.Str": "strikes thrown",
    "pitching.Ctct": "strikes thrown by contact",
    "pitching.StS": "strikes thrown swinging",
    "pitching.StL": "stikes thrown looking",
    "pitching.GB": "ground balls given up",
    "pitching.FB": "fly balls given up",
    "pitching.LD": "line drives given up"
};

export const mlbDefStatVerbs = {
    "pitching.IP": "are",
    "pitching.H": "are",
    "pitching.R": "are",
    "pitching.ER": "are",
    "pitching.BB": "are",
    "pitching.SO": "are",
    "pitching.HR": "are",
    "pitching.ERA": "is",
    "pitching.BF": "are",
    "pitching.Pit": "are",
    "pitching.Str": "are",
    "pitching.Ctct": "are",
    "pitching.StS": "are",
    "pitching.StL": "are",
    "pitching.GB": "are",
    "pitching.FB": "are",
    "pitching.LD": "are"
};

export const mlbTeams = {
    "ARI": "Diamondbacks",
    "ATL": "Braves",
    "BAL": "Orioles",
    "BOS": "Redsox",
    "CHW": "Whitesox",
    "CHC": "Cubs",
    "CIN": "Reds",
    "CLE": "Guardians",
    "COL": "Rockies",
    "DET": "Tigers",
    "HOU": "Astros",
    "KCR": "Royals",
    "LAA": "Angels",
    "LAD": "Dodgers",
    "MIA": "Marlins",
    "MIL": "Brewers",
    "MIN": "Twins",
    "NYM": "Mets",
    "NYY": "Yankees",
    "OAK": "Athletics",
    "PHI": "Phillies",
    "PIT": "Pirates",
    "SDP": "Padres",
    "SEA": "Mariners",
    "SFG": "Giants",
    "STL": "Cardinals",
    "TBR": "Rays",
    "TEX": "Rangers",
    "TOR": "Blue Jays",
    "WSN": "Nationals",
};

export const mlbLeagues = {
    "NL": {
        "East": ["ATL", "MIA", "NYM", "PHI", "WSN"],
        "Central": ["CHC", "CIN", "MIL", "PIT", "STL"],
        "West": ["ARI", "COL", "LAD", "SDP", "SFG"]
    },
    "AL": {
        "East":["BAL", "BOS", "NYY", "TBR", "TOR"],
        "Central":["CHW", "CLE", "DET", "KCR", "MIN"],
        "West":["HOU", "LAA", "OAK", "SEA", "TEX"]
    }
};