import React, {useState, useEffect} from 'react'
import {Button, FloatingLabel, Form} from 'react-bootstrap';
import StatSelection from './StatSelection';
const NflCustomCrit = (props) => {
    const [critStep, setCritStep] = useState(1);
    const [opLabel, setOpLabel] = useState("");
    const [err, setErr] = useState("");
    const [lines] = useState({
        "spread.home":"home spread points",
        "spread.away":"away spread points",
        "total":"total points"
    });

    const validateCond = () => {
        // make sure condition is filled out
        if(props.cond === "" || props.cond === null){
            return false;
        // if condition is a number return true
        }else if(!isNaN(props.cond)){
            return true;
        // otherwise validate conditional formatting
        };
    }

    // run whenever err changes
    useEffect(()=>{
        // remove err classes if there are none
        if(err === ""){
            // get all elements previously in error state and remove class
            let prevErrs = document.getElementsByClassName("err");
            while(prevErrs.length){
                prevErrs[0].classList.remove("err");
            };
        }else{
            // add err class to element
            document.getElementById(err).classList.add("err");   
        };
    },[err]);

    const back = () => {
        setCritStep(critStep-1);
    };

    // handle next step change 
    const next = (stepType) => {
        switch(stepType){
            case "op": 
                // validate op was selected
                if(props.op === "" || props.op === null){
                    setErr("op");
                }else{
                    setCritStep(critStep+1);
                    setErr("");
                };
                break;
            case "stat1": 
                if(props.stat === "" || props.stat === null){
                    setErr("stat");    
                }else{
                    setCritStep(critStep+1);
                    setErr("");
                };
                break;
            case "stat2":
                if(props.stat2 === "" || props.stat2 === null){
                    setErr("stat");    
                }else{
                    setCritStep(critStep+1);
                    setErr("");
                };
                break;
            case "comp":
                if(props.comp === "" || props.comp === null){
                    setErr("comp");    
                }else{
                    setCritStep(critStep+1);
                    setErr("");
                };
                break;
            case "cond":
                let valid = validateCond();
                if(valid){
                    setCritStep(critStep+1);
                    setErr("");
                }else{
                    setErr("cond");
                };
                break
            default: break
        };
    };

    // function to handle crit type selection
    const handleCritTypeSelection = (type) => {
        props.setType(type);
        setCritStep(2);
    };

    // function to handle op selection 
    const handleOpSelection = (val) => {
        props.setOp(val);
        if(val === "sum"){
            setOpLabel("Sum of")
        }else if(val === "diff"){
            setOpLabel("Difference between")
        };
    };

    // function to handle op selection 
    const handleCompSelection = (val) => {
        props.setComp(val);
        if(val === "lte"){
            props.setCompLabel("<=");
        }else if(val === "gte"){
            props.setCompLabel(">=");
        }else if(val === "eq"){
            props.setCompLabel("=")
        };
    };

    // run whenever any criteria variables are set, update critLabel
    useEffect(()=>{
        let tmpCritLab = "" ;
        if(props.type === "one-stat"){
            tmpCritLab = `${props.statLabel} ${props.compLabel} ${props.cond}`;
        }else if(props.type === "two-stats"){
            if(props.statLabel !== ""){
                tmpCritLab = `${opLabel} ${props.statLabel} and ${props.stat2Label} ${props.compLabel} ${props.cond}`;
            }else{
                tmpCritLab = `${opLabel}`;
            }
        };
        tmpCritLab = tmpCritLab.trim();
        props.setCritLabel(tmpCritLab);
    },[props.statLabel, props.stat2Label, opLabel, props.cond, props.compLabel, props.type])

    const condStep = <>
                        <div id="critStep">
                            <div id="cond-div">
                                <div id="cond-dropdown">
                                    <FloatingLabel label='Condition' id="cond-label">
                                        <Form.Control id="cond" type="text" value={props.cond} onChange={e=>props.setCond(e.target.value.replace(/\s+/g, ''))}/>
                                    </FloatingLabel>
                                    <Form.Select onChange={(e)=>props.setCond(e.target.value)} id="cond-select">
                                        <option defaultValue hidden value=""></option>
                                        <option disabled className='dropdown-cat'>Lines</option>
                                        {/* {Object.keys(lines).map(line_key=>{
                                            return(
                                                <option value={lines[line_key]} key={line_key}>{lines[line_key]}</option>
                                            )
                                        })} */}
                                    </Form.Select>
                                    <Form.Text muted>
                                        The condition can be a number, one of the lines from the dropdown, or a combination of the two (e.g. total+2)
                                    </Form.Text>
                                </div>
                            </div>
                        </div>
                        <div id="critBtns">
                            <Button className="secondaryBtn" onClick={()=>back()}>Back</Button>
                            <Button className="secondaryBtn" onClick={()=>next("cond")}>Next</Button>
                        </div>
                    </>;
    return (
        <div id="addCritDiv">
            {critStep === 1 && (
                <>
                    <h6 id="instr">What do you want your criteria to do?</h6>
                    <div id="critStep">
                        <Button className="secondaryBtn" onClick={()=>handleCritTypeSelection("one-stat")}  name="crit-type">Look at one stat</Button>
                        <Button className="secondaryBtn" onClick={()=>handleCritTypeSelection("two-stats")}  name="crit-type">Compare two stats</Button>
                    </div>
                    <div id="critBtns">
                        <Button className="primaryBtn" id="cancelCritBtn" onClick={()=>props.clearCrit()}>Cancel</Button>
                    </div>
                
                </>
            )}  
            {critStep === 2 && (
                <>
                    {props.type === "two-stats" && (
                        <>
                            <div id="critLabel">Criteria: </div>
                            <h6 id="instr">What would you like to look at?</h6>
                            <div id="critStep">
                                <FloatingLabel label='Operation' id="op-div">
                                    <Form.Select id="op" defaultValue={props.op} onChange={(e)=>handleOpSelection(e.target.value)}> 
                                        <option value=""></option>
                                        <option value="sum">Sum of two stats</option>
                                        <option value="diff">Difference between two stats</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            <div id="critBtns">
                                <Button className="secondaryBtn" onClick={()=>back()}>Back</Button>
                                <Button className="secondaryBtn" onClick={()=>next("op")}>Next</Button>
                            </div>
                        </>
                    )}
                    {props.type === "one-stat" && (
                        <>
                            <StatSelection offStats={props.offStats} defStats={props.defStats} stat={props.stat} handleStatSelection={props.handleStatChange} oneOrTwo={1} back={back} next={next}/>
                        </>
                    )}
                </>
            )}
            {critStep === 3 && (
                <>
                    {props.type === "two-stats" &&
                        <>
                            <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                            <StatSelection offStats={props.offStats} defStats={props.defStats} stat={props.stat} handleStatSelection={props.handleStatChange} oneOrTwo={1} back={back} next={next}/>
                        </>

                    }
                    {props.type === "one-stat" &&
                        <>  
                            <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                            <div id="critStep">
                                <FloatingLabel label='Comparison' id="comp-div">
                                    <Form.Select defaultValue={props.comp} id="comp" onChange={(e)=>handleCompSelection(e.target.value)}>
                                        <option value=""></option>
                                        <option id="gte" value="gte">{">="}</option>
                                        <option id="lte" value="lte">{"<="}</option>
                                        <option id="eq" value="eq">{"="}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            <div id="critBtns">
                                <Button className="secondaryBtn" onClick={()=>back()}>Back</Button>
                                <Button className="secondaryBtn" onClick={()=>next("comp")}>Next</Button>
                            </div>                        
                        </>
                    }

                </>
            )}
            {critStep === 4 && (
                <>
                    {props.type === "two-stats" &&
                        <>
                            <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                            <StatSelection offStats={props.offStats} defStats={props.defStats} stat={props.stat2} handleStatSelection={props.handleStatChange} oneOrTwo={2} back={back} next={next}/>
                        </>
                    }
                    {props.type === "one-stat" &&
                        <>
                            <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                            <div id="critStep">{condStep}</div>
                        </>
                    }
                </>
            )}
            {critStep === 5 && (
                <>
                    {props.type === "two-stats" &&
                        <>
                            <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                            <div id="critStep">
                                <FloatingLabel label='Comparison' id="comp-div">
                                    <Form.Select defaultValue={props.comp} id="comp" onChange={(e)=>handleCompSelection(e.target.value)}>
                                        <option value=""></option>
                                        <option id="gte" value="gte">{">="}</option>
                                        <option id="lte" value="lte">{"<="}</option>
                                        <option id="eq" value="eq">{"="}</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </div>
                            <div id="critBtns">
                                <Button className="secondaryBtn" onClick={()=>back()}>Back</Button>
                                <Button className="secondaryBtn" onClick={()=>next("comp")}>Next</Button>
                            </div>
                        </>
                    }
                
                </>
            )}

            {critStep === 6 && (
                <>
                    {props.type === "two-stats" &&
                        <>
                            <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                            {condStep}
  
                        </>
                    }
                
                </>
            )}         
                {((props.type === "two-stats" && critStep === 7) || (props.type === "one-stat" && critStep === 5)) && ( 
                    <>
                        <div id="critLabel">{`Criteria: ${props.critLabel}`}</div>
                        <div id="critBtns">
                            <Button className="secondaryBtn" onClick={()=>back()}>Back</Button>
                            <Button className="primaryBtn" id="saveCritBtn" onClick={()=>props.submit()}>Save Criteria</Button> 
                        </div>
                    </>
                )}
        </div>                       
    )
}

export default NflCustomCrit