export function isToday(date) {
    if(date instanceof Date){
        const today = new Date();
        return date.getDate() === today.getDate() &&
               date.getMonth() === today.getMonth() &&
               date.getFullYear() === today.getFullYear();
    }else return false;

};

// Function used to convert a datetime string (ET) and return an object with date and time string properties
export function formatDatetimeString(dateString) {
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Parse the date string as a local Date object (assuming it's already in ET)
    const date = new Date(dateString);

    // Get the day of the week, month, day of the month
    const dayName = days[date.getDay()]; // Day of the week
    const month = date.getMonth() + 1;   // Month (0-indexed, so add 1)
    const dayOfMonth = date.getDate();   // Day of the month

    // Format the time part as 12-hour format with AM/PM
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format, handling midnight

    return ({
        date: `${dayName}, ${month}-${dayOfMonth}`,
        time: `${hours}:${minutes} ${period} ET`
    })
};