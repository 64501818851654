import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    upcoming: [],
    publicSystems: []
}

const nflSlice = createSlice({
    name: 'nfl',
    initialState,
    reducers:{
        setNflUpcoming: (state, action) => {
            state.upcoming = action.payload
        },
        setNflPublicSystems: (state, action) => {
            state.publicSystems = action.payload
        },
    }
})

export const {setNflUpcoming, setNflPublicSystems} = nflSlice.actions;
export default nflSlice.reducer;
