import { Nav } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import '../scss/navs.scss';
import logo from '../images/logos/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faYoutube, faTiktok, faDiscord } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  
  return (
    <Nav className="navLinks" id="footer"> 
      <div id="left-footer">
        <img src={logo} id="logo"/>
        <LinkContainer className="navLink" id="tos" to='/tos'>
          <Nav.Link>Terms of Service</Nav.Link>
        </LinkContainer>
        <LinkContainer className="navLink" id="faq" to='/faq'>
          <Nav.Link>FAQ</Nav.Link>
        </LinkContainer>
      </div>

      <div id="socials">
        <Nav.Link as="a" className="navLink" target="_blank" href="https://twitter.com/LineLeadersLLC">
          <FontAwesomeIcon icon={faTwitter} size="2x"/>
        </Nav.Link>
        <Nav.Link as="a" className="navLink" target="_blank" href="https://www.youtube.com/@LineLeaders">
          <FontAwesomeIcon icon={faYoutube} size="2x"/>
        </Nav.Link>          
        <Nav.Link as="a" className="navLink" target="_blank" href="https://www.tiktok.com/@lineleadersllc">
          <FontAwesomeIcon icon={faTiktok} size="2x"/>
        </Nav.Link>    
        <Nav.Link as="a" className="navLink" target="_blank" href="https://discord.gg/x6NFz8hFun">
          <FontAwesomeIcon icon={faDiscord} size="2x"/>
        </Nav.Link>      
      </div>
    </Nav>
  );
};

export default Footer;