import React, {useState, useEffect} from 'react'
import {Form, FloatingLabel, Row, Col, Button, Image, ToggleButtonGroup, ToggleButton, OverlayTrigger, Popover} from 'react-bootstrap'
import write from '../../../../images/icons/sm-write.svg';
import close from '../../../../images/icons/noun_Close_3850069.svg';
import {ReactComponent as HelpIcon} from '../../../../images/icons/help.svg';
import {ReactComponent as PlusIcon} from '../../../../images/icons/plus.svg';
const Criteria = (props) => {
    const [disp, setDisp] = useState("view");
    const [type, setType] = useState("one-stat");
    const [statLabel, setStatLabel] = useState("");
    const [stat, setStat] = useState("");
    const [statRef, setStatRef] = useState("");
    const [stat2Label, setStat2Label] = useState("");
    const [stat2, setStat2] = useState("");
    const [stat2Ref, setStat2Ref] = useState("");
    const [comp, setComp] = useState("");
    const [cond, setCond] = useState("");
    const [op, setOp] = useState("");
    const [crit, setCrit] = useState({});
    const [errs, setErrs] = useState([]);
    const offensiveStats = {
        "at bats":"batting.AB",
        "runs":"batting.R",
        "hits":"batting.H",
        "team home runs": "batting.HR",
        "RBIs":"batting.RBI",
        "walks taken":"batting.BB",
        "strike outs":"batting.SO",
        "plate appearances":"batting.PA",
        "batting average":"batting.BA",
        "on base percentage":"batting.OBP",
        "slugging percentage":"batting.SLG",
        "OPS": "batting.OPS",
        "pitches taken":"batting.Pit",
        "strikes taken":"batting.Str",
        "doubles":"batting.doubles",
        "triples":"batting.triples",
        "sac flies":"batting.SF",
        "stolen bases":"batting.SB",
        "runners caught stealing":"batting.CS",
        "runners left on base":"batting.LOB",
    };
    const offensiveStatLabels = Object.keys(offensiveStats);
    const defensiveStats = {
        "innings pitched":"pitching.IP",
        "hits given up":"pitching.H",
        "runs given up":"pitching.R",
        "earned runs":"pitching.ER",
        "batters walked":"pitching.BB",
        "strike outs thrown":"pitching.SO",
        "home runs given up":"pitching.HR",
        "ERA":"pitching.ERA",
        "batters faced":"pitching.BF",
        "pitches thrown":"pitching.Pit",
        "strikes thrown":"pitching.Str",
        "strikes by contact thrown":"pitching.Ctct",
        "strikes swinging thrown":"pitching.StS",
        "stikes looking thrown":"pitching.StL",
        "ground balls given up":"pitching.GB",
        "fly balls given up":"pitching.FB",
        "line drives given up":"pitching.LD"
    };
    const defensiveStatLabels = Object.keys(defensiveStats);
    const [lines] = useState([
        "runline.home",
        "runline.away",
        "total"
    ]);

    // run when operation is selected
    useEffect(()=>{
        // if adding criteria change label of stat dropdown
        if(disp === "add"){
            let prnt = document.getElementById("stat");
            let label = prnt.lastElementChild;
    
            if(op !== "") label.textContent = "Stat 1";
            else{ 
                label.textContent = "Stat";
                setStat2("");
            };
        };
    },[op, disp]);

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };    
        // add err class based on updated errs
        errs.forEach(err=>{
            document.getElementById(err).classList.add("err");
        });
    },[errs]);

    
    // function to handle stat selection
    const handleStatChange = (stat_num, val, statLab) =>{
        // get home/away and stat label
        let arr = val.split('.');
        let team = arr[0];
        let stat_type = arr[1];
        let label = arr[2];
        // hold stat for backtesting (ex:home.batting.R)
        let tmpStat;
        if(stat_type === "off"){
            tmpStat = offensiveStats[label];
        }else{
            tmpStat = defensiveStats[label];
        };
        tmpStat =team+"."+tmpStat;
        // set stat1 or stat2
        if(stat_num === 1){
            setStat(tmpStat);
            setStatRef(val);
            setStatLabel(statLab);
        }else if(stat_num === 2){
            setStat2(tmpStat);
            setStat2Ref(val);
            setStat2Label(statLab);
        };
    };

    // function to get label 
    const getLabel = (compText) => {
        let label = '';
        switch(op){
            case "":
                label = statLabel + compText + cond;
                break;
            case "sum":
                label = `sum of ${statLabel} and ${stat2Label} ${compText} ${cond}`;
                break;
            case "diff":
                label = `difference between ${statLabel} and ${stat2Label} ${compText} ${cond}`;
                break;
            default:
                break;
        };
        return label;
    };

    // run when any criteria var changes 
    useEffect(()=>{
        let compText = "";
        let compElem = document.getElementById(comp);
        if(compElem){
            compText = compElem.innerText;
        };
        let label = getLabel(compText);
        setCrit({
            label : label,
            stat: stat,
            statLabel: statLabel,
            statRef:statRef,
            stat2:stat2,
            stat2Label: stat2Label,
            stat2Ref:stat2Ref,
            op:op,
            comp:comp,
            cond:cond
        });
    },[stat, stat2, op, cond, comp]);
    
    // function to edit crit
    const editCrit = (crit, i) => {
        if(crit.stat2 !== ""){
            setType("two-stats");
        };
        setOp(crit.op);
        setStat(crit.stat);
        setStatLabel(crit.statLabel);
        setStatRef(crit.statRef);
        setStat2(crit.stat2);
        setStat2Label(crit.stat2Label);
        setStat2Ref(crit.stat2Ref);
        setComp(crit.comp);
        setCond(crit.cond);
        props.setUpdateIdx(i);
        setDisp("add");
    };

    //function to validate condition value
    const validateCond = ()=>{
        let vals = lines;
        let ops = ["+","-"];
        let tf = false;
        let startIdx = -1;
        let endIdx = -1;
        // condition cannot be empty
        if(cond !== ""){
            // check if string starts with one of the sub strings
            vals.forEach(val=>{
                if(cond.includes(val)){
                    // check if home or away start
                    if(cond.includes)
                    startIdx = cond.indexOf(val);
                    endIdx = val.length-1;
                    return;
                };
            });
            // make sure substring is included
            if(startIdx === 0){
                // split string at end of substring, next character, and remaining text 
                let op = cond.slice(endIdx+1, endIdx+2)
                let num = cond.slice(endIdx+2)
                // if the complete string is a sub option - ret true
                if(op === "") tf = true
                // op has to be one of the options, remain has to be a number
                else if(!ops.includes(op) || isNaN(num) || num.startsWith("+") ||num.startsWith("-")) tf = false
                else tf = true
            }else tf = false;
        };
        return tf;

    };

    // function to validate criteria
    const validateCriteria = () =>{
        // array to hold bad fields
        let tmpErrs = [];
        // validate fields
        // if type is two stats - make sure right fields are selected
        if(type === "two-stats"){
            if(crit.op === "") tmpErrs.push("op");
            if(crit.stat2 === "") tmpErrs.push("stat2");
        };
        // stat has to be selected
        if(crit.stat ==="") tmpErrs.push("stat");
        // comp has to be selected
        if(crit.comp === "") tmpErrs.push("comp");
        // condition either has to be a number or a line and number with operator (ex: ou+2 )
        if(isNaN(Number(crit.cond))){
            let val = validateCond();
            if(val === false) tmpErrs.push("cond");
        }else if (crit.cond === "") tmpErrs.push("cond");
        setErrs(tmpErrs);
        if(tmpErrs.length > 0){
            return false;
        }else return true;
    };

    // run when type toggle changes
    useEffect(()=>{
        let oneStat = document.querySelector("label[for='toggle1']");
        let twoStats = document.querySelector("label[for='toggle2']");
        if(oneStat && twoStats){
            // clear operation, and stat 2 fields if toggling back to one stat
            if(type === "one-stat"){
                oneStat.classList.add("selected");
                twoStats.classList.remove("selected");
                setOp("");
                setStat2("");
            }else{
                oneStat.classList.remove("selected");
                twoStats.classList.add("selected");
            };
        };

    },[type]);



    // function to add crit
    const newCrit = ()=>{
        props.setUpdateIdx(-1);
        setDisp("add");
    };

    // function to handle cancelling of criteria form
    const clearCrit = ()=>{
        setType("one-stat");
        setStat("");
        setStatLabel("");
        setStatRef("");
        setStat2("");
        setStat2Label("");
        setStat2Ref("");
        setComp("");
        setCond("");
        setOp("");
        setDisp("view");
    };

    // function to handle attempt to submit
    const submit = ()=>{
        let tf = validateCriteria();
        if(tf){
            props.addCriteria(crit, props.updateIdx);
            clearCrit();
            setDisp("view");
        };
    };

    return (
        <div id="criteriaDiv">
            <div id="header-row">
                <div className='left-align'>
                    <h5>System Criteria</h5>
                    <OverlayTrigger trigger="click" placement='bottom' rootClose={true}
                        overlay={
                        <Popover>
                            <Popover.Header>Criteria FAQs</Popover.Header>
                            <Popover.Body>
                                <p>How do the stats work when backtesting?</p> 
                                <ul>
                                    <li>When backtesting your systems we look at every game individually, using the season averages up to that point in the season.</li>
                                    <li>E.g. if looking at the home team's homeruns, and it was their 5th game - we would look at the average from their 4 prior games to compare against your condition.</li>
                                </ul>
                                <p>What does the operation field do?</p> 
                                <ul>
                                    <li>The operation field will either look at the difference between, or the sum of, the two statistics you choose.</li>
                                </ul>
                                <p>What is the condition field and why does it look weird?</p> 
                                <ul>
                                    <li>The condition field is what allows you to find the games you would like to bet on.</li>
                                    <li>Examples: 
                                        <ul>
                                            <li>you could look at games where the home team averages at least 3 more runs than the away team.</li>
                                            <li>you could also look at games where both teams combines average at least 2 more runs than the total for the game. (total+2)</li>
                                        </ul>
                                    </li>
                                </ul>
                            </Popover.Body>
                        </Popover>
                        }
                    >  
                        <HelpIcon className="button" id="help-crit"/>
                    </OverlayTrigger>
                </div>
                <div className='right-align'>
                    <PlusIcon className="button" id="addCritBtn" onClick={()=>newCrit()} />
                </div>
            </div>
            {disp === "add" ?
                (
                    <div id="addCritDiv">
                            <ToggleButtonGroup type='radio' defaultValue={type} name="crit-type" id="crit-type">
                                <ToggleButton value={"one-stat"} className="toggleBtn selected" id="toggle1" onChange={(e)=>setType(e.target.value)}  name="crit-type">One Stat</ToggleButton>
                                <ToggleButton value={"two-stats"} className="toggleBtn" id="toggle2" onChange={(e)=>setType(e.target.value)}  name="crit-type">Two Stats</ToggleButton>
                            </ToggleButtonGroup>
                            {type === "two-stats" &&
                                (<FloatingLabel label='Operation' id="op-div">
                                        <Form.Select id="op" defaultValue={op} onChange={(e)=>setOp(e.target.value)}> 
                                            <option value=""></option>
                                            <option value="sum">sum of</option>
                                            <option value="diff">difference between</option>
                                        </Form.Select>
                                </FloatingLabel>)
                            }
                            <FloatingLabel label='Stat' id="stat-div">
                                <Form.Control id="stat"  defaultValue={statRef}  as="select" onChange={(e)=>handleStatChange(1, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                    <option hidden value=""></option>
                                    <option disabled className='dropdown-cat'>Home Offensive Stats</option>
                                    {offensiveStatLabels.map(stat=>{
                                        return(
                                            <option value={`home.off.${stat}`} key={`home.${stat}`} >{`home ${stat}`}</option>
                                        )
                                    })}
                                    <option disabled className='dropdown-cat'>Home Defensive Stats</option>
                                    {defensiveStatLabels.map(stat=>{
                                        return(
                                            <option value={`home.def.${stat}`} key={`home.pitching.${stat}`} >{`home ${stat}`}</option>
                                        )
                                    })}
                                    <option disabled className='dropdown-cat'>Away Offensive Stats</option>
                                    {offensiveStatLabels.map(stat=>{
                                        return(
                                            <option value={`away.off.${stat}`} key={`away.batting.${stat}`} >{`away ${stat}`}</option>
                                        )
                                    })}
                                    <option disabled className='dropdown-cat'>Away Defensive Stats</option>
                                    {defensiveStatLabels.map(stat=>{
                                        return(
                                            <option value={`away.def.${stat}`} key={`away.pitching.${stat}`} >{`away ${stat}`}</option>
                                        )
                                    })}
                                </Form.Control>
                            </FloatingLabel>

                        {type === "two-stats" &&
                            (<>
                                <span id="stat-split">and</span>
                                <FloatingLabel label='Stat 2' id="stat2-div">
                                    <Form.Select id="stat2" defaultValue={stat2Ref} as="select" onChange={(e)=>handleStatChange(2, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                        <option hidden value=""></option>
                                        <option disabled className='dropdown-cat'>Home Offensive Stats</option>
                                        {offensiveStatLabels.map(stat=>{
                                            return(
                                                <option value={`home.off.${stat}`} key={`home.${stat}`} >{`home ${stat}`}</option>
                                            )
                                        })}
                                        <option disabled className='dropdown-cat'>Home Defensive Stats</option>
                                        {defensiveStatLabels.map(stat=>{
                                            return(
                                                <option value={`home.def.${stat}`} key={`home.pitching.${stat}`} >{`home ${stat}`}</option>
                                            )
                                        })}
                                        <option disabled className='dropdown-cat'>Away Offensive Stats</option>
                                        {offensiveStatLabels.map(stat=>{
                                            return(
                                                <option value={`away.off.${stat}`} key={`away.batting.${stat}`} >{`away ${stat}`}</option>
                                            )
                                        })}
                                        <option disabled className='dropdown-cat'>Away Defensive Stats</option>
                                        {defensiveStatLabels.map(stat=>{
                                            return(
                                                <option value={`away.def.${stat}`} key={`away.pitching.${stat}`} >{`away ${stat}`}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                            </>)
                            }
                            <FloatingLabel label='Comparison' id="comp-div">
                                <Form.Select defaultValue={comp} id="comp" onChange={(e)=>setComp(e.target.value)}>
                                    <option value=""></option>
                                    <option id="gte" value="gte">{">="}</option>
                                    <option id="lte" value="lte">{"<="}</option>
                                    <option id="eq" value="eq">{"="}</option>
                                </Form.Select>
                            </FloatingLabel>
                        <div id="cond-div">
                            <div id="cond-dropdown">
                                <FloatingLabel label='Condition' id="cond-label">
                                    <Form.Control id="cond" type="text" value={cond} onChange={e=>setCond(e.target.value.replace(/\s+/g, ''))}/>
                                </FloatingLabel>
                                <Form.Select onChange={(e)=>setCond(e.target.value)} id="cond-select">
                                    <option defaultValue hidden value=""></option>
                                    <option disabled className='dropdown-cat'>Lines</option>
                                    {lines.map(line=>{
                                        return(
                                            <option value={line} key={line} >{line}</option>
                                        )
                                    })}
                                </Form.Select>
                                <Form.Text muted>
                                    The condition can be a number, one of the lines from the dropdown, or a combination of the two (e.g. total+2)
                                </Form.Text>
                            </div>
                        </div>
                        <div id='submit-btns'>
                            <Button className="secondaryBtn" id="cancelCritBtn" onClick={()=>clearCrit()}>Cancel</Button>
                            <Button className="primaryBtn" id="saveCritBtn" onClick={()=>submit()}>Save Criteria</Button>
                        </div>
                    </div>                       

                )
                :
                (
                    <div id='critList'>
                        {props.criteria.map((crit,i)=>{
                            return(
                            <div key={i} className="criteria">
                                <Row key={i}>
                                    <Col>{crit.label}</Col>
                                    <Col xs={2} s={1}>
                                        <Image src={write} width="15" height="auto" onClick={()=>editCrit(crit, i)}/>
                                    </Col>
                                    <Col xs={2} s={1}>
                                        <Image src={close} width="15" height="auto" onClick={()=>props.removeCrit(i)}/>
                                    </Col>
                                </Row>
                            </div>
                            )
                        })}
                    </div>
                )
            }
            
        </div>
    )
}

export default Criteria