import React, {useEffect, useState} from 'react';
import apiService from '../../utils/apiService';
import {Form, Card} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setEmailSub } from '../../redux/userSlice';

// Styling
import '../../scss/pages/profile.scss'

const Notifications = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const emailSub = useSelector((state) => state.user.emailSub);
    const [emailStatus, setEmailStatus] = useState(emailSub);
    const [emailSubChange, setEmailSubChange] = useState(false);

    useEffect(()=> {
        if(emailStatus !== emailSub){
            setEmailSubChange(true);
        }else{
            setEmailSubChange(false);
        };
    },[emailStatus]);

    const handleCancel = () => {
        setEmailStatus(emailSub);
        setEmailSubChange(false);
    };

    const updateEmailNotifications = async (type) => {
        try{
            // Subscribe user to email notifications
            if(type === "sub"){
                let id_token = localStorage.getItem("id_token");
                if(id_token){
                    const body = {
                        id_token: id_token
                    };
                    let payload = await apiService('app/subscribe-email', 'POST', body);
                    if(payload.message === "Email subscription updated"){
                        dispatch(setEmailSub(true));
                        setError(null);
                    }else{
                        setError(payload.message);
                        setEmailStatus(emailSub);
                    };    
                }else{
                    setError("No ID token found, please sign out and back in before attempting again");
                    setEmailStatus(emailSub);
                };
            // Unsubscribe user to email notifications
            }else{
                let payload = await apiService('app/unsub-email');
                if(payload.message === "Email subscription updated"){
                    dispatch(setEmailSub(false));
                    setError(null);
                }else{
                    setError(payload.message);
                    setEmailStatus(emailSub);
                };    
            };
        }catch(error){
            setEmailStatus(emailSub);
            setError("Something went wrong, please try again later")
        };

        setEmailSubChange(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(emailStatus == true){
            updateEmailNotifications("sub");
        }else{
            updateEmailNotifications("unsub");
        };
    };

    return (
        <div id='noti-settings'>
            <h3 id="header">Notification Settings</h3>
            <Form onSubmit={handleSubmit}>
                <Card id="devCard">
                    <Card.Header>Email Notifications</Card.Header>
                    <Card.Body>
                        <div class="field">
                            <Form.Check type='switch' label="Notifications" checked={emailStatus} onChange={()=>setEmailStatus(!emailStatus)}/> 
                            {error !== null && (
                                <p className='errMsg'>{error}</p>
                            )}
                        </div>
                        {(emailSubChange) && (
                            <>
                                <button className='secondaryBtn' id="devCancel" onClick={()=>handleCancel()}>Cancel</button>
                                <button className='primaryBtn' id="devSub" type="submit">Save Changes</button>
                            </>
                        )}
                    </Card.Body>    
                </Card>
            </Form>
        </div>
    );
};

export default Notifications;