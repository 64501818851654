import React from 'react';
import {Table} from 'react-bootstrap'


const FailedGamelogs = (props) => {
    return (
        <Table>
            <thead>
                <tr>
                    <th colSpan="2">Failed Gamelogs</th>
                </tr>
            </thead>
            <tbody>
                {props.games.length > 0 ?
                    props.games.map(game=>{
                        return(
                            <tr key={game._id}> 
                                <td colSpan="2">
                                    {game.link}
                                </td>
                            </tr>
                        )
                    })
                        :
                    <>No failures</>
                }
             
            </tbody>
        </Table>    )
}

export default FailedGamelogs