import React, {useState} from 'react'
import styles from '../../scss/pages/tos.module.scss';

const TermsOfService = () => {
    return (
        <div className='full-page'>
            <div className={'page-section'}>
                <div className={styles.tos} >
                    <div className={styles.header}>
                        <h1>Terms of Service</h1>
                        <p id="lastUpdated">Last Updated: 9/15/2023</p>
                    </div>
                    <div className={styles.content}> 
                    <p>Welcome to LineLeaders ("We", "Our", or "Us"). By using our website, services, or any of our tools (collectively, the "Service"), you are agreeing to the following terms and conditions (the "Terms of Service"). Please review the following terms carefully.</p>
                        <ol>
                            <li>
                                <h6>Acceptance of Terms</h6>
                                <p>By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to these Terms, you should not use or access the Service.</p>
                            </li>
                            <li>
                                <h6>Statistical Accuracy</h6>
                                <p>We strive to present accurate, up-to-date, and reliable statistical information. However, due to the nature of statistical analysis and the potential for human or technological error, we cannot guarantee the accuracy, reliability, or completeness of any data provided. Users should use our Service as a general guide and not as the sole basis for any decisions.</p>
                            </li>
                            <li>
                                <h6>No Financial or Betting Advice</h6>
                                <p>The information and services provided on our website are for informational and entertainment purposes only and do not constitute financial advice, betting advice, or any other kind of professional advice. Any wagers or bets you choose to place based on our Service are made entirely at your own discretion and risk. While we endeavor to provide accurate and up-to-date information, we make no guarantees or warranties of any kind. Always remember that gambling involves risk, and you should only bet what you can afford to lose. If you believe you may have a gambling problem, please seek help from appropriate services in your jurisdiction.</p>
                            </li>
                            <li>
                                <h6>Limitation of Liability</h6>
                                <p>To the fullest extent permitted by law, LineLeaders and its affiliates, partners, or licensors shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</p>
                                <ul>
                                    <li>Your use or inability to use the Service;</li>
                                    <li>Any unauthorized access to or use of our servers and/or any personal information stored therein;</li>
                                    <li>Any interruption or cessation of transmission to or from the Service.</li>
                                </ul>
                            </li>
                            <li>
                                <h6>Indemnification</h6>
                                <p>You agree to defend, indemnify, and hold harmless LineLeaders, its parent company, officers, directors, employees, and agents, from and against any claims, actions, or demands, including without limitation, reasonable legal and accounting fees, arising or resulting from your breach of these Terms or your access to, use, or misuse of the Service.</p>
                            </li>
                            {/* <li>
                                <h6>Privacy Policy</h6>
                                <p>Your use of the Service is also governed by our Privacy Policy, which can be found at [Link to Privacy Policy].</p>
                            </li> */}
                            <li>
                                <h6>Changes to Terms</h6>
                                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion.</p>
                            </li>
                            <li>
                                <h6>Termination</h6>
                                <p>We reserve the right to terminate or suspend access to our Service, without prior notice, for conduct that we believe violates these Terms or is harmful to other users of the Service, us, or third parties, or for any other reason.</p>
                            </li>
                            <li>
                                <h6>Governing Law</h6>
                                <p>These Terms of Service are governed by and construed in accordance with the laws of the United States, without regard to its conflict of law principles.</p>
                            </li>
                        </ol>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TermsOfService