const BASE_URL = 'https://api.lineleaders.net';
const BASE_URL_V2 = 'https://apiv2.lineleaders.net';
// const BASE_URL = 'http://localhost:3001';


// function to refresh user session 
export const refreshSession = async() => {
    // get refresh token 
    const refresh_token = localStorage.getItem('refresh_token');
    const body = {refreshToken: refresh_token}
    // url for refresh endpoint
    const url = `${BASE_URL}/accounts/refreshSession`;
    // request options 
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(body)
    };
    // try to get new session
    let res = await fetch(url, options);
    if(res.ok === true){
        // store new session in local storage
        let tokens = await res.json();
        localStorage.setItem('access_token', tokens.accessToken);
        localStorage.setItem('id_token', tokens.idToken);
        return(true);
    }else{
      return(false);
    };
};

// function to validate initial session
export const validateSession = async() => {
    // access token for session
    const token = localStorage.getItem('access_token');
    // url for session endpoint
    const url = `${BASE_URL}/accounts/validateSession`;
    const options = {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': `Bearer ${token}`
      }
    };
    // validate session
    const response = await fetch(url, options);
    if(response.status === 200){
      return true;
    // if session no longer valid - try to refresh with token 
    }else{
      const sessionCreated = await refreshSession();
      if(sessionCreated){
        return true;
      }else{
        return false;
      };
    };
};

// function to handle API requests 
const apiService = async(endpoint, method = 'GET', body = null, headers = {}, initUrl='v1') => {
  let url = '';
  let token = '';
  if(initUrl === 'v1'){
    url = `${BASE_URL}/${endpoint}`;
    token = localStorage.getItem('access_token');
  }else{
    url = `${BASE_URL_V2}/${endpoint}`;
    token = localStorage.getItem('id_token');
  }


  // set options 
  const options = {
    method: method,
    body: body && JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      ...headers
    }
  };  
  // fetch api resources
  const response = await fetch(url, options);
  let payload;
  if(response.ok){
    payload = await response.json();
  }else if(response.status === 401){
    const sessionCreated = await refreshSession();
    if(sessionCreated){
      return apiService(endpoint, method, body, headers);
    }else{
      return Promise.reject('Session Expired');
    }
  }else{
    return Promise.reject('Server Error');
  }
  // return a Promise that resolves with the payload
  return Promise.resolve(payload);
};

export default apiService;