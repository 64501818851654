import React, { useEffect, useState } from 'react';
import {Form} from "react-bootstrap";

const MlbBettingData = (props) => {
    const [totals, setTotals] = useState([]);
    const [runline, setRunline] = useState([]);
    const [moneyline, setMoneyline] = useState([]);
    const [selected, setSelected] = useState("totals");


    // run when game prop is set 
    useEffect(()=>{
        if('totals' in props.game){
            // loop through each set of odds, filter out any empty arrays, return most recent bet for each book
            let tmpTotals = [];            
            for(const sb of props.game.totals){
                // only include books that have lines
                if(sb.lines.length > 0){
                    const runs = sb.lines[0].runs;
                    const over = sb.lines[0].over;
                    const under = sb.lines[0].under;
                    tmpTotals.push({book:sb.book, over: over, under: under, runs: runs});
                };
            };
            setTotals(tmpTotals);
        };

        if('runline' in props.game){
            let tmpRunline = [];
            for(const sb of props.game.runline){
                // only include books that have lines
                if(sb.lines.length > 0){
                    const away = {runs: sb.lines[0].away.runs, odds: sb.lines[0].away.odds};
                    const home = {runs: sb.lines[0].home.runs, odds: sb.lines[0].home.odds};
                    tmpRunline.push({book:sb.book, home: home, away: away});
                };
            };
            setRunline(tmpRunline);
        };

        if('ml' in props.game){
            let tmpML = [];
            for(const sb of props.game.ml){
                // only include books that have lines
                if(sb.lines.length > 0){
                    const away = sb.lines[0].away;
                    const home = sb.lines[0].home;
                    tmpML.push({book:sb.book, home: home, away: away});
                };
            };
            setMoneyline(tmpML)
        };
    },[props.game]);

    return (
    <>
        <Form.Select id="bet-select" value={selected} onChange={e => setSelected(e.target.value)}>
            <option value="totals">Totals</option>
            <option value="runline">Runline</option>
            <option value="moneyline">Moneyline</option>
        </Form.Select>
        
        {selected === "totals" && (
            totals.length > 0 ? (
                <table className='oddsTable'>
                    <thead>
                        <tr>
                            <th>Book</th>
                            <th>Line</th>
                            <th>Over</th>
                            <th>Under</th>
                        </tr>
                    </thead>
                    <tbody>
                    {totals.map(book => {
                        return(
                            <tr key={book.book}>
                                <td>{book.book}</td>
                                <td>{book.runs}</td>
                                <td>{book.over}</td>
                                <td>{book.under}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            ) : (
                <h6>Odds unavailable</h6>
            )
        )}

        {selected === "moneyline" && (
            moneyline.length > 0 ? (
                <table className='oddsTable'>
                    <thead>
                        <tr>
                            <th>Book</th>
                            <th>Home</th>
                            <th>Away</th>
                        </tr>
                    </thead>
                    <tbody>
                    {moneyline.map(book => {
                        return(
                            <tr key={book.book}>
                                <td>{book.book}</td>
                                <td>{book.home}</td>
                                <td>{book.away}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            ) : (
                <h6>Odds unavailable</h6>
            )
        )}

        {selected === "runline" && (
            runline.length > 0 ? (
                <table className='oddsTable'>
                    <thead>
                        <tr>
                            <th>Book</th>
                            <th>Home</th>
                            <th>Away</th>
                        </tr>
                    </thead>
                    <tbody>
                    {runline.map(book => {
                        return(
                            <tr key={book.book}>
                                <td>{book.book}</td>
                                <td>{`${book.home.runs} ${book.home.odds}`}</td>
                                <td>{`${book.away.runs} ${book.away.odds}`}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            ) : (
                <h6>Odds unavailable</h6>
            )
        )}
    </>
    );
};

export default MlbBettingData;