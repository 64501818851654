import SignInForm from '../forms/SignIn';
import styles from '../../scss/pages/loginSignup.module.scss';
import { useNavigate } from 'react-router-dom';


const SignInPage = () => {
  const navigate = useNavigate();

  return (
    <div className='full-page'>
      <div className={'page-section'}>
        <div className={styles.mainView}>
          <h1 className={styles.title}>Sign In</h1>
          <SignInForm navigate={navigate} className={styles.formFields}/>
          <div className={styles.footer}>
              <p>Don't have an account? <a className={styles.pageLink} onClick={()=>navigate("/signup")}>Create One</a></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;