import React, {useEffect, useState} from 'react';
import {FloatingLabel, FormControl} from 'react-bootstrap';

const StatComponent = ({sport, statObj, setStatObj, stats, dispCrit}) => {
    const [errs, setErrs] = useState([]);
    const [localStat, setLocalStat] = useState({...statObj})
    const statTypeLabels = {
        "mlb": {
            "off": "hitting",
            "def": "pitching"
        },
        "nfl": {
            "off.rushing" : "offensive rushing",
            "off.passing": "offensive passing",
            "off.receiving": "offensive receiving",
            "off.general": "general offensive",
            "def.passing": "defensive passing",
            "def.tackles": "defensive tackles",
            "def.fumbles": "defensive fumbles",
            "def.general": "general defensive",
            "st.kicking": "special teams kicking"
        }
    };

    const getStatLabel = (obj) => {
        try{
            return(`${obj.team} team average ${stats[obj.type][obj.stat]}`);
        }catch{
            return("");
        };
    };

    // run when stat type changes
    const handleFieldChange = (val, type)=> {
        let objCopy = {...localStat};
        switch(type){
            case "team":
                objCopy.team = val;
                break; 
            case "type": 
                objCopy.type = val;
                objCopy.stat = "";
                break;
            case "stat": 
                objCopy.stat = val;
                break;
        };
        const label = getStatLabel(objCopy);
        objCopy.label = label;
        setLocalStat(objCopy);
    };

    const checkErrs = () => {
        let tmpErrs = [];
        // team validation 
        if(localStat.team !== "home" && localStat.team !== "away") tmpErrs.push("team");
        // stat type & stat validation
        if(localStat.type in stats){
            if(!(localStat.stat in stats[localStat.type])) tmpErrs.push("stat");
        }else{
            tmpErrs.push("statType");
        };
        setErrs(tmpErrs);
        if(tmpErrs.length > 0){
            return(true)
        }else{
            return(false);
        };
    };

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            const elem = document.getElementById(err);
            if(elem){
                elem.classList.add("err");
            };
        });
    },[errs]);

    const validateSubmit = () => {
        let isErrs = checkErrs();
        if(!isErrs){
            setStatObj(localStat);
            dispCrit();
        };
    };

    return(
        <>
            <div id='header-row'>
                <h2>Choose Stat</h2>
            </div>
            <FloatingLabel label='Team' id="team-div" className='sysField'>
                <FormControl as="select" id="team" value={localStat.team} onChange={(e) => handleFieldChange(e.target.value, "team")}>
                    <option hidden value=""></option>
                    <option value="home">home team</option>
                    <option value="away">away team</option>
                </FormControl>
            </FloatingLabel>
            <FloatingLabel label='Stat Type' id="stat-type-div" className='sysField'>
                <FormControl as="select" id="statType" value={localStat.type} onChange={(e) => handleFieldChange(e.target.value, "type")}>
                    <option hidden value=""></option>
                    {Object.keys(stats).map(key => 
                        <option value={key} key={key}>{statTypeLabels[sport][key]}</option>
                    )}
                </FormControl>
            </FloatingLabel>
            {localStat.type !== "" && (
                <FloatingLabel  label='Stat' id="stat-div" className='sysField'>
                    <FormControl as="select" id="stat" value={localStat.stat} onChange={e=>handleFieldChange(e.target.value, "stat")}>
                        <option hidden value=""></option>
                        {Object.keys(stats[localStat.type]).map(stat => (
                            <option key={stat} value={stat}>{stats[localStat.type][stat]}</option>
                        ))}
                    </FormControl>
                </FloatingLabel>
            )}
            <div id='submit-btns'>
                <button className='secondaryBtn' onClick={()=>dispCrit()}>Cancel</button>
                <button className='primaryBtn' onClick={()=>validateSubmit()}>Save Stat</button>
            </div>
        </>
    )
};

export default StatComponent;