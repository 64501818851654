import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

const useDeepCompareEffect = (callback, dependencies) => {
    const currentDependenciesRef = useRef();

    // Compare current dependencies with previous ones
    if (!isEqual(currentDependenciesRef.current, dependencies)) {
        // Update currentDependenciesRef if dependencies have changed
        currentDependenciesRef.current = dependencies;
    }

    // Use useEffect with the updated dependencies
    useEffect(callback, [currentDependenciesRef.current]);
};

export { useDeepCompareEffect };