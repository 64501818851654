import React, { useState, useEffect} from 'react'
import { useSelector, useDispatch} from 'react-redux';
import { ListGroup, Modal, Button, Form, FloatingLabel, Row, Col } from 'react-bootstrap';
import { setNflMissingOdds } from '../../../redux/applicationSlice';
import apiService from '../../../utils/apiService';
import { useClearUserSession } from '../../../hooks/user-hooks';
import { useNavigate } from 'react-router-dom';

const MissingNFLOdds = () => {
    const clearSession = useClearUserSession();
    const navigate = useNavigate();
    const missing = useSelector((state) => state.app.nfl_missing_odds);
    const dispatch = useDispatch();
    const [game, setGame] = useState({});
    const [show, setShow] = useState(false);
    const [totalPts, setTotalPts] = useState(null);
    const [totalOver , setTotalOver] = useState(null);
    const [totalUnder , setTotalUnder] = useState(null);
    const [spreadHomePts , setSpreadHomePts ] = useState(null);
    const [spreadHomeOdds , setSpreadHomeOdds] = useState(null);
    const [spreadAwayPts , setSpreadAwayPts] = useState(null);
    const [spreadAwayOdds , setSpreadAwayOdds] = useState(null);
    const [homeML , setHomeML] = useState(null);
    const [awayML , setAwayML] = useState(null);
    const [errs, setErrs] = useState([]);
    const [odds, setOdds] = useState({});
    const [reviewing, setReviewing] = useState(false);

    // run when any criteria var changes - set odds object
    useEffect(()=>{
        // make sure game is set
        if("_id" in game){            
            setOdds({
                season : game.season,
                totals: [
                    {
                        book: "N/A",
                        lines: [
                            {
                                timestamp: game.date,
                                runs: parseFloat(totalPts),
                                over: parseInt(totalOver),
                                under: parseInt(totalUnder)
                            }
                        ]
                    }
                ],
                spread: [
                    {
                        book: "N/A",
                        lines: [
                            {
                                timestamp: game.date,
                                home:{
                                    odds: parseInt(spreadHomeOdds),
                                    runs: parseFloat(spreadHomePts)
                                },
                                away:{
                                    odds: parseInt(spreadAwayOdds),
                                    runs: parseFloat(spreadAwayPts)
                                }
                            }
                        ]
                    }
                ],
                ml: [
                    {
                        book: "N/A",
                        lines: [
                            {
                                timestamp: game.date,
                                home: parseFloat(homeML),
                                away: parseFloat(awayML)
                            }
                        ]
                    }
                ]
            });
        };

    },[totalPts, totalOver, totalUnder, spreadAwayOdds, spreadAwayPts, spreadHomeOdds, spreadHomePts, homeML, awayML, game]);

    // run when game changes - reset state if empty 
    useEffect(()=>{
        if(!game.hasOwnProperty("gamelog_id")){
            setTotalPts(null);
            setTotalOver(null);
            setTotalUnder(null);
            setSpreadAwayOdds(null);
            setSpreadAwayPts(null);
            setSpreadHomeOdds(null);
            setSpreadHomePts(null);
            setAwayML(null);
            setHomeML(null);
        };
    },[game]);

    // handle modal closure
    const handleClose = () => {
        setShow(false);
        setGame({});
        setReviewing(false);
    };

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };    
        // add err class based on updated errs
        errs.forEach(err=>{
            document.getElementById(err).classList.add("err");
        });
    },[errs]);

    // function to open game modal
    const openGame = (game)=>{
        setGame(game);
        setShow(true);
    };

    const validateForm = () => {
        // array to hold bad fields
        let tmpErrs = []
        // validate fields
        if(totalPts == null) tmpErrs.push("total-pts");
        if(totalOver == null) tmpErrs.push("total-over");
        if(totalUnder == null) tmpErrs.push("total-under");
        if(spreadAwayOdds == null) tmpErrs.push("away-spread-odds");
        if(spreadAwayPts == null) tmpErrs.push("away-spread-pts");
        if(spreadHomeOdds == null) tmpErrs.push("home-spread-odds");
        if(spreadHomePts == null) tmpErrs.push("home-spread-pts");
        if(homeML == null) tmpErrs.push("home-ml-odds");
        if(awayML == null) tmpErrs.push("away-ml-odds");

        setErrs(tmpErrs);
        if(tmpErrs.length === 0){
            setReviewing(true);
        };

    };

    // function to insert odds record to database   
    const insertOdds = async() => {
        // api params to create odds record
        const body = {
            odds: odds,
            game: game 
        };
        const response = await apiService('nfl/insert-odds', 'POST', body);
        if(response !== 'Server Error' && response !== 'Session Expired'){
            // remove from missing redux array
            const tmpMissing = missing.filter(record =>{return(record._id !== game._id)});
            dispatch(setNflMissingOdds(tmpMissing));
            // close modal
            handleClose();
        }else{ // else redirect to login and clear user data
            clearSession();
            navigate('/signin');
        };
    };

    // function to handle submission 
    const submit = () =>{
        // create odds record 
        insertOdds();
    };



    return(
        <>
            <ListGroup>
                
                {missing != undefined && missing.map(game=>{
                    return(
                        <ListGroup.Item key={game._id} onClick={()=>openGame(game)}>
                            <p>Missing ID: {game._id}</p>
                            <p>Season: {game.season}</p>
                            <p>Week: {game.week}</p>
                            <p>Home: {game.home_id}</p>
                            <p>Away: {game.away_id}</p>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Game Lines</Modal.Title>
                </Modal.Header>
                {!reviewing ?
                    <>
                        <Modal.Body>
                            <div>
                                <p>Missing ID: {game._id}</p>
                                <p>Season: {game.season}</p>
                                <p>Week: {game.week}</p>
                                <p>Home: {game.home_id}</p>
                                <p>Away: {game.away_id}</p>
                            </div>
                            <Form> 
                                <h3>Totals</h3>
                                <Form.Group>
                                    <>
                                        <FloatingLabel
                                            controlId="total-pts"
                                            label="Points"
                                            className="mb-3"
                                        >
                                            <Form.Control type="number" defaultValue={totalPts} onChange={(e)=>setTotalPts(e.target.value)}/>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="total-over"
                                            label="Over"
                                            className="mb-3"
                                        >
                                            <Form.Control type="number" defaultValue={totalOver} onChange={(e)=>setTotalOver(e.target.value)}/>
                                        </FloatingLabel>
                                        <FloatingLabel
                                            controlId="total-under"
                                            label="Under"
                                            className="mb-3"
                                        >
                                            <Form.Control type="number" defaultValue={totalUnder} onChange={(e)=>setTotalUnder(e.target.value)}/>
                                        </FloatingLabel>
                                    </>                        
                                </Form.Group>

                                <h3>Spread</h3>
                                <Form.Group>
                                    <>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                controlId="home-spread-pts"
                                                label={`${game.home_id} pts`}
                                                className="mb-3"
                                                >
                                                    <Form.Control type="number" defaultValue={spreadHomePts} onChange={(e)=>setSpreadHomePts(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    controlId="home-spread-odds"
                                                    label={`${game.home_id} odds`}
                                                    className="mb-3"
                                                    >
                                                    <Form.Control type="number" defaultValue={spreadHomeOdds} onChange={(e)=>setSpreadHomeOdds(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FloatingLabel
                                                controlId="away-spread-pts"
                                                label={`${game.away_id} pts`}
                                                className="mb-3"
                                                >
                                                    <Form.Control type="number" defaultValue={spreadAwayPts} onChange={(e)=>setSpreadAwayPts(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                            <Col>
                                                <FloatingLabel
                                                    controlId="away-spread-odds"
                                                    label={`${game.away_id} odds`}
                                                    className="mb-3"
                                                    >
                                                    <Form.Control type="number" defaultValue={spreadAwayOdds} onChange={(e)=>setSpreadAwayOdds(e.target.value)}/>
                                                </FloatingLabel>
                                            </Col>
                                        </Row>
                                    </>                        
                                </Form.Group>
                                
                                <h3>Moneyline</h3>
                                <Form.Group>
                                    <FloatingLabel
                                        controlId="home-ml-odds"
                                        label={`${game.home_id} ML`}
                                        className="mb-3"
                                        >
                                        <Form.Control type="number" defaultValue={homeML} onChange={(e)=>setHomeML(e.target.value)}/>
                                    </FloatingLabel>          
                                    <FloatingLabel
                                        controlId="away-ml-odds"
                                        label={`${game.away_id} ML`}
                                        className="mb-3"
                                        >
                                        <Form.Control type="number" defaultValue={awayML} onChange={(e)=>setAwayML(e.target.value)}/>
                                    </FloatingLabel>                    
                                </Form.Group>
                            </Form>
                        </Modal.Body>   
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={()=>validateForm()}>
                                Review Lines
                            </Button>
                        </Modal.Footer>
                    </>
                    :
                    <>
                        <Modal.Body>
                            <div style={{whiteSpace: "pre"}}>
                                {JSON.stringify(odds, null, 4)}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>setReviewing(false)}>
                                Back
                            </Button>
                            <Button variant="primary" onClick={submit}>
                                Save Lines
                            </Button>
                        </Modal.Footer>
                    </>

                }
            </Modal>
        </>
    )

}

export default MissingNFLOdds