import '../../../../scss/pages/create_system.scss';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

// Components 
import System from './System';
import BacktestDisp from './BacktestDisp';
import AuthProtected from '../../../HOCs/AuthProtected';

const BuildSystem = () => {
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [sysType, setSysType] = useState('private');
    const [criteria, setCriteria] = useState([]);
    const [updateIdx, setUpdateIdx] = useState(-1);
    const [bet, setBet] = useState("");
    const [id, setId] = useState("");
    const location = useLocation();
    const pageType = location.state.pageType;

    // Run when component is rendered, if system is passed - update system, if no system is passed - create new system
    useEffect(()=>{
        if(pageType === "edit" || pageType === "view"){
            let system = location.state.system;
            setName(system.name);
            setCriteria(system.criteria);
            setBet(system.bet);
            setId(system._id);
        };
    },[pageType]);
    
    //Method to show system
    function dispSys(){
        setStep(1);
    };

    //Method to show backtesting
    function dispBacktest(){
        setStep(3);
    };

    // function to add new or update criteria
    const addCriteria = (crit, i) => {
        let tmpCrit = criteria;
        if(i !== -1){
            tmpCrit[i] = crit;
        }else tmpCrit.push(crit);
        setCriteria(tmpCrit);
    };

    // function to remove criteria 
    const removeCrit = (i) =>{
        let tmpCrit = [...criteria];
        tmpCrit.splice(i, 1);
        setCriteria(tmpCrit);
    }

    switch(step){
        case 1:
            return (
                <System name={name} bet={bet}
                        criteria={criteria} setName={setName} 
                        setBet={setBet} dispBacktest={dispBacktest} removeCrit={removeCrit}
                        pageType={location.state.pageType} addCriteria={addCriteria} 
                        updateIdx={updateIdx} setUpdateIdx={setUpdateIdx}
                        sysType={sysType} setSysType={setSysType}
                />
            )
        case 3: 
            return (
                <BacktestDisp dispSys={dispSys} 
                    system={
                        {
                            bet:bet,
                            criteria:criteria,
                            name:name,
                            units:1,
                            sysType
                        }
                    }
                    id={id}
                    pageType={pageType} />
            )
    };
};

export default AuthProtected(BuildSystem);