import { useEffect, useState } from 'react';
import {Form, FloatingLabel} from 'react-bootstrap'
import styles from '../../scss/pages/loginSignup.module.scss';

const ConfirmPassForm = (props) => {
    const [isValid, setIsValid] = useState(false);
    const [codeValid, setCodeValid] = useState(false);
    const [newPassValid, setNewPassValid] = useState(false);
    const [confPassValid, setConfPassValid] = useState(false);

    const handleSubmit = (e)=>{
        e.preventDefault();
        props.handleSubmit();
    };

    const validateCode = (e)=>{
        let tmpCode = e.target.value
        props.setCode(tmpCode)
        if(tmpCode !== "" & tmpCode !== null & tmpCode !== undefined){
            setCodeValid(true)
        }   
    }
    const validateNewPass = (e)=>{
        let tmpPass = e.target.value
        props.setNewPass(tmpPass)
        const passRegex = /(?=.*\d)(?=.*[!@#$%^&?*])(?=.*[a-z])(?=.*[A-Z]).{8,}/;
        setNewPassValid(passRegex.test(tmpPass));   
    }

    const validateConfPass = (e)=>{
        let tmpPass = e.target.value
        props.setConfPass(tmpPass)
        setConfPassValid(tmpPass === props.newPass)
    }

    useEffect(()=>{
        if(codeValid & newPassValid & confPassValid){
            setIsValid(true);
        };
    },[codeValid, newPassValid, confPassValid]);


    return (
        <Form onSubmit={(e)=>handleSubmit(e)} noValidate className={styles.resetPassForm}>
            <FloatingLabel label='Code' className={styles.formField}>
                <Form.Control type="text" id="code" minLength={4} required value={props.code} onChange={validateCode} placeholder="Enter Code"/>
            </FloatingLabel>
            <FloatingLabel label='New Password' required pattern="" className={styles.formField}>
                <Form.Control type="password" id="newPass" minLength={4} required value={props.newPass} onChange={validateNewPass} placeholder="Enter New Password"/>
                <Form.Control.Feedback type="invalid">Password must contain at least 8 characters, including uppercase and lowercase letters, numbers, and special characters.</Form.Control.Feedback>
            </FloatingLabel>
            <FloatingLabel label='Confirm Password' required className={styles.formField}>
                <Form.Control type="password" id="confirmPass" minLength={4} required value={props.confPass} onChange={validateConfPass} placeholder="Confirm Password"/>
            </FloatingLabel>
            <div className='invalid-feedback' style={{display:"block"}}>{props.err}</div>
            <button className={`primaryBtn ${styles.loginSignupPageBtn}`} disabled={!isValid} type="submit">Confirm Password</button> 
        </Form>
    );
};

export default ConfirmPassForm;