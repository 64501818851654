import React, {useState, useEffect} from 'react';
import {Form, FloatingLabel} from 'react-bootstrap';
import { mlbOffStats, mlbDefStats, mlbOffStatVerbs, mlbDefStatVerbs } from '../../../../../../utils/mlbUtils';
import { nflStats, nflStatVerbs } from '../../../../../../utils/nflUtils';
import write from '../../../../../../images/icons/sm-write.svg';
import {ReactComponent as PlusIcon} from '../../../../../../images/icons/plus.svg';

import StatComponent from '../StatComponent';

const StatAverages = (props) => {
    const [statDisp, setStatDisp] = useState(null);
    const [sport, setSport] = useState("");
    const [compType, setCompType] = useState("");
    const [statObj, setStatObj] = useState({
        team: "",
        type: "",
        stat: "",
        label: ""
    });
    const [statObj2, setStatObj2] = useState({
        team: "",
        type: "",
        stat: "",
        label: ""
    });
    const [rangeType, setRangeType] = useState("");
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const [initCrit, setInitCrit] = useState(null);
    const [critIdx, setCritIdx] = useState(null);
    const [errs, setErrs] = useState([]);
    const [errMsg, setErrMsg] = useState(null);
    const stats = {
        mlb: {
            off: mlbOffStats,
            def: mlbDefStats
        },
        nfl: nflStats
    };
    const statVerbs = {
        mlb: {
            off: mlbOffStatVerbs,
            def: mlbDefStatVerbs
        },
        nfl: nflStatVerbs
    };

    const formFields = {
        min:
            <FloatingLabel id="min-div" label='Minimum Value' className='sysField'>
                <Form.Control id="min" type="number" value={min} onChange={(e)=>setMin(e.target.value)}/>
            </FloatingLabel>,
        max: 
            <FloatingLabel id="max-div" label='Maximum Value' className='sysField'>
                <Form.Control id="max" type="number" value={max} onChange={(e)=>setMax(e.target.value)}/>
            </FloatingLabel>    
    };
    const forms = {
        "between": 
            <div id="between-div">
                {formFields.min}
                {formFields.max}
            </div>,
        "greater": formFields.min,
        "less": formFields.max    
    };
    const compLabels = {
        "sum": "sum of",
        "diff" : "difference between"
    };

    // run when sys context changes - set sport
    useEffect(()=>{
        setSport(props.sysContext.sport);
    },[props.sysContext]);

    // run when init crit changes - set fields if crit was passed to component
    useEffect(()=> {
        // check if user is editing an existing criteria
        if(props.initCrit){
            setCritIdx(props.initCrit.index);
            const critCopy = {...props.initCrit.crit};
            setRangeType(critCopy.rangeType);
            setStatObj(critCopy.statObj);
            setInitCrit(critCopy);
            if(critCopy.type === "stat.avg-comp"){
                setStatObj2(critCopy.statObj2);
                setCompType(critCopy.compType);
            }; 
            switch(critCopy.rangeType){
                case "between":
                    setMin(critCopy.min);
                    setMax(critCopy.max);
                    break;
                case "greater":
                    setMin(critCopy.min);
                    break;
                case "less":
                    setMax(critCopy.max);
                    break;
                default: 
                    break;
            };
        };
    },[props.initCrit]);
    
    // function to check if init criteria has changed
    const checkCritChange = () => {
        // function to check a stat object for changes against the initial
        const isStatObjModified = (initObj, obj) => {
            if(initObj.type !== obj.type || initObj.team !== obj.team || initObj.team !== obj.team || initObj.label != obj.label){
                return true;
            }else return false; 
        };
        // if criteria index - check for changes, otherwise return true
        if(critIdx  !== null){
            // check stat objects for changes
            const statObjModified = isStatObjModified(initCrit.statObj, statObj);
            const statObj2Modified = props.type === "avg-comp" ? isStatObjModified(initCrit.statObj2, statObj2) : null;
            const compTypeChanged = (props.type === "avg-comp") && (initCrit.compType !== compType) ? true : false; 
            if(statObjModified || statObj2Modified || rangeType !== initCrit.rangeType || compTypeChanged){
                return true;
            };
            // check min or max values for changes
            switch(rangeType){
                case "between":
                    if(min !== initCrit.min || max !== initCrit.max){
                        return true;
                    };
                    break;
                case "greater":
                    if(min !== initCrit.min){
                        return true;
                    };
                    break;
                case "less":
                    if(max !== initCrit.max){
                        return true;
                    };
                    break;
            };
        }else{
            return true;
        };
    };

    // function to check for errors
    const checkErrs = () => {
        let tmpErrs = [];
        let tmpErrMsg = null;

        const checkStatObjErrs = (statNum, obj) => {
            // team validation 
            if((obj.team !== "home" && obj.team !== "away") || !(obj.type in stats[sport]) || !(obj.stat in stats[sport][obj.type]) || obj.label === ""){
                tmpErrs.push(`stat${statNum}-div`);
            };
        };

        // check for errs in stat object (always)
        checkStatObjErrs(1, statObj);

        // if comparing two stats - check second stat object and comparison type 
        if(props.type === "avg-comp"){ 
            checkStatObjErrs(2, statObj2);
            if(compType !== "sum" && compType !== "diff") tmpErrs.push("compType");
        };
        
        // Range type & min, max validation
        switch(rangeType){
            case "between":
                if(isNaN(min) || min === ""){
                    tmpErrs.push("min");
                };
                if(isNaN(max) || max === ""){
                    tmpErrs.push("max");
                };
                if(!isNaN(min) && min !== "" && !isNaN(max) && max !== ""){
                    if(min >= max){
                        tmpErrs.push("min");
                        tmpErrs.push("max");
                        tmpErrMsg = "Please ensure the minimum odds value is less than the maximum";
                    };
                };
                break;
            case "greater":
                if(isNaN(min) || min === null){
                    tmpErrs.push("min");
                };
                break;
            case "less":
                if(isNaN(max) || max === null){
                    tmpErrs.push("max");
                };
                break;
            default:
                tmpErrs.push("rangeType");
                break;
        };

        return({errs: tmpErrs, msg: tmpErrMsg});
    };

    const getLabel = () => {
        let label = "";
        if(props.type === "avg"){
            const statLbl = statObj.label[0].toUpperCase() + statObj.label.slice(1);
            const statVerb = statVerbs[sport][statObj.type][statObj.stat];            
            label = `${statLbl} ${statVerb} ${rangeType} `;
        }else if(props.type === "avg-comp"){
            label = `${compLabels[compType]} ${statObj.label} and ${statObj2.label} is ${rangeType} `;
        };
        switch(rangeType){
            case "between":
                label = label + `${min} and ${max}`
                break;
            case "greater":
                label = `${label}than ${min}`
                break;
            case "less":
                label = `${label}than ${max}`
                break;
        };
        return label;
    };

    // function to get criteria for submission 
    const getCriteria = () => {
        const label = getLabel();
        let crit = {
            type: `stat.${props.type}`,
            rangeType: rangeType,
            statObj: statObj,
            label: label
        };
        if(props.type === "avg-comp"){
            crit.statObj2 = statObj2;
            crit.compType = compType;
        };
        switch(rangeType){
            case "between":
                crit.min = parseFloat(min);
                crit.max = parseFloat(max);
                break;
            case "greater":
                crit.min = parseFloat(min);
                break;
            case "less":
                crit.max = parseFloat(max);
                break;
        };

        return crit;
    };

    // set submit function in parent component
    const validateSubmit = (e) => {
        e.preventDefault();
        // check for errors
        const errCheck = checkErrs(); 
        setErrMsg(errCheck.msg);
        setErrs(errCheck.errs);
        if(errCheck.errs.length === 0){
            // get criteria
            const crit = getCriteria();
            // check for crit change - returns true if initCrit was modified or not passed
            const modified = checkCritChange();
            if(modified){
                props.sysContext.changeCrit(crit, critIdx);
            };
            props.sysContext.setCritDisp(null);
            props.sysContext.setStep(2);
        };
    };

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            const elem = document.getElementById(err);
            if(elem){
                elem.classList.add("err");
            };
        });
    },[errs]);

    const dispCrit = () => {
        setStatDisp(null);
    };

    return (
        <>
            {statDisp === null ? (
                <>
                    <div id="stat-avg-crit">
                        <div id='header-row'>
                            <h2>Stat Average Criteria</h2>
                        </div>
                        {(props.type === "avg-comp" && sport !== "") && (
                            <>
                                <FloatingLabel label='Choose Stat Comparison' id="comp-div" className='sysField'>
                                    <Form.Control as="select" value={compType} onChange={e=>setCompType(e.target.value)}>
                                        <option hidden value=""></option>
                                        <option value="sum">Combine two stats</option>
                                        <option value="diff">Compare two stats</option>
                                    </Form.Control>
                                </FloatingLabel>
                                <div id="stats-comp">
                                    <div id="stat1-div" className='border-label statField'>
                                        <p className='label-content'>First Stat</p>
                                        {statObj.label ===  "" ? (
                                            <PlusIcon className="add-stat" onClick={()=>setStatDisp(1)} />
                                        )
                                            : 
                                        (
                                            <div className="stat-content">
                                                <p className='stat-lbl'>{statObj.label}</p>
                                                <img className="editIcon" src={write} width="15" height="auto" onClick={()=>setStatDisp(1)}/>
                                            </div>
                                        )}
                                    </div>
                                    <div id="stat2-div" className='border-label statField'>
                                        <p className='label-content'>Second Stat</p>
                                        {statObj2.label ===  "" ? (
                                            <PlusIcon className="add-stat" onClick={()=>setStatDisp(2)} />
                                        )
                                            : 
                                        (
                                            <div className="stat-content">
                                                <p className='stat-lbl'>{statObj2.label}</p>
                                                <img className="editIcon" src={write} width="15" height="auto" onClick={()=>setStatDisp(2)}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {(props.type === "avg" && sport !== "") && (
                            <div id="stat1-div" className='border-label statField'>
                                <p className='label-content'>Stat</p>
                                {statObj.label ===  "" ? (
                                    <PlusIcon className="add-stat" onClick={()=>setStatDisp(1)} />
                                )
                                    : 
                                (
                                    <div className="stat-content">
                                        <p className='stat-lbl'>{statObj.label}</p>
                                        <img className="editIcon" src={write} width="15" height="auto" onClick={()=>setStatDisp(1)}/>
                                    </div>
                                )}
                            </div>                    )}
                        <FloatingLabel label='Choose Filter' id="filter-div" className='sysField'>
                            <Form.Control id="rangeType" as="select" value={rangeType} onChange={(e)=>setRangeType(e.target.value)}>
                                <option hidden value=""></option>
                                <option value="between">between</option>
                                <option value="greater">greater than</option>
                                <option value="less">less than</option>
                            </Form.Control>
                        </FloatingLabel>
                        {rangeType !== "" && (
                            forms[rangeType]
                        )}
                        {errMsg && (<p className='errMsg'>{errMsg}</p>)}
                    </div>
                    <div id='submit-btns'>
                        <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>props.cancel()}>Cancel</button>
                        <button className="primaryBtn" id="saveCritBtn" onClick={(e)=>validateSubmit(e)}>Save Criteria</button>
                    </div>
                </>
            )
            :
            (
                <>
                    {statDisp === 1 && (
                        <StatComponent sport={sport} statObj={statObj} setStatObj={setStatObj} stats={stats[sport]} dispCrit={dispCrit}/>
                    )}
                    {statDisp === 2 && (
                        <StatComponent sport={sport} statObj={statObj2} setStatObj={setStatObj2} stats={stats[sport]} dispCrit={dispCrit}/>
                    )}
                </>
            )}
        </>                               
    )
}

export default StatAverages;