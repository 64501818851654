import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import SystemDisp from './SystemDisp';
import calcProfitAm from '../../../utils/systems/calcProfit';
import { follow_system, formatSystem } from '../../../utils/systems/systemUtils';
import { setFollowedSystems, setPrivateSystems } from '../../../redux/userSlice';
import apiService from '../../../utils/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { useClearUserSession } from '../../../hooks/user-hooks';
import { useNavigate } from "react-router-dom";
import AuthProtected from '../../HOCs/AuthProtected';

const System = () => {
    const dispatch = useDispatch();
    const clearSession = useClearUserSession();
    const navigate = useNavigate();
    const location = useLocation();
    const followedSystems = useSelector(state => state.user.followedSystems);
    const privSystems = useSelector(state => state.user.privateSystems);
    const [system, setSystem] = useState(() => {
        return location.state ? location.state.system : null;
    });
    const [sysType, setSysType] = useState(() => {
        return location.state ? location.state.sysType : null;
    });
    const editPath = "/system-builder";
    const [sznWL, setSznWL] = useState([]);
    const [profit, setProfit] = useState(0);
    const [pubRes, setPubRes] = useState("");
    const [modalStep, setModalStep] = useState('submission');

    // run on mount - if location is not set navigate to systems page
    useEffect(() => {
        if (!location.state) {
          navigate('/systems', { replace: true });
        }
    }, [location, navigate]);
    
    // run when system is set, get sznWL data for charts, get edit path 
    useEffect(()=>{
        if(system && Object.keys(system).length > 0){        
            // hold win percentage data yr over yr
            let seasons = Object.keys(system.matches);
            let units = 1, total_prof = 0;
            // tmp sznWL arr
            let tmpSznWL = [];
            for(const szn of seasons){
                let games = system.matches[szn];
                // season win loss and push count
                let sznW = 0, sznL = 0, sznP = 0, sznProf = 0;
                if(games.length > 0){
                    for(const game of games){
                        let betProf;
                        if(game.bet.result === "W"){
                            sznW = sznW+1;
                            betProf = calcProfitAm(game.bet.odds, units);
                            sznProf = sznProf + betProf;
                            total_prof = total_prof + betProf;
                        }else if(game.bet.result === "L"){
                            sznL = sznL+1;
                            sznProf = sznProf - units;
                            total_prof = total_prof - units;
                        }else if(game.bet.result === "P"){
                            sznP = sznP+1;
                        };
                    };
                };
                tmpSznWL.push({season: szn, w: sznW, l:sznL, p:sznP, prof:sznProf.toFixed(2)});
            };
            setSznWL(tmpSznWL);
            setProfit(total_prof.toFixed(2));
        }
    },[system]);

    // function to add system to followed systems
    const updateFollowedSystems = (system) => {
        // format system and update followed systems
        const fmtSys = formatSystem(system);
        let tmpFollowedSystems = {...followedSystems}; 
        tmpFollowedSystems[system.sport] = [...tmpFollowedSystems[system.sport], fmtSys]; 
        dispatch(setFollowedSystems(tmpFollowedSystems));
    };

    // function to handle system being followed 
    const handleSystemFollow = async() =>{
        const response = await follow_system(system._id, system.sport, clearSession, navigate);
        // if follow is successful, change sysType to followed - then update users followed systems
        if(response.acknowledged){
            setSysType("followed");
            follow_system(system);
            updateFollowedSystems(system);
        };
    };

    // function to delete system from database and any unsettled matches 
    const deleteSystem = async() =>{
        try{
            const endpoint = `systems/delete?id=${system._id}`;
            const response = await apiService(endpoint, 'GET');
            // if deleted - remove system from private systems and navigate to systems page
            if(response === "System deleted successfully"){
                // remove system from private systems 
                let tmpPrivSystems = [...privSystems];
                tmpPrivSystems = tmpPrivSystems.filter(sys => sys._id !== system._id);
                dispatch(setPrivateSystems(tmpPrivSystems));
                navigate('/systems');
            };
        }catch(error){
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };
    
    // Function to handle function publishing
    const publishSystem = async() => {
        try{
            let endpoint = `systems/publish-system?id=${system._id}`;
            const response = await apiService(endpoint, 'GET');
            setPubRes(response);
            setModalStep('response');
            setSysType("followed")
            // if successful - move system into followed systems and remove from user systems 
            if(response === "System successfully published"){
                // remove system from private systems 
                let tmpPrivSystems = [...privSystems];
                tmpPrivSystems = tmpPrivSystems.filter(sys => sys._id !== system._id);
                dispatch(setPrivateSystems(tmpPrivSystems));
                // add system to followedSystems 
                updateFollowedSystems(system);
            };
        }catch(error){
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };

    // function to edit system
    const editSystem = () => {
        navigate(editPath, {state:{system:system, pageType:"edit", sznWL:sznWL}});
    };

    if(system != {}){
        return(
            <div className='full-page'>
                <div className='page-section'>
                    <SystemDisp system={system} 
                        sznWL={sznWL} 
                        pageType="existing" 
                        sysType={sysType} 
                        publishSystem={publishSystem}
                        deleteSystem={deleteSystem}
                        handleSystemFollow={handleSystemFollow}
                        editSystem={editSystem}
                        modalStep={modalStep}
                        setModalStep={setModalStep}
                        pubRes={pubRes}
                    />
                </div>
            </div>
        )
    }else{
        return(<></>)
    }

}

export default AuthProtected(System);