import { useState } from 'react';
import ResetPasswordForm from '../forms/ResetPassword';
import ConfirmPassForm from '../forms/ConfirmPass';
import styles from '../../scss/pages/loginSignup.module.scss';
import { useNavigate } from 'react-router-dom';

const ResetPassPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [err, setErr] = useState('');
  const [code, setCode] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confPass, setConfPass] = useState('');

  const handleSendCode = async() => {
    // body of post request
    const body = {
      email: email
    };

    //api parameters to get sys matches
    const url = 'https://api.lineleaders.net/accounts/resetpassword';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    };

    let resetPassProm = await fetch(url, options);
    let result = await resetPassProm.json();  
    // handle response 
    if(result.ok === true){
      setStep(2);
    }else if(result.ok === false){
      if(result.err.code === "LimitExceededException"){
        setErr('You have attemped to reset your password too many times, please wait and try again later.')
      }else if(result.err.code === "InternalErrorException"){
        setErr("Something went wrong on our end. Please try again later.")
      }else{
        console.log(result.err);
        setErr("Something went wrong, please try again in a few minutes");
      }
      
    };
  };

  const handleConfPass = async() => {
    // body of post request
    const body = {
      email: email, 
      pass: newPass,
      code:code
    };

    //api parameters to get sys matches
    const url = 'https://api.lineleaders.net/accounts/confirmpass';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body)
    };

    let confirmPassProm = await fetch(url, options);
    let result = await confirmPassProm.json();  
    // handle response 
    if(result.ok === true){
      setStep(3);
    }else if(result.ok === false){
      if(result.err.code === "CodeMismatchException" || result.err.code === "ExpiredCodeException"){
        setErr('The reset code you entered is incorrect or has already been used. Please request a new one if needed.')
      }else if(result.err.code === "InternalErrorException"){
        setErr("Something went wrong on our end. Please try again later.")
      }else if(result.err.code === "InvalidPasswordException" || result.err.code === "UserNotFoundException"){
        setErr("We were unable to reset your password. Please ensure you've entered the correct email address and that the new password meets our criteria. If you continue to experience issues, please contact our support team.");
      }else{
        console.log(result.err);
        setErr("Something went wrong, please try again in a few minutes");
      };
    };
  };

  return (
    <div className='full-page'>
      <div className='page-section'>
      {step === 1 && (
        <div className={styles.mainView}>
          <div className={styles.title}>
            <h2>Reset Your Password</h2>
            <p>We will send you an email containing a code to complete this process and reset your password.</p>
          </div>
          <ResetPasswordForm className={styles.formFields} err={err} navigate={navigate} email={email} setEmail={setEmail} setStep={setStep} handleSubmit={handleSendCode}/>
        </div>
      )}
      {step === 2 && (
        <div className={styles.mainView}>
          <div className={styles.title}>
            <h2>Change Your Password</h2>
            <p>If there is an account under the provided email, you should receive a code to reset your password.</p>
          </div>
          <ConfirmPassForm 
            className={styles.formFields} navigate={navigate} email={email} err={err} 
            setStep={setStep} handleSubmit={handleConfPass}
            code={code} setCode={setCode}
            newPass ={newPass} setNewPass={setNewPass}
            confPass={confPass} setConfPass={setConfPass}
          />
        </div>
      )}
      {step === 3 && (
        <div className={styles.mainView}>
          <div className={styles.title}>
            <h2>Password Reset</h2>
            <p>Your password has been reset.</p>
            <a className={`${styles.pageLink} ${styles.footer}`} onClick={()=>navigate('/signin')}>Sign In</a>
          </div>
        </div>
      )}
      </div>

    </div>
  );
};

export default ResetPassPage;