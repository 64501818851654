import React from 'react'
import { Card } from 'react-bootstrap';
import account from '../../images/icons/account.svg';
import Notifications from '../profile/Notifications';

const Settings = () => {
    return (
        <div className='full-page'>
            <div className='page-section'>
                <Notifications />
            </div>
        </div>
    )
};

export default Settings