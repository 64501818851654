import React, { useState, useEffect, createContext  } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SystemInfo from './SystemInfo';
import SystemCriteria from './criteria/SystemCriteria';
import CriteriaDisplay from './criteria/CriteriaDisplay';
import CriteriaSelection from './criteria/CriteriaSelection';
import BacktestDisp from './BacktestDisplay';
import '../../scss/pages/systemBuilder.scss';

const SystemContext = createContext();

const SystemBuilder = (props) => {
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [canClearFields, setCanClearFields] = useState(false);
    const [id, setId] = useState("");
    const [step, setStep] = useState(null);
    const [name, setName] = useState("");
    const [sport, setSport] = useState("");
    const [bet, setBet] = useState("");
    const [criteria, setCriteria] = useState([]);
    const [units, setUnits] = useState(1);
    const [critDisp, setCritDisp] = useState(null);
    const [sysType, setSysType] = useState('private');
    const [matches, setSysMatches] = useState({});
    const [upcomingMatches, setUpcomingMatches] = useState([]);
    const [backtested, setBacktested] = useState({
        tested: false, 
        modified: false
    });
    const [roi, setRoi] = useState(0.00);
    const [w, setW] = useState(0);
    const [l, setL] = useState(0);
    const [p, setP] = useState(0);
    const [sznWL, setSznWL] = useState([]);
    const location = useLocation();
    const pageType = location.state?.pageType || "new";
    const navigate = useNavigate();

    // Load initial data
    useEffect(() => {
        if (pageType === "edit" || pageType === "view") {
            let system = location.state.system;
            setName(system.name);
            setCriteria(system.criteria);
            setSport(system.sport);
            setBet(system.bet);
            setId(system._id);
            setW(system.w);
            setL(system.l);
            setP(system.p);
            setRoi(system.roi);
            setSysMatches(system.matches);
            setSznWL(location.state.sznWL);
        };
        setStep(1);
        setInitialDataLoaded(true); // Indicate that initial data has been loaded
    }, [pageType, location.state]);

    // Enable field clearing after initial data load
    useEffect(() => {
        if (initialDataLoaded) {
            const timer = setTimeout(() => {
                setCanClearFields(true);
                if(pageType === "edit"){
                    setBacktested({tested: true, modified: false});
                }
            }, 1000); // 1-second delay before allowing clearing
            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [initialDataLoaded]);

    // Clear fields after initial data load and when sport changes
    useEffect(() => {
        if (canClearFields) {
            setCriteria([]);
            setBet("");
        };
    }, [sport]);

    // run when any system variable changes that affects system backtesting, check if needs backtesting
    useEffect(()=>{
        if(backtested.tested === true){
            let tmpObj = {
                ...backtested, 
                modified: true
            };
            setBacktested(tmpObj);
        };
    },[sport, bet, criteria]);

    // function to validate system info
    const validateSystemInfo = ()=>{
        let errs = [];
        // needs a name, sport, and bet selection
        if(name.length < 1 ){
            errs.push("name");
        };
        if(sport.length < 1){
            errs.push("sport");
        };
        if(bet.length < 1){
            errs.push("bet");
        };
        return errs;    
    };

    const displayCrit = (type, crit=null, index=null) => {
        let tmpCrit = null;
        if(crit !== null && index !== null){
            tmpCrit = {
                crit: crit, 
                index: index
            };
        };

        setCritDisp({filter: type, crit: tmpCrit});
        setStep(4);
    };

    // Function to add or update a criteria
    const changeCrit = (critVal, index=null) => {
        let tmpCrit = [...criteria];
        if(index != null){
            tmpCrit[index] = critVal;
        }else{
            tmpCrit.push(critVal);
        };
        setCriteria(tmpCrit);
    };

    // function to remove criteria 
    const removeCrit = (i) =>{
        let tmpCrit = [...criteria];
        tmpCrit.splice(i, 1);
        setCriteria(tmpCrit);
    };

    const navToSystems = () => {
        navigate('/systems');
    };

    return (
        <SystemContext.Provider value={{setStep, changeCrit, removeCrit, displayCrit, setCritDisp, id, name, sport, bet, criteria, units, sysType, matches, upcomingMatches}} >
            <div className='page-section'>
                <div id='system-builder'>
                    {step === 1 && (
                        <SystemInfo
                            navToSystems={navToSystems}
                            setName={setName}
                            setSport={setSport}
                            setBet={setBet}
                            setSysType={setSysType}
                            validateSystemInfo={validateSystemInfo}
                            pageType={pageType}
                        />
                    )}
                    {step === 2 && (
                        <SystemCriteria />
                    )}
                    {step === 3 && (
                        <CriteriaSelection />
                    )}
                    {step === 4 && (
                        <CriteriaDisplay critDisp={critDisp} />
                    )}
                </div>
                {step === 5 && (
                        <BacktestDisp 
                            pageType={pageType}
                            setBacktested={setBacktested} 
                            backtested={backtested} 
                            setSysMatches={setSysMatches} 
                            setUpcomingMatches={setUpcomingMatches}
                            setRoi={setRoi} setW={setW} setL={setL} setP={setP}
                            roi={roi} w={w} l={l} p={p}
                            sznWL={sznWL} setSznWL={setSznWL}
                        />
                )}
            </div>
        </SystemContext.Provider>
    )
}

export default SystemBuilder;
export { SystemContext };