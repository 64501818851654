import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

const SystemBets = (props) => {
    const [bets, setBets] = useState([]);

    // get list of bets for table
    useEffect(()=>{
        if(props.matches !== null && props.matches !== undefined){
            let tmpBets = [];
            // loop through each season
            const seasons = Object.keys(props.matches);
            for(const season of seasons){
                const sznBets = props.matches[season];
                tmpBets = tmpBets.concat(sznBets);
            };
            // Sort tmpBets by date in descending order
            tmpBets.sort((a, b) => {
                // Compare date strings directly; newer dates will be "greater" as strings
                return b.date.localeCompare(a.date);
            });
            setBets(tmpBets);
        };
    }, [props.matches]);

    return (
        <Table id="resultTbl">
            <thead>
                <tr>
                    <th className='gameCol'>Game</th>
                    <th className='betCol'>Bet</th>
                    <th className='oddsCol'>Odds</th>
                    <th className='resCol'>Result</th>
                </tr>
            </thead>
            <tbody>
                {bets.length > 0 ? 
                (
                    bets.map((rec, i) => {
                        return(
                            <tr key={i}>
                                <td className='gameCol'>
                                    <span className='topText'>{rec.date}</span>
                                    <span className='bottomText'>{rec.game}</span>
                                </td>
                                <td className='betCol'>{rec.bet.bet}</td>
                                <td className='oddsCol'>{rec.bet.odds > 0 ? "+" : null}{rec.bet.odds}</td>
                                <td className={`bet-${rec.bet.result} resCol`}>{rec.bet.result}</td>
                            </tr>
                        )
                    })
                ): (<>No bets found</>)
                }
            </tbody>
        </Table>
    )
}

export default SystemBets