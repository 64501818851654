import React, {useState} from 'react'
import styles from '../../scss/pages/tos.module.scss';

const FAQ = () => {
    return (
        <div className='full-page'>
            <div className={'page-section'}>
                <div className={styles.tos} >
                    <div className={styles.header}>
                        <h1>Frequently asked questions</h1>
                    </div>
                    <div className={styles.content}> 
                        <ol>
                            <li>
                                <h5>What is this platform?</h5>
                                <p>Our platform enables users to create, test, and refine their own sports betting systems using over 5 years of historical data. Users can select custom criteria based on home and away team statistics to develop strategies that can be backtested to evaluate performance over time. We also monitor upcoming games to check for system matches. Not wanting to create your own systems? You can follow community published ones.</p>
                            </li>
                            <li>
                                <h5>How do I create a betting system?</h5>
                                <p>To create a betting system, navigate to the "Systems" page on our platform. Here, you can define your system by creating your own custom criteria, using stats for home and away teams. Once your criteria are set, you just select a bet and name your system then backtest it. Your systems can be kept private, or published to the community for others to follow. If you are unhappy with the results, edit your system criteria to refine it and when you are ready just click save!</p>
                            </li>
                            <li>
                                <h5>What kind of data is available for backtesting?</h5>
                                <p>Our database currently includes over 5 years of historical data covering MLB and NFL. This includes team performance statistics, game outcomes, betting odds, and more, allowing for comprehensive analysis and testing of your betting systems.</p>
                            </li>
                            <li>
                                <h5>How does the backtesting process work?</h5>
                                <p>Once you have created a betting system, you can backtest it by clicking the "Backtest" button. The platform will then simulate how your system would have performed historically. Every game is checked for a match, and the stats selected in your criterial are the teams average at that point in the season. Results include potential profits, win/loss ratios, and other key performance indicators.</p>
                            </li>
                            <li>
                                <h5>Can I adjust my system after backtesting?</h5>
                                <p>Absolutely. We encourage users to refine their systems based on backtesting results. You can adjust your criteria, and test different bets. Continuous refinement is key to developing a successful betting strategy.</p>
                            </li>
                            <li>
                                <h5>Is there a limit to how many systems I can create or follow?</h5>
                                <p>Currently, there is no limit to the number of systems you can create and backtest, or public systems that you follow, on our platform. We believe in providing our users with the flexibility to explore and test as many strategies as they wish.</p>
                            </li>
                            <li>
                                <h5>Is there a cost to use this platform?</h5>
                                <p>Currently, our platform is completely FREE.</p>
                            </li>
                            <li>
                                <h5>Where can I get help if I have more questions, or have new feature ideas?</h5>
                                <p>If you have further questions or want to recommend changes to the platform, please join our discord or direct message us on Twitter. We're here to help you make the most of our platform and ensure your betting strategies are as successful as possible.</p>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ