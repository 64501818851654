import React, {useEffect, useState} from 'react';

import BuildSystem from './Systems/BuildSystem';
const MlbBeta = () => {

    return (
        <BuildSystem />
    )

}

export default MlbBeta