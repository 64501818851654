import React, {useState, useEffect} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import calcProfitAm from '../../../utils/systems/calcProfit';
import {useNavigate} from 'react-router-dom';
import { setFollowedSystems, setPrivateSystems, setMatches } from '../../../redux/userSlice';
import SystemDisp from '../../pages/System/SystemDisp';
import apiService from '../../../utils/apiService';
import { useClearUserSession } from '../../../hooks/user-hooks';
import { formatSystem } from '../../../utils/systems/systemUtils';

const BacktestDisp = (props) => {
    const clearSession = useClearUserSession();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_id = useSelector((state) => state.user.id);
    const privateSystems = useSelector(state => state.user.privateSystems);
    const followedSystems = useSelector(state => state.user.followedSystems);
    const allSysMatches = useSelector(state => state.user.matches);
    const [sznWL, setSznWL] = useState([]);

    // run on render to calc sznWL
    useEffect(()=>{
        // total win loss and push count
        let total_w = 0, total_l = 0, total_p = 0, total_prof = 0;
        let seasons = Object.keys(props.system.matches);
        // tmp sznWL arr
        let tmpSznWL = [];
        for(const szn of seasons){
            let games = props.system.matches[szn];
            // season win loss and push count
            let sznW = 0, sznL = 0, sznP = 0, sznProf = 0;
            if(games.length > 0){
                for(const game of games){
                    let betProf;
                    if(game.bet.result === "W"){
                        total_w = total_w+1;
                        sznW = sznW+1;
                        betProf = calcProfitAm(game.bet.odds, props.system.units);
                        sznProf = sznProf + betProf;
                        total_prof = total_prof + betProf;
                    }else if(game.bet.result === "L"){
                        sznL = sznL+1;
                        total_l = total_l+1;
                        sznProf = sznProf - props.system.units;
                        total_prof = total_prof - props.system.units;
                    }else if(game.bet.result === "P"){
                        sznP = sznP+1;
                        total_p = total_p+1;
                    };
                };
            };
            tmpSznWL.push({season: szn, w: sznW, l:sznL, p:sznP, prof:sznProf.toFixed(2)});
        };
        setSznWL(tmpSznWL);
        let tmpRoi = (total_prof/(total_w+total_l)*props.system.units)*100;
        props.system.roi = parseFloat(tmpRoi.toFixed(2));
        props.system.w = total_w;
        props.system.l = total_l;
        props.system.p = total_p;
    },[]);

    
    //Function to create system record in database
    async function createSystem() {
        // body of post request
        const body = {
            system:{
                sport: 'nfl',
                name: props.system.name,
                criteria: props.system.criteria,
                bet: props.system.bet,
                matches: props.system.matches,
                user_id: user_id
            },
            upcomingMatches: props.upcomingMatches,
            type: props.system.sysType
        };
        try{
            // call api
            const response = await apiService('systems/create', 'POST', body);
            if(response.hasOwnProperty('system_id')){

                let tmpSys = formatSystem({
                    sport: 'nfl',
                    name: props.system.name,
                    criteria: props.system.criteria,
                    bet: props.system.bet,
                    matches: props.system.matches,
                    user_id: user_id,
                    _id: response.system_id
                });
                if(props.system.sysType === "private"){
                    let tmpSystems = [...privateSystems];
                    tmpSystems.push(tmpSys);
                    dispatch(setPrivateSystems(tmpSystems));
                }else if(props.system.sysType == "public"){
                    let tmpSystems = {
                        ...followedSystems,
                        [tmpSys.sport]: [...(followedSystems[tmpSys.sport] || []), tmpSys]
                    };
                    dispatch(setFollowedSystems(tmpSystems));
                };
                // add sys matches 
                if(response.matches.length > 0){
                    let tmpMatches = [...allSysMatches];
                    tmpMatches = tmpMatches.concat(response.matches);
                    dispatch(setMatches(tmpMatches));
                };
                // navigate back to systems page
                navigate('/systems');
            }else{
                console.log("resource not created");
            };
        }catch(error){
            console.log(error)
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };

    //Function to update system record in database
    async function updateSystem() {
        // body of post request
        const body = {
            sys: {
                sport: 'nfl',
                name: props.system.name,
                criteria: props.system.criteria,
                bet: props.system.bet,
                matches: props.system.matches,
                user_id: user_id
            },
            id: props.id,
            upcomingMatches: props.upcomingMatches,
            type: 'nfl'
        };
        try{
            // call api
            const response = await apiService('systems/update', 'POST', body)
            // if record updated in database, update redux store
            if(response.updated){
                let tmpSystems = [...privateSystems];
                // find index of system
                const i = tmpSystems.findIndex(sys => sys._id === props.id);
                // if index found, format system and update redux store
                if(i !== -1){
                    let tmpSys = formatSystem({
                        sport: 'nfl',
                        name: props.system.name,
                        criteria: props.system.criteria,
                        bet: props.system.bet,
                        matches: props.system.matches,
                        user_id: user_id,
                        _id: props.id,
                    });
                    tmpSystems[i] = tmpSys;
                    dispatch(setPrivateSystems(tmpSystems));
                };
                // remove system matches from redux store and add new ones 
                let tmpMatches = [...allSysMatches];
                tmpMatches = tmpMatches.filter(match => match.system_id !== props.id);
                if(response.matches.length > 0){
                    tmpMatches = tmpMatches.concat(response.matches);
                };
                dispatch(setMatches(tmpMatches));
                // navigate back to systems page 
                navigate('/systems');
            }else{
                console.log('not modified')
            };
        }catch(error){
            if(error === 'Server Error' || error === 'Session Expired'){
                clearSession();
                navigate('/signin');
            };
        };
    };

    // function to handle submit
    const submit = () =>{
        // either create system or update depending on type
        if(props.pageType === "edit"){
            updateSystem();
        }else createSystem();
    };

    return(
        <div>
            <SystemDisp system={props.system} sznWL={sznWL} pageType="new"/>
            <Row className="form-row">
                <Col className='right-align'>
                    <Button className='secondaryBtn' type="submit" onClick={()=>props.dispSys()} >Edit</Button>
                </Col>
                <Col className='left-align'>
                    <Button className='primaryBtn' type="submit" onClick={()=>submit()} >Save System</Button>

                </Col>
            </Row>
        </div>
    );
};
export default BacktestDisp