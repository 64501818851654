import React, {useEffect, useState} from 'react'
// components
import WLChart from '../../Systems/WinLossChart';
import WinPerChart from '../../Systems/WinPerChart';
import ProfitChart from '../../Systems/ProfitChart';
import {ReactComponent as Ellipsis} from '../../../images/icons/ellipsis.svg';
import { Carousel, Modal, Tabs, Tab } from 'react-bootstrap';
import '../../../scss/pages/system.scss';
import SystemBets from './SystemBets';
// images
import share from '../../../images/icons/share.svg';
import follow from '../../../images/icons/follow.svg';
import following from '../../../images/icons/following.svg';
import edit from '../../../images/icons/edit.svg';
import greenCaret from '../../../images/icons/caret-green.svg';
import deleteIcon from '../../../images/icons/delete.svg';
import redCaret from '../../../images/icons/caret-red.svg';


const SystemDisp = (props) => {
    const [system, setSystem] = useState({});
    const sznWL = props.sznWL;
    const pageType = props.pageType;
    const [showEllipseDrop, setShowEllipseDrop] = useState(false);
    const [showPubModal, setShowPubModal] = useState(false);
    const [showDelModal, setShowDelModal] = useState(false);
    const [key, setKey] = useState("system");
    useEffect(()=>{
        setSystem(props.system);
    },[props]);

    // run whenever system is set, add red / green classes to Win / ROI 
    useEffect(()=>{
        // get elements 
        let winDiv = document.getElementById(`win-div`);
        let roiDiv = document.getElementById(`roi-div`);
        let winCaret = document.getElementById(`win-caret`);
        let roiCaret = document.getElementById(`roi-caret`);

        // if Win is under 55%, color red, otherwise green
        if(system.roi !== "NaN"){
            if((system.w/(system.w+system.l))*100 > 55){
                winDiv.classList.add("green");
                winDiv.classList.remove("red");
                winCaret !== null && winCaret.classList.add("up-caret");
            }else{
                winDiv.classList.add("red");
                winDiv.classList.remove("green");
                winCaret !== null && winCaret.classList.add("down-caret");
            };

            // if ROI is + color green, otherwise red
            if(system.roi > 0){
                roiDiv.classList.add("green");
                roiDiv.classList.remove("red");
                roiCaret !== null && roiCaret.classList.add("up-caret");
            }else{
                roiDiv.classList.add("red");
                roiDiv.classList.remove("green");
                roiCaret !== null && roiCaret.classList.add("down-caret");
            };
        };
    },[system]);

    // open menu on click of ellipsis
    const handleEllipseClick = () => {
        setShowEllipseDrop(!showEllipseDrop);
    };

    // Show publish system modal
    const handlePublishClick = () => {
        setShowPubModal(!showPubModal);
    };

    // function for publish completion and modal closure
    const closePublishedModal = () =>{
        props.setModalStep('submission');
        setShowPubModal(false);
    };

    // function to show delete system modal 
    const handleDeleteClick = () => {
        setShowDelModal(!showDelModal);
    };

    // function to handle edit click 
    const handleEditClick = () => {
        props.editSystem();
    };

    let pubModalContent;
    if(props.modalStep === 'submission') {
        pubModalContent = (
            <>
                <Modal.Body>Are you sure you would like to publish this system? Once public you will not be able to modify or delete it.</Modal.Body>
                <Modal.Footer>
                    <button className='secondaryBtn' onClick={()=>setShowPubModal(false)}>Cancel</button>
                    <button className='primaryBtn' onClick={()=>props.publishSystem()}>Pubish</button>
                </Modal.Footer>
            </>
        );
    } else if(props.modalStep === 'response') {
        pubModalContent = (
            <>
                <Modal.Body>{props.pubRes}</Modal.Body>
                <Modal.Footer>
                    <button className='primaryBtn' onClick={()=>closePublishedModal()}>Close</button>
                </Modal.Footer>
            </>
        );
    };
    
    let pubModal = <Modal show={showPubModal} onHide={()=>setShowPubModal(false)}>
        <Modal.Header>Publish System</Modal.Header>
        {pubModalContent}
    </Modal>

    let delModal = <Modal show={showDelModal} onHide={()=> setShowDelModal(false)}>
        <Modal.Header>Delete System</Modal.Header>
        <Modal.Body>Are you sure you want to delete this system?</Modal.Body>
        <Modal.Footer>
                <button className='secondaryBtn' onClick={()=>setShowDelModal(false)}>Cancel</button>
                <button className='primaryBtn' onClick={()=>props.deleteSystem()}>Delete</button>
        </Modal.Footer>
    </Modal>

    return (
        <div id="componentBody">
            {showPubModal && (pubModal)}
            {showDelModal && (delModal)}
            <div id="header">
                <h4>{system.name} - {system.sport}</h4>
                {(pageType==="existing" && props.sysType ==="private") &&
                    <>
                        <Ellipsis id={`ellipsis`} className="button" onClick={()=>handleEllipseClick()}/>
                        {showEllipseDrop && (
                        <ul>
                            <li onClick={()=>handlePublishClick()}><span>Publish System</span><img id="share" src={share} alt=""/></li>
                            <li onClick={()=>handleEditClick()}><span>Edit</span><img id="edit" src={edit} alt=""/></li>
                            <li onClick={()=>handleDeleteClick()}><span>Delete</span><img id="delete" src={deleteIcon} alt=""/></li>
                        </ul>
                        )}

                    </>
                }
                {(pageType==="existing" && props.sysType ==="public") &&
                    <button id="followBtn" onClick={()=>props.handleSystemFollow()}><span>Follow</span><img id="follow" src={follow} alt=""/></button>
                }
                {(pageType==="existing" && props.sysType ==="followed") &&
                    <img id="following" src={following} alt=""/>
                }
            </div>
            <Tabs id="sysTabs" 
                activeKey={key}
                onSelect={(k) => setKey(k)}
                fill 
                justify
            >
                <Tab eventKey="system" title="System Info">
                    <div className='sysView'>
                        <div className="bet-div">
                            <h5>Bet</h5> 
                            {system.bet}
                        </div>
                        <div className="record-div">
                            <h5>Record</h5> 
                            {system.p > 0 ? (system.w +"-"+system.l+"-"+system.p) : (system.w+"-"+system.l)}
                        </div>
                        <div className="crit-div">
                            <h5>Criteria</h5> 
                            {system && system.criteria && 
                                (system.criteria.map((crit,i)=>{
                                    return(
                                        <p key={i}>{crit.label}</p>
                                    )
                                }))
                            }
                        </div>
                        <div id={`win-div`} className="win-div">
                            {system.w/(system.w+system.l)*100 >= 55 && <img id={`win-caret`} src={greenCaret} alt=""/>}
                            <div>
                                <h5>Win</h5>
                                {system.roi !== "NaN" ? (system.w/(system.w+system.l)*100).toFixed(2) : 0}%
                            </div>
                            {system.w/(system.w+system.l)*100 < 55 && <img id={`win-caret`} src={redCaret} alt=""/>}

                        </div>
                        <div id={`roi-div`} className="roi-div">
                            {system.roi > 0 && <img id={`roi-caret`} src={greenCaret} alt=""/>}
                            <div>
                                <h5>Roi</h5>
                                <span>{system.roi !== "NaN" ? system.roi : 0}% </span>
                            </div>
                            {system.roi < 0 && <img id={`roi-caret`} src={redCaret} alt=""/>}
                        </div>
                        {system.roi !== "NaN" && (
                            <div id="graph_div">
                                <Carousel id="graphCarousel" variant="dark" controls={false}>
                                    <Carousel.Item>
                                        <WLChart sznWL={sznWL} />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <WinPerChart sznWL={sznWL} />
                                    </Carousel.Item>                
                                    <Carousel.Item>
                                        <ProfitChart sznWL={sznWL} />
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        )}
                    </div>
                    
                </Tab>
                <Tab eventKey="bets" title="Bet Results">
                    <SystemBets matches={system.matches} />
                </Tab>                
            </Tabs>
        </div>
        )

}

export default SystemDisp