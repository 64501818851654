import React, {useState, useEffect} from 'react'
import { Chart, LinearScale, PointElement, LineElement,  CategoryScale, Tooltip } from "chart.js";
import { Line } from 'react-chartjs-2';
const RoiChart = (props) => {
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);
    
    useEffect(()=>{
        Chart.register(LinearScale, PointElement, LineElement,  CategoryScale, Tooltip);
        // chart options 
        const tmpOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                y: {
                    ticks: {
                        display: false
                    },
                    grid:{
                        display:false,
                        drawBorder: false
                    }, 
                    border:{
                        display:false
                    }
                },
                x: {
                    ticks: {
                        display: false
                    },
                    grid:{
                        display:false,
                        drawBorder: false 
                    }, 
                    border:{
                        display:false
                    }
                }
            }
        };
        setOptions(tmpOptions);

        // labels 
        let labels = props.system.roiData.map((data, i)=>{
            return i;
        });
        // labels 
        let data = props.system.roiData.map((data, i)=>{
            return data;
        });

        // color chart green or red based on roi
        let backPos = "#15b86c", backNeg = "#DA1E34", backColor;
        if(parseFloat(props.system.roi) > 0){
            backColor = backPos;
        }else{
            backColor = backNeg;
        };

        // data 
        let tmpData = {
            datasets: [
                {
                    fill: {
                        target: 'origin',
                        below: backNeg,
                        above: backPos
                    },
                    borderWidth: 1,
                    borderColor:"#dcdfe0",
                    data: data,
                    tension: 0.4,
                    pointRadius:0
                }
            ],
            labels: labels
        };
        setData(tmpData);
    },[])
    
    if(options && data){
        return (
            <div className="roiChart" >
                <Line options={options} data={data}id={`chart-${props.system._id}`}/>
            </div>
        );
    }else{
        return(<></>)
    }

};

export default RoiChart;