import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GameAnalyzerChart = ({chartType, data, options, chartLabel, horizontalLine=null}) => {
  const [horizontalLinePlugin, setHorizontalLinePlugin] = useState(null);
  const [legendPlugin, setLegendPlugin] = useState(null);
  const [isChartReady, setIsChartReady] = useState(false);

  const addHorizontalLine = async () => {
    let tmpPlugin = null;
    if(horizontalLine){
      // Define the value where the horizontal line will be drawn
      const lineValue = horizontalLine;
      tmpPlugin = {
        id: 'horizontalLine',
        afterDraw: (chart) => {
          const { ctx, chartArea: { top, right, bottom, left }, scales: { y } } = chart;
          ctx.save();

          const yPos = y.getPixelForValue(lineValue);
      
          // Draw the line
          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.strokeStyle = 'black';
          ctx.moveTo(left, yPos);
          ctx.lineTo(right, yPos);
          ctx.stroke();
        }
      }; 
      setHorizontalLinePlugin(tmpPlugin); 
    }else{
      if(horizontalLinePlugin){
        ChartJS.unregister(horizontalLinePlugin)
        setHorizontalLinePlugin(null);
      };
    };
  };

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');
  
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;
  
      legendContainer.appendChild(listContainer);
    };
  
    return listContainer;
  };

  const getLegendPlugin = async() => {
    if (chartType === 'runline') {
      const legend = document.getElementById('legend-container')
      if(legend) legend.style.display = 'none';
      setLegendPlugin(null);
    } else {
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
          const ul = getOrCreateLegendList(chart, options.containerID);
  
          // Remove old legend items
          while (ul.firstChild) {
            ul.firstChild.remove();
          }
  
          // Reuse the built-in legendItems generator
          const items = chart.options.plugins.legend.labels.generateLabels(chart);
  
          items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.marginLeft = '10px';
  
            li.onclick = () => {
              const {type} = chart.config;
              if (type === 'pie' || type === 'doughnut') {
                // Pie and doughnut charts only have a single dataset and visibility is per item
                chart.toggleDataVisibility(item.index);
              } else {
                chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
              }
              chart.update();
            };
  
            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';
            boxSpan.style.display = 'inline-block';
            boxSpan.style.flexShrink = 0;
            boxSpan.style.height = '20px';
            boxSpan.style.marginRight = '10px';
            boxSpan.style.width = '20px';
  
            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = 0;
            textContainer.style.padding = 0;
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
  
            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);
  
            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
          });
        }
      };
  
      setLegendPlugin(htmlLegendPlugin);
      const legend = document.getElementById('legend-container')
      if(legend) legend.style.display = 'block';
    }
  };

  const prepareChart = async() => {
    if(data !== null){
      await addHorizontalLine();
      await getLegendPlugin();
      setIsChartReady(true);
    };
  };

  // run when horizontal line prop changes - update state plugins
  useEffect(() => {
    prepareChart();
    getLegendPlugin();
  }, [horizontalLine, data, options, chartType]);

  if(isChartReady && horizontalLinePlugin){
    ChartJS.register(horizontalLinePlugin);
    return (
      <div id='chart-container'>
        <span id='chart-label'>{chartLabel}</span>
        <div id='legend-container'></div>
        <Bar data={data} options={options} plugins={[horizontalLinePlugin, legendPlugin]}/>
      </div>
    );
  }else if(isChartReady){
    return (
      <div id='chart-container'>
        <span id='chart-label'>{chartLabel}</span>
        <div id='legend-container'></div>
        <Bar data={data} options={options} plugins={[legendPlugin]}/>
      </div>
    );
  }else{
    return null;
  };

};

export default GameAnalyzerChart;
