import React, {useState, useEffect} from 'react'
import { Chart, LinearScale, BarElement,  CategoryScale, Title, Legend, Tooltip } from "chart.js";
import { Bar } from 'react-chartjs-2';
const WLChart = (props) => {
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);

    useEffect(()=>{
        if(props.sznWL.length > 0){
            Chart.register(LinearScale, BarElement,  CategoryScale, Title, Legend, Tooltip );
            // build datasets and labels arrays
            let w = [], l = [], p = [], labels = [];
            for(const szn of props.sznWL){
                labels.push(szn.season);
                w.push(szn.w);
                l.push(szn.l);
                p.push(szn.p);
            };
            // chart options 
            const tmpOptions = {
                plugins: {
                    legend: {
                        position: "bottom"
                    }
                },
                scales:{
                    x:{
                        grid:{
                            display:false
                        }
                    },
                    y:{
                        grid:{
                            display:false
                        }
                    }
                }
            };
            setOptions(tmpOptions);

            // data 
            let tmpData = {
                labels: labels,
                datasets: [
                    {
                        label:'Win',
                        backgroundColor:'rgba(52, 199, 89, .75)',
                        borderWidth: 1,
                        borderColor:"#dcdfe0",
                        data: w
                    },
                    {
                        label:'Loss',
                        backgroundColor:'rgba(255, 45, 85,.75)',
                        borderWidth: 1,
                        borderColor:"#dcdfe0",
                        data: l
                    },
                    {
                        label:'Push',
                        backgroundColor:'rgba(128, 128, 128,.75)',
                        borderWidth: 1,
                        borderColor:"#dcdfe0",
                        data: p
                    }
                ] 
            };
            setData(tmpData);
        };
    },[props.sznWL])
    
    if(options && data){
        return (
            <Bar options={options} data={data} />
        );
    }else{
        return(<></>)
    }

};

export default WLChart;