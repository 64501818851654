import { useDispatch} from 'react-redux';
import { setLoggedIn, setPermissions, setUser, setUserId, setFollowedSystems, setPrivateSystems, setMatches} from "../redux/userSlice";
import {useNavigate} from 'react-router-dom';
import localforage from 'localforage';

// function to handle clearing userSession without navigation to home page
export const useClearUserSession = () =>{
    const dispatch = useDispatch();
    const clearSession = async() => {
        // clear all user data from redux
        await localforage.clear();
        dispatch(setLoggedIn(false));
        dispatch(setUser(null));
        dispatch(setUserId(null));
        dispatch(setPermissions(null));
        dispatch(setFollowedSystems([]));
        dispatch(setPrivateSystems([]));
        dispatch(setMatches([]));

        // remove tokens from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('id_token');
    };

    return clearSession;
};

// function to handle manual logout 
export const useLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logout = async() => {
        // clear all user data from redux
        await localforage.clear();
        dispatch(setLoggedIn(false));
        dispatch(setUser(null));
        dispatch(setUserId(null));
        dispatch(setPermissions(null));
        // remove tokens from local storage
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('id_token');
        navigate("/");
    };
    
    return logout;
};