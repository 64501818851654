import React, { useContext, useState, useEffect } from 'react'
import { SystemContext } from '../SystemBuilder';
import { Row, Col, Image } from 'react-bootstrap';
import write from '../../../images/icons/sm-write.svg';
import close from '../../../images/icons/noun_Close_3850069.svg';
import {ReactComponent as PlusIcon} from '../../../images/icons/plus.svg';
const SystemCriteria = (props) => {
    const systemContext = useContext(SystemContext);
    const [err, setErr] = useState(false);

    const editCrit = (crit, i) => {
        systemContext.displayCrit(crit.type, crit, i);
    };

    const validateCrit = (e) => {
        e.preventDefault();
        if(systemContext.criteria.length > 0){
            systemContext.setStep(5)
        }else{
            setErr(true);
        };
    };

    return (
        <>
            <div id="header-row">
                    <h2>System Criteria</h2>
                    <PlusIcon className="icon headerBtn" id="addCritBtn" onClick={()=>systemContext.setStep(3)} />
            </div>
            <div id='critList'>
                {systemContext.criteria.length > 0 ? (
                    <>
                        {systemContext.criteria.map((crit,i)=>{
                            return(
                            <div key={i} className="criteria">
                                <Row key={i}>
                                    <Col>{crit.label}</Col>
                                    <Col xs={2} s={1}>
                                        <Image src={write} width="15" height="auto" onClick={()=>editCrit(crit, i)}/>
                                    </Col>
                                    <Col xs={2} s={1}>
                                        <Image src={close} width="15" height="auto" onClick={()=>systemContext.removeCrit(i)}/>
                                    </Col>
                                </Row>
                            </div>
                            )
                        })}
                    </>
                ):(
                    <div id="noCrit"><p>No Criteria</p></div>
                )}
            </div>
            {err && (<p className='errMsg'>Please add at least one criteria</p>)}
            <div id='submit-btns'>
                <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>systemContext.setStep(1)}>Back</button>
                <button className="primaryBtn" id="saveCritBtn"  onClick={(e)=>validateCrit(e)}>Next</button>
            </div>
        </>

    )
}

export default SystemCriteria;