import SignUpForm from '../forms/SignUp';
import styles from '../../scss/pages/loginSignup.module.scss';
import { useNavigate } from 'react-router-dom';

const SignUpPage = () => {
  const navigate = useNavigate();

  return (
    <div className='full-page'>
      <div className={'page-section'}>
        <div className={styles.mainView}>
            <h1 className={styles.title}>Sign Up</h1>
            <SignUpForm className={styles.formFields}/>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;