import { createSlice } from '@reduxjs/toolkit';

const initialState  = {
    upcoming: [],
    publicSystems: []
}

const mlbSlice = createSlice({
    name: 'mlb',
    initialState,
    reducers:{
        setMlbUpcoming: (state, action) => {
            state.upcoming = action.payload
        },
        setMlbPublicSystems: (state, action) => {
            state.publicSystems = action.payload
        },
    }
})

export const {setMlbUpcoming, setMlbPublicSystems} = mlbSlice.actions;
export default mlbSlice.reducer;
