import React from 'react';
import { Row, Col } from 'react-bootstrap';
import hero from '../../images/img/hero.png';
import heroSm from '../../images/img/hero-sm.png';
import underline from '../../images/img/underline.svg';
import alert from '../../images/icons/alert.svg';
import build from '../../images/icons/build.svg';
import backtest from '../../images/icons/backtest.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from '../../scss/pages/about.module.scss';

const About = () => {
    const navigate = useNavigate();
    const loggedIn = useSelector((state)=> state.user.loggedIn)

    return (
        <div className='full-page'>
            <div className='page-section' id={styles["section-1"]}>
                <div id={styles["sec1-content"]}>
                    <h3><span id={styles["strikethrough"]} >Sports Betting<img src={underline}/></span></h3>
                    <h1>Sports Investing</h1>
                    <h1>DESIGN YOUR OWN <span id={styles["red-text"]}>SYSTEMS<img id={styles["underline"]} src={underline}/></span></h1>
                    <h4>See how they hold up historically</h4>
                    <h4>Get notified of future bets</h4>
                    <p>Say goodbye to the days of needing to be a programmer or excel wizard to build sports betting models. Create, backtest, and tail the systems that you want. Bring your own sports knowledge and insight and we'll take care of the rest.</p>
                    {!loggedIn ? (
                    <>
                        <button className={`primaryBtn ${styles['loginSignupBtn']}`} onClick={() => navigate('/signup')}>Sign Up</button>
                        <button className={`secondaryBtn ${styles['loginSignupBtn']}`} onClick={() => navigate('/signin')}>Log In</button>    
                    </>
                    ) : null}
                    <img id={styles["hero"]}
                        src={heroSm}
                        srcSet={`${hero} 768w`}
                    />
                </div>
            </div>
            <div className='page-section' id={styles['section-2']}>
                <Row>
                    <Col><h1>How It Works</h1></Col>
                </Row>
                <Row>
                    <Col sm={12} lg={4} className={`${styles["right-point-col"]} center-align`}>
                        <div className={styles['right-point-p1']}>
                            <div className={styles['step']}>
                                <div className={styles['step-icon']}>
                                    <img src={build} alt=""/>
                                </div>
                                <h5>01. Build</h5>
                                <p>Simply name your system, add the criteria you want to look at, and choose a bet to backtest against.</p>
                                {/* <button className='show-more'>Show More 🡳</button> */}
                            </div>
                        </div>
                        <div className={styles['right-point-p2']}></div>
                    </Col>
                    <Col sm={12} lg={4} className={`${styles["right-point-col"]} center-align`}>
                        <span className={styles["right-point-p1"]}>
                            <div className={styles['step']}>
                                <div className={styles['step-icon']}>
                                    <img src={backtest} alt=""/>
                                </div>
                                <h5>02. Backtest</h5>
                                <p>See how your system has held up historically. We give you the win percentage, return on investment, record, and breakdown year over year.</p>
                                {/* <button className='show-more'>Show More 🡳</button> */}
                            </div>
                        </span>
                        <span className={styles['right-point-p2']}></span>
                    </Col>
                    <Col sm={12} lg={4} className={`${styles['right-point-col']} center-align`}>
                        <span className={styles['right-point-p1']}>
                            <div className={styles['step']}>
                                <div className={styles['step-icon']}>
                                    <img src={alert} alt=""/>
                                </div>
                                <h5>03. Tail</h5>
                                <p>We'll notify you when a game matches one of your systems, and show you which books are offering the best lines.</p>
                                {/* <button className='show-more'>Show More 🡳</button> */}
                            </div>
                        </span>
                        <div className={styles['right-point-p2']}></div>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default About