import apiService from "./apiService";

// function to fetch upcoming games 
export const fetchUpcomingGames = async(route) =>{
    const response = await apiService(route);
    return response
};

// function to fetch mlb games with missing odds
const fetchMlbMissingOdds = async() =>{
    try{
        const response = await apiService('mlb/missing-odds');
        response.sort(function(a, b) { // sort missing odds by date
            let dateA = new Date(a.date);
            let dateB = new Date(b.date);
            return dateA - dateB;
        });
        return response
    }catch(error){
        console.log(error);
    };
};

// function to fetch games with missing odds
const fetchNflMissingOdds = async() =>{
    try{
        const response = await apiService('nfl/missing-odds');
        return response;
    }catch(error){
        console.log(error);
    };
};