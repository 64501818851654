
import React from 'react'

const Tutorials = () => {

    return (
        <div className='full-page'>
            <div className={'page-section'}>
                <h1>Tutorials</h1>
                <div className='tutorial'>
                    <h3>Creating an MLB system</h3>
                    <iframe
                        width="250"
                        height="450"
                        src="https://www.youtube.com/embed/kDLK3PvkHQ8?si=1tVPJfA-d8Mbw1kV"
                        title="Creating an MLB system"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    )

}

export default Tutorials