import React, {useState, useEffect} from 'react'
import { Chart, LinearScale, PointElement, Title, LineElement,  CategoryScale, Tooltip, Filler } from "chart.js";
import { Line } from 'react-chartjs-2';
const WinPerChart = (props) => {
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);
    useEffect(()=>{
        if(props.sznWL.length > 0){
            Chart.register(LinearScale, LineElement, PointElement, CategoryScale, Title, Tooltip );
            // build datasets and labels arrays
            let labels = [], winPer = [], total_w = 0, total_l = 0;
            for(const szn of props.sznWL){
                // get last value of win percentage
                let last = winPer[winPer.length - 1]
                // if no games matched the system in a season, add last winPer
                if(szn.w === 0 && szn.l === 0 && winPer.length > 0){
                    winPer.push(last)
                }
                // else if first season, and no matches, add zero
                else if(winPer.length === 0 && szn.w === 0 && szn.l === 0){
                    winPer.push(0)
                }
                // other wise calc new win percentage all time
                else{
                    total_l += szn.l;
                    total_w += szn.w;
                    let tmpPer = (total_w/(total_l+total_w))*100 
                    winPer.push(tmpPer)
                }
                labels.push(szn.season);
            };
            // chart options 
            const tmpOptions = {
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                        grid:{
                            display:false,
                        }, 
                    }, 
                    x:{
                        grid:{
                            display:false,
                        }, 
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Win Percentage',
                        position: 'bottom',
                        padding:{
                            top: 10,
                        }
                    }
                }
            }
            setOptions(tmpOptions);

            // data 
            let tmpData = {
                labels: labels,
                datasets: [
                    {
                        label:'Win %',
                        fill: true,
                        backgroundColor: 'rgba(52, 199, 89, .75)',
                        data: winPer,
                        pointRadius: 0,
                        tension: 0.4,
                        borderWidth: 1,
                        borderColor:"#dcdfe0"
                    }
                ] 
            };
            setData(tmpData);
        };
    },[props.sznWL])
    
    if(options && data){
        return (
            <Line options={options} data={data} />
        );
    }else{
        return(<></>)
    }

};

export default WinPerChart;