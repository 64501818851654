import React, { useContext, useState, useCallback} from 'react'
import { SystemContext } from '../SystemBuilder';
import { Button } from 'react-bootstrap';

// Criteria Components
import FavoriteUnderdog from './filters/teamFilters/FavoriteUnderdog';
import TeamSelector from './filters/teamFilters/TeamSelector';
import OddsRange from './filters/lineFilters/OddsRange';
import SpreadFilter from './filters/lineFilters/SpreadFilter';
import GameTotalsFilter from './filters/lineFilters/GameTotalsFilter';
import StatAverages from './filters/statFilters/averages/StatAverages';

const CriteriaDisplay = (props) => {
    const sysContext = useContext(SystemContext)

    const cancel = () => {
        sysContext.setStep(3);
        sysContext.setCritDisp(null);
    };

    const components = {
        "team.fav-dog": <FavoriteUnderdog sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "team.home-team-sel": <TeamSelector homeAway="home" sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "team.away-team-sel": <TeamSelector homeAway="away" sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "line.odds-filter": <OddsRange sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "line.team-spread": <SpreadFilter sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "line.game-totals": <GameTotalsFilter sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "stat.avg": <StatAverages type="avg" sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>,
        "stat.avg-comp": <StatAverages type="avg-comp" sysContext={sysContext} initCrit={props.critDisp.crit} cancel={cancel}/>
    };

    return(
        <>
            {components[props.critDisp.filter]}
        </>
    )
   
};

export default CriteriaDisplay;