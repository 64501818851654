import React, { useEffect, useState } from 'react';
import {Form} from "react-bootstrap";

const MlbStatAvgs = (props) => {
    const game = props.game;
    const [battingStats, setBattingStats] = useState(null)
    const [pitchingStats, setPitchingStats] = useState(null)
    const [activeTab, setActiveTab] = useState('batting');
    const battingLabels = {
        "AB":"At Bats",
        "R":"Runs",
        "H":"Hits",
        "HR": "Home Runs",
        "RBI":"RBIs",
        "BB":"Walks Taken",
        "SO":"Strike Outs",
        "PA":"Plate Appearances",
        "BA":"Batting Average",
        "OBP":"On Base Percentage",
        "SLG":"Slugging Percentage",
        "OPS":"OPS",
        "Pit":"Pitches Taken",
        "Str":"Strikes Taken",
        "doubles":"Doubles",
        "triples":"Triples",
        "SF":"Sac Flies",
        "SB":"Stolen Bases",
        "CS":"Caught Stealing",
        "LOB":"Left on Base",
    };
    const pitchingLabels = {
        "IP":"Innings Pitched",
        "H":"Hits Allowed",
        "R":"Runs Allowed",
        "ER":"Earned Runs",
        "BB":"Batters Walked",
        "SO":"Strike Outs Thrown",
        "HR":"Home Runs Allowed",
        "ERA":"ERA",
        "BF":"Batters Faced",
        "Pit":"Pitches Thrown",
        "Str":"Strikes Thrown",
        "Ctct":"Strikes Thrown By Contact ",
        "StS":"Strikes Thrown Swinging",
        "StL":"Stikes Thrown Looking",
        "GB":"Ground Balls Allowed",
        "FB":"Fly Balls Allowed",
        "LD":"Line Drives Allowed"
    };
    useEffect(()=>{
        if('batting' in game.home){
            const battingKeys = Object.keys(game.home.batting);
            const pitchingKeys = Object.keys(game.home.pitching);
            let tmpBattingStats = {}, tmpPitchingStats = {}
            battingKeys.forEach(stat=>{
                tmpBattingStats[stat] = [game.home.batting[stat], game.away.batting[stat]]
            });
            pitchingKeys.forEach(stat=>{
                tmpPitchingStats[stat] = [game.home.pitching[stat], game.away.pitching[stat]]
            })
            setBattingStats(tmpBattingStats)
            setPitchingStats(tmpPitchingStats)
        };
    }, [props])

    
    return(
        <>
            <Form.Select id="bet-select" value={activeTab} onChange={e => setActiveTab(e.target.value)}>
                <option value="batting">Batting</option>
                <option value="pitching">Pitching</option>
            </Form.Select>
            {activeTab === "batting" & battingStats !== null ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{game.home.team}</th>
                            <th>{game.away.team}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(battingStats).map(stat=>
                            (
                                <tr>
                                    <td>{battingLabels[stat]}</td>
                                    <td>{game.home.batting[stat]}</td>
                                    <td>{game.away.batting[stat]}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
                ): null
            }
            {activeTab === "pitching" & pitchingStats !== null ? (
                <table className='statsTable'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{game.home.team}</th>
                            <th>{game.away.team}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pitchingStats !== null ?
                            (Object.keys(pitchingStats).map(stat=>
                                (
                                    <tr>
                                        <td>{pitchingLabels[stat]}</td>
                                        <td>{game.home.pitching[stat]}</td>
                                        <td>{game.away.pitching[stat]}</td>
                                    </tr>
                                )
                            )):
                            null
                        }
                    </tbody>
                </table>):null
            }
        </>
    )
   

};

export default MlbStatAvgs;