import React, {useEffect, useState} from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

const SpreadRange = (props) => {
    const [formType, setFormType] = useState("");
    const [team, setTeam] = useState("");
    const [min, setMin] = useState("");
    const [max, setMax] = useState("");
    const [runline, setRunline] = useState("");
    const [initCrit, setInitCrit] = useState(null);
    const [critIdx, setCritIdx] = useState(null);
    const [errs, setErrs] = useState([]);
    const [errMsg, setErrMsg] = useState(null);

    const formFields = {
        min:
            <FloatingLabel label='Minimum Spread' className='sysField'>
                <Form.Control id="min" type="number" value={min} onChange={(e)=>convertToNum(e.target.value, setMin)}/>
            </FloatingLabel>,
        max: 
            <FloatingLabel label='Maximum Spread' className='sysField'>
                <Form.Control id="max" type="number" value={max} onChange={(e)=>convertToNum(e.target.value, setMax)}/>
            </FloatingLabel>,
        runline: 
            <FloatingLabel label="Runline" id="runline-div" className='sysField'>
                <Form.Control id="sport" as="select" value={runline} onChange={(e)=>convertToNum(e.target.value, setRunline)}>
                    <option value=""></option>
                    <option value={-1.5}>-1.5</option>
                    <option value={1.5}>+1.5</option>
                </Form.Control>
            </FloatingLabel>,
        spreadType: 
            <FloatingLabel label='Choose spread filter' id="spread-div" className='sysField'>
                <Form.Control id="spread-type" as="select" value={formType} onChange={(e)=>setFormType(e.target.value)}>
                    <option value=""></option>
                    <option value="between">Spread is between</option>
                    <option value="greater">Spread is greater than</option>
                    <option value="less">Spread is less than</option>
                </Form.Control>
            </FloatingLabel>,
        team: 
            <FloatingLabel label='Choose team' id="team-div" className='sysField'>
                <Form.Control id="team" as="select" value={team} onChange={(e)=>setTeam(e.target.value)}>
                    <option value=""></option>
                    <option value="home">Home</option>
                    <option value="away">Away</option>
                </Form.Control>
            </FloatingLabel>
    };

    const forms = { 
        runline: formFields.runline,
        between: 
            <>
                {formFields.min}
                {formFields.max}
            </>,
        greater: formFields.min,
        less: formFields.max    
    };

    // run when init crit changes
    useEffect(()=> {
        // check if user is editing an existing criteria
        if(props.initCrit){
            const critCopy = {...props.initCrit.crit};
            setFormType(critCopy.rangeType);
            setTeam(critCopy.team);
            setInitCrit(critCopy);
            setCritIdx(props.initCrit.index);
            switch(critCopy.rangeType){
                case "runline":
                    setRunline(critCopy.runs);
                case "between":
                    setMin(critCopy.min);
                    setMax(critCopy.max);
                    break;
                case "greater":
                    setMin(critCopy.min);
                    break;
                case "less":
                    setMax(critCopy.max);
                    break;
                default: 
                    break;
            };
        };
    },[props.initCrit])

    // function to check for errors
    const checkErrs = () => {
        let tmpErrs = [];
        let tmpErrMsg = null;
        switch(formType){
            // Runline - runline needs to be +-1.5 
            case "runline":
                if(Math.abs(runline) !== 1.5){
                    errs.push("runline");
                };
                break;
            // Between - min and max need to be numbers & min < max
            case "between":
                if(isNaN(min) || min === ""){
                    tmpErrs.push("min");
                };
                if(isNaN(max) || max === ""){
                    tmpErrs.push("max");
                };
                if(!isNaN(min) && min !== "" && !isNaN(max) && max !== ""){
                    if(min >= max){
                        tmpErrs.push("min");
                        tmpErrs.push("max");
                        tmpErrMsg = "Please ensure the minimum spread value is less than the maximum";
                    };
                };
                break;
            case "greater":
                if(isNaN(min) || min === ""){
                    tmpErrs.push("min");
                };
                break;
            case "less":
                if(isNaN(max) || max === ""){
                    tmpErrs.push("max");
                };
                break;
            default:
                tmpErrs.push("spread-type");
                break; 
        };
        if(team !== "home" && team !== "away"){
            tmpErrs.push("team");
        };

        return({errs: tmpErrs, msg: tmpErrMsg});
    };

    // Function to get label for criteria
    const getLabel = () => {
        let label = "";
        let minLbl = "";
        let maxLbl = "";
        let teamLbl = team[0].toUpperCase() + team.slice(1);
        switch(formType){
            case "runline":
                let runLbl = runline > 0 ? `+${runline}` : runline;
                label = `${teamLbl} runline is ${runLbl}`;
                break;
            case "between":
                minLbl = min > 0 ? `+${min}` : min;
                maxLbl = max > 0 ? `+${max}` : max;
                label = `${teamLbl} spread is between ${minLbl} and ${maxLbl}`;
                break;
            case "greater":
                minLbl = min > 0 ? `+${min}` : min;
                label = `${teamLbl} spread is ${minLbl} or greater`;
                break;
            case "less":
                maxLbl = max > 0 ? `+${max}` : max;
                label = `${teamLbl} spread is ${maxLbl} or less`;
                break;
        };
        return label; 
    };

    // Function to get criteria for submission
    const getCrit = () => {
        let crit = {
            type: "line.team-spread",
            rangeType: formType,
            team: team
        };
        const label = getLabel();
        crit.label = label;
        switch(formType){
            case "runline":
                crit.runs = runline;
                break;
            case "between":
                crit.min = min;
                crit.max = max;
                break;
            case "greater":
                crit.min = min;
                break;
            case "less":
                crit.max = max;
                break;
        };
        return crit; 
    };

    // function to check if init criteria has changed
    const checkCritChange = (crit) => {
        let modified = false;
        // no initial criteria passed - set modified to true
        if(critIdx != null){
            // check if range type changed
            if(crit.rangeType !== initCrit.rangeType){
                modified = true;
            // check if team changed
            }else if(crit.team !== initCrit.team){
                modified = true;
            // check values for changes 
            }else{
                switch(crit.rangeType){
                    case "runline":
                        if(crit.runs !== initCrit.runs){
                            modified = true;
                        };
                        break;
                    case "between":
                        if(crit.min !== initCrit.min || crit.max !== initCrit.max){
                            modified = true;
                        };
                        break;
                    case "greater":
                        if(crit.min !== initCrit.min){
                            modified = true;
                        };
                        break;
                    case "less":
                        if(crit.max !== initCrit.max){
                            modified = true;
                        };
                        break;
                };
            };
        }else{
            modified = true;
        };
        return modified;
    };

    // set submit function in parent component
    const validateSubmit = (e) => {
        e.preventDefault();
        // check for errors
        const errCheck = checkErrs(); 
        setErrMsg(errCheck.msg);
        setErrs(errCheck.errs);
        if(errCheck.errs.length === 0){
            // check for crit change - returns true if initCrit was modified or not passed
            const crit = getCrit(); 
            const modified = checkCritChange(crit);
            console.log(modified)
            if(modified){
                props.sysContext.changeCrit(crit, critIdx);
                props.sysContext.setCritDisp(null);
            };
            props.sysContext.setStep(2);
        };
    };

    // function to convert sting to number
    const convertToNum = (val, cb) => {
        let parsed = "";
        // if value is a number - parse the int or float
        if (val !== "" && !isNaN(val)) {
            parsed = val.includes(".") ? parseFloat(val) : parseInt(val);
        };
        cb(parsed);
    };

    // Run when system context changes - set formType and bet label
    useEffect(()=>{
        const sport = props.sysContext.sport;
        if(sport === "mlb"){
            setFormType("runline");
        };
    },[props.sysContext]);

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            const elem = document.getElementById(err);
            if(elem){
                elem.classList.add("err");
            };
        });
    },[errs]);    

    return (
        <>
            <div id='header-row'>
                <h2>Spread Filter</h2>
            </div>
            <Form>
                {formFields.team}
                {formType !== "runline" && formFields.spreadType}
                {formType !== "" && forms[formType]}
                {errMsg && (<p className='errMsg'>{errMsg}</p>)}
                <div id='submit-btns'>
                    <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>props.cancel()}>Cancel</button>
                    <button className="primaryBtn" id="saveCritBtn" onClick={(e)=>validateSubmit(e)}>Save Criteria</button>
                </div>
            </Form>
        </>
    )

}

export default SpreadRange