import React, {useState, useEffect, useCallback} from 'react';
import { FormSelect } from 'react-bootstrap';
import '../../../scss/pages/gameAnalyzer.scss';
import GameAnalyzerChart from '../../gameAnalyzer/Chart';
import ResultsOverview from '../../gameAnalyzer/ResultsOverview';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import back from '../../../images/icons/back.svg';
import apiService from '../../../utils/apiService';

const GameAnalyzer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const game = location.state?.game || null;
    
    const [linescores, setLinescores] = useState(null);
    const [chartType, setChartType] = useState("inning-runs");
    const [subSelection, setSubSelection] = useState("FG");
    const [numGames, setNumGames] = useState(5);
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({});
    const [chartLabel, setChartLabel] = useState('');
    const [horizontalLine, setHorizontalLine] = useState(null);
    const [subDropdownOptions, setSubDropdownOptions] = useState(null);
    const [apiError, setApiError] = useState(false); // boolean to indicate if data is unavailable for chart / performance elements
    const [gamesData, setGamesData] = useState(null);
    const [totalsResults, setTotalsResults] = useState(null);
    const [runlineResults, setRunlineResults] = useState(null);
    const [results, setResults] = useState(null); // change to result sets

    const homeColor = "rgba(66, 135, 245, 0.75)";
    const homeBorderColor = "rgba(66, 135, 245, 1)";
    const awayColor = 'rgba(255, 45, 85, .75)';
    const awayBorderColor = 'rgba(255, 45, 85, 1)';
    const winColor = 'rgba(52, 199, 89, .75)';
    const winBorder = 'rgba(52, 199, 89, 1)';

    const fetchMlbLinescores = useCallback(async (game) => {
        try {
            const response = await apiService(`mlb-linescores?home=${game.home.team}&away=${game.away.team}`, 'GET', null, {}, 'v2');
            setLinescores(response);
        } catch (error) {
            console.error('Error fetching MLB linescores:', error);
            setApiError(true);
        };
    }, []);

    // Run to pull linescores from API
    useEffect(()=>{
        if(game === null){
            navigate('/upcoming-games'); // if no game - navigate to upcoming page
        }else{
            fetchMlbLinescores(game);
        };
    },[game, fetchMlbLinescores, navigate]);

    // Run to get team games data
    useEffect(()=> {
        if(!linescores || !game) return; 
        
        const initializeGamesData = () => {
            const keys = ["FG", "F5", "L4", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
            return keys.reduce((acc, inning) => {
                acc[inning] = {
                    data: [],
                    results: []
                };
                return acc;
            }, {});
        };

        // initialize game data for each team
        const teams = Object.keys(linescores);
        const tmpGamesData = teams.reduce((acc, team) => {
            acc[team] = initializeGamesData();
            return acc;
        }, {});

        const updateGamesObj = (obj, key, team1, val1, team2, val2) => {
            // add values to object
            obj[team1][key].data.unshift(val1);
            obj[team2][key].data.unshift(val2);
            // update results for individual innings
            if (val1 > val2) {
                obj[team1][key].results.unshift(1);
                obj[team2][key].results.unshift(0);
            } else if (val1 < val2) {
                obj[team1][key].results.unshift(0);
                obj[team2][key].results.unshift(1);
            } else {
                obj[team1][key].results.unshift(2);
                obj[team2][key].results.unshift(2);
            };
        };

        const processGameSet = (obj, team1Game, team1, team2Game, team2) => {
            // full game data
            const t1FG = team1Game['team_score'];
            const t2FG = team2Game['team_score'];
            updateGamesObj(obj, "FG", team1, t1FG, team2, t2FG);
            // first 5 data
            const t1F5 = team1Game['team_linescore']['first5_innings'];
            const t2F5 = team2Game['team_linescore']['first5_innings'];
            updateGamesObj(obj, "F5", team1, t1F5, team2, t2F5);
            //  last 4 data
            const t1L4 = team1Game['team_linescore']['last4_innings'];
            const t2L4 = team2Game['team_linescore']['last4_innings'];
            updateGamesObj(obj, "L4", team1, t1L4, team2, t2L4);
            // individual innings data
            for (let index = 0; index < 9; index++) {
                const inningKey = `${index + 1}`;
                const t1Inning = team1Game['team_linescore']['runs_per_inning'][index];
                const t2Inning = team2Game['team_linescore']['runs_per_inning'][index];
                updateGamesObj(obj, inningKey, team1, t1Inning, team2, t2Inning);
            };
        };
        
        // get data array for each team
        const minLength = Math.min(linescores[teams[0]].length, linescores[teams[1]].length);
        for (let i = 0; i < minLength; i++){
            const t1Game = linescores[teams[0]][i];
            const t2Game = linescores[teams[1]][i];
            processGameSet(tmpGamesData, t1Game, teams[0], t2Game, teams[1]);
        };

        setGamesData(tmpGamesData);
    },[linescores, game]);

    // Run when games data is set, get totals and runline arrays 
    useEffect(()=> {
        if(!gamesData || !game) return; 

        const getTotalResult = (line, t1Score, t2Score) => {
            const diff = t1Score + t2Score - line;
            if(diff > 0){
                return 1;
            }else if(diff < 0){
                return 0; 
            }else{
                return 2; 
            };
        };

        const getRunlineResult = (t1Runline, t1Score, t2Score) => {
            const t1Diff = t1Score + t1Runline - t2Score;
            // Note: returned array is [t1 result, t2 result]
            if(t1Diff > 0){
                return {
                    tResults: [1, 0],
                    't1Diff': t1Diff
                };
            }else if(t1Diff < 0){
                return {
                    tResults: [0, 1],
                    't1Diff': t1Diff
                }; 
            }else{
                return {
                    tResults: [2, 2],
                    't1Diff': t1Diff
                }; 
            };
        };

        // get team runline value
        const getTeamRunline = (team) => {
            const runline = team === game.home.team
                ? game?.runline?.[0]?.lines?.[0]?.home?.runs
                : game?.runline?.[0]?.lines?.[0]?.away?.runs;
            
            return runline;
        };

        // teams data
        const teams = Object.keys(gamesData);
        const t1 = teams[0];
        const t2 = teams[1];
        const t1Scores = gamesData[t1]["FG"].data;
        const t2Scores = gamesData[t2]["FG"].data;

        // runline results
        const tmpRunlineData = teams.reduce((acc, team) => {
            acc[team] = {
                runline: null,
                data: [],
                results: []
            };
            return acc;
        }, {});
        const t1Runline = getTeamRunline(t1);
        tmpRunlineData[t1].runline = t1Runline;
        tmpRunlineData[t2].runline = (-1 * t1Runline);

        // totals results
        let tmpTotalsData = {
            line: null,
            results: []
        };
        const totalRuns = game?.totals?.[0]?.lines?.[0]?.runs;
        tmpTotalsData.line = totalRuns; 

        // get results
        const minLength = Math.min(t1Scores.length, t2Scores.length);
        for (let i = 0; i < minLength; i++){
            const t1Runs = t1Scores[i];
            const t2Runs = t2Scores[i];
            // update totals data
            tmpTotalsData.results.push(getTotalResult(totalRuns, t1Runs, t2Runs));
            // update runline data
            const runlineRes = getRunlineResult(t1Runline, t1Runs, t2Runs);

            tmpRunlineData[t1].results.push(runlineRes.tResults[0]);
            tmpRunlineData[t1].data.push(runlineRes.t1Diff);

            tmpRunlineData[t2].results.push(runlineRes.tResults[1]);
            tmpRunlineData[t2].data.push((runlineRes.t1Diff * -1));
        };

        setTotalsResults(tmpTotalsData);
        setRunlineResults(tmpRunlineData);
    },[gamesData, game]);

    // Function to get average value of array
    const getAverages = (data) => {
        const calculateAverage = arr => (arr.reduce((sum, value) => sum + value, 0) / arr.length).toFixed(1);

        const averages = [5, 10, 15, 20, 30].map(n => {
            const slicedData = data.slice(-n);
            
            return calculateAverage(slicedData);
        });

        return averages; 
    };

    // Function to get data for overview
    const getWLSets = (results) => {        
        const wlSets = [5, 10, 15, 20, 30].map(n => {
            let res = {
                w: 0,
                l: 0,
                p: 0
            };
            const slice = results.slice(-n);
            for (let i = 0; i < slice.length; i++) {
                if (slice[i] === 1) {
                    res.w++;
                } else if (slice[i] === 0) {
                    res.l++;
                } else if (slice[i] === 2) {
                    res.p++;
                };
            };
            const record = `${res.w}-${res.l}-${res.p}`
            const winPer = res.w + res.l > 0 ? (res.w/(res.w + res.l)*100).toFixed(1) : "N/A"
            const wlSet = {
                record,
                winPer
            }
            return wlSet;
        });

        return wlSets;
    };

    // Function to get results set for overview
    const getResults = () => {
        const teams = Object.keys(gamesData);
        let tmpResults = {
            type: chartType,
            subType: null
        };
        switch(chartType){
            case 'inning-runs': 
                tmpResults.subType = subSelection;
                // get data array and results for each team
                teams.forEach(team => {
                    const data = gamesData[team][subSelection].data; // team runs data
                    const results = gamesData[team][subSelection].results; 
                    const wl = getWLSets(results);
                    const avgs = getAverages(data);
                    tmpResults[team] = {
                        last5: { results: wl[0], average: avgs[0] },
                        last10: { results: wl[1], average: avgs[1] },
                        last15: { results: wl[2], average: avgs[2] },
                        last20: { results: wl[3], average: avgs[3] },
                        last30: { results: wl[4], average: avgs[4] }
                    };
                });
                // update results data
                setResults(tmpResults);
                break;
            case 'total-runs': 
                const team1 = teams[0];
                const team2 = teams[1];
                // get data array for each team
                let teamTotalAvgs = {};
                // get team avgs
                teams.forEach(team => {
                    const teamRuns = gamesData[team]["FG"].data; 
                    teamTotalAvgs[team] = getAverages(teamRuns);
                });
                // get win loss data 
                const totalWL = getWLSets(totalsResults.results);
                // update results data
                tmpResults.last5 = { results: totalWL[0], average: { [team1]: teamTotalAvgs[team1][0], [team2]: teamTotalAvgs[team2][0]} }
                tmpResults.last10 = { results: totalWL[1], average: { [team1]: teamTotalAvgs[team1][1], [team2]: teamTotalAvgs[team2][1]} }
                tmpResults.last15 = { results: totalWL[2], average: { [team1]: teamTotalAvgs[team1][2], [team2]: teamTotalAvgs[team2][2]} }
                tmpResults.last20 = { results: totalWL[3], average: { [team1]: teamTotalAvgs[team1][3], [team2]: teamTotalAvgs[team2][3]} }
                tmpResults.last30 = { results: totalWL[4], average: { [team1]: teamTotalAvgs[team1][4], [team2]: teamTotalAvgs[team2][4]} }

                setResults(tmpResults);
                break;
            case 'runline':
                tmpResults.subType = subSelection;

                const teamRunlineData = runlineResults[subSelection]; 
                // get win loss data 
                const runlineWL = getWLSets(teamRunlineData.results);
                const teamAvgs = getAverages(teamRunlineData.data);
                // update results data
                tmpResults.last5 = { results: runlineWL[0], average: teamAvgs[0] }
                tmpResults.last10 = { results: runlineWL[1], average: teamAvgs[1] }
                tmpResults.last15 = { results: runlineWL[2], average: teamAvgs[2] }
                tmpResults.last20 = { results: runlineWL[3], average: teamAvgs[3] }
                tmpResults.last30 = { results: runlineWL[4], average: teamAvgs[4] }

                setResults(tmpResults);
                break;
            default:
                break;
        };
    };

    // Run when subselection changes - update results if necessary
    useEffect(()=>{
        switch(chartType){
            case 'inning-runs':
                // return if game data or subselection dont exist, existing results state is accurate, and sub selection is valid
                if( !gamesData || 
                    !subDropdownOptions ||
                    !subSelection || 
                    !subDropdownOptions.some(obj => obj.key === subSelection) ||
                    (results && results.type === 'inning-runs' && results.subType === subSelection) 
                ) return;

                getResults();
                break;
            case 'total-runs': 
                // return if missing data, or results state is accurate
                if(!totalsResults || !gamesData || (results && results.type === 'total-runs')) return; 

                getResults();
                break;
            case 'runline':
                // return if runline data or subselection dont exist, existing results state is accurate, and sub selection is valid
                if( !runlineResults || 
                    !subDropdownOptions ||
                    !subSelection || 
                    !subDropdownOptions.some(obj => obj.key === subSelection) ||
                    (results && results.type === 'inning-runs' && results.subType === subSelection) 
                ) return;

                getResults();
                break;
            default:
                break;
        };
    },[subSelection, gamesData, totalsResults, runlineResults]);

    // function to get home / away colors for chart 
    const getTeamColors = (team) => {
        return team === game.home.team
            ? { color: homeColor, border: homeBorderColor }
            : { color: awayColor, border: awayBorderColor };
    };

    // formats chart and overview data
    const formatRunsByInning = useCallback((type, numGames) => {
        if (!gamesData) return;

        const labels = Array.from({ length: numGames }, (_, i) => numGames - i);
        const teams = Object.keys(gamesData);
        // init chart datasets for each team
        const teamData = teams.reduce((acc, team) => {
            const teamColors = getTeamColors(team);
            acc[team] = {
                label: team,
                data: [],
                borderWidth: 1,
                backgroundColor: teamColors.color,
                borderColor: teamColors.border,
            };
            return acc;
        }, {});

        // get data array and results for each team
        teams.forEach(team => {
            const data = gamesData[team][type].data; // team runs data
            const teamRuns = data.slice(-numGames);
            teamData[team].data = (teamRuns);
        });

        setHorizontalLine(null);
        setChartLabel('Runs Scored By Inning');
        setChartData({
            labels,
            datasets: Object.values(teamData),
        });

        setChartOptions({
            responsive: true,
            scales: {
                y: { beginAtZero: true },
                x: { grid: { display: false } },
            },
            plugins: {
                htmlLegend: { containerID: 'legend-container' },
                legend: { display: false },
                title: { display: false },
            },
        });
    }, [gamesData]);

    const formatTotalRunsData = useCallback((numGames) => {
        if (!gamesData || !totalsResults) return;

        const labels = Array.from({ length: numGames }, (_, i) => numGames - i);
        const teams = Object.keys(gamesData);
        const teamData = teams.reduce((acc, team) => {
            const teamColors = getTeamColors(team);
            acc[team] = {
              label: team,
              data: [],
              borderWidth: 1,
              backgroundColor: teamColors.color,
              borderColor: teamColors.border,
            };
            return acc;
        }, {});
        
        // get data array for each team
        teams.forEach(team => {
            const teamRuns = gamesData[team]["FG"].data; 
            const runsSlice = teamRuns.slice(-numGames);
            teamData[team].data = (runsSlice);
        });

        setChartLabel('Combined Team Runs');
        setChartData({
            labels,
            datasets: Object.values(teamData),
        });
        setChartOptions({
            responsive: true,
            scales: {
                y: { beginAtZero: true, stacked: true },
                x: { stacked: true, grid: { display: false } },
            },
            plugins: {
                htmlLegend: { containerID: 'legend-container' },
                legend: { display: false },
                title: { display: false },
            },
        });

        setHorizontalLine(totalsResults.line);    
    }, [totalsResults, gamesData]);

    const formatRunlineData = useCallback((numGames, teamSelection) => {
        if (!gamesData || !runlineResults || !game) return;

        const labels = Array.from({ length: numGames }, (_, i) => numGames - i);

        // prepare team data structure
        let teamData = {
            data: new Array(numGames),
            borderWidth: 1,
            backgroundColor: new Array(numGames), 
            borderColor: new Array(numGames)
        };

        //  data for label and runline value
        const oppTeam = (teamSelection === game.home.team) ? game.away.team : game.home.team;
        const runline = runlineResults[teamSelection].runline;
        const chartLabel = `${teamSelection} ${runline > 0 ? `+${runline}` : runline} vs. ${oppTeam}`;

        // get both team games
        const teamRunlineData = runlineResults[teamSelection].data.slice(-numGames);
        for (let i = 0; i < teamRunlineData.length; i++) {
            // calc score w/ runline and add to data array
            const score = teamRunlineData[i];
            teamData.data[i] = score;
            // color bar based on score differential 
            if( score > 0 ){
                teamData.backgroundColor[i] = winColor;
                teamData.borderColor[i] = winBorder;
            }else{
                teamData.backgroundColor[i] = awayColor;
                teamData.borderColor[i] = awayBorderColor;
            };
        };

        setChartLabel(chartLabel);
        setHorizontalLine(null);
        setChartData({
            labels,
            datasets: [teamData]
        });
        setChartOptions({
            responsive: true,
            scales: {
                y: { beginAtZero: true },
                x: { grid: { display: false } },
            },
            plugins: {
                htmlLegend: { containerID: 'legend-container' },
                legend: { display: false },
                title: { display: false },
            },
        });
    }, [game, winColor, winBorder, awayColor, awayBorderColor, runlineResults, gamesData, numGames]);

    // run when chart type changes - check if needs sub options
    useEffect(()=>{
        if(chartType === "inning-runs"){
            setSubDropdownOptions([
                { key: "FG", value: "Full Game" },
                { key: "F5", value: "First 5 Innings" },
                { key: "L4", value: "Last 4 Innings" },
                { key: "1", value: "1st Inning" },
                { key: "2", value: "2nd Inning" },
                { key: "3", value: "3rd Inning" },
                { key: "4", value: "4th Inning" },
                { key: "5", value: "5th Inning" },
                { key: "6", value: "6th Inning" },
                { key: "7", value: "7th Inning" },
                { key: "8", value: "8th Inning" },
                { key: "9", value: "9th Inning" }
            ]);
            setSubSelection("FG");
        }else if(chartType === "runline" && game) {
            setSubDropdownOptions([
              { key: game.home.team, value: game.home.team },
              { key: game.away.team, value: game.away.team },
            ]);
            setSubSelection(game.home.team);
        }else{
            setSubDropdownOptions(null);
            setSubSelection(null);
        };
    }, [chartType, game]);

    // run when chart needs to reformat data
    useEffect(()=> {
        if(!gamesData) return;
        
        switch(chartType){
            case 'inning-runs':
                if(gamesData && subDropdownOptions && subDropdownOptions.some(obj => obj.key === subSelection)){
                    formatRunsByInning(subSelection, numGames);
                };
                break;
            case 'total-runs': 
                if(totalsResults && gamesData){
                    formatTotalRunsData(numGames);
                };
                break;
            case 'runline':
                if(runlineResults && subDropdownOptions && subDropdownOptions.some(obj => obj.key === subSelection)){
                    formatRunlineData(numGames, subSelection);
                };
                break;
            default:
                break;
        };
    }, [subSelection, numGames, formatRunsByInning, formatTotalRunsData, formatRunlineData, gamesData, totalsResults, runlineResults]);

    // run to set number of games and active selection
    const changeNumGames = (num) => {
        setNumGames(num);
        // remove current active selections 
        const oldElements = document.getElementsByClassName('selected');
        if (oldElements.length > 0) {
            const oldElementsArray = Array.from(oldElements); // Convert to array
            oldElementsArray.forEach(element => {
                element.classList.remove('selected');
            });
        };
        // add new selections to elements 
        const newElements = document.getElementsByClassName(`last${num}-games`);
        if (newElements.length > 0) {
            Array.from(newElements).forEach(element => {
                element.classList.add('selected');
            });
        }
    };

    return (
        <div className='full-page'>
            <div id='game-anayzler'>
                <div id="game-analyzer-menu">
                    <button onClick={()=>navigate(`/upcoming-game/${game._id}`, {state:{game:game, sport:'mlb'}})}><img id='back-icon' src={back}/>Back to Game</button>
                    <h3 id='game'>{game.away.team} @ {game.home.team}</h3>
                    <FormSelect value={chartType} onChange={(e)=>setChartType(e.target.value)}>
                        <option value="inning-runs">Runs Scored By Inning</option>
                        {/* <option value="home-away">Home / Away Performance</option> */}
                        <option value="total-runs">Game Total Runs</option>
                        <option value="runline">Team Runline</option>
                    </FormSelect>
                    {subDropdownOptions !== null && (
                        <FormSelect value={subSelection} onChange={e=>setSubSelection(e.target.value)}>
                            {subDropdownOptions.map(opt => (
                                <option key={opt.key} value={opt.key}>{opt.value}</option>
                            ))}
                        </FormSelect>
                    )}
                </div>
                {gamesData ? (
                    <>
                        <div id="analyzer-games-menu">
                            <span id="title">Games</span>
                            <span id="sub-menu-btns">
                                <button className="last5-games numGamesBtn selected" onClick={()=>changeNumGames(5)}>L5</button>
                                <button className="last10-games numGamesBtn" onClick={()=>changeNumGames(10)}>L10</button>
                                <button className="last15-games numGamesBtn" onClick={()=>changeNumGames(15)}>L15</button>
                                <button className="last20-games numGamesBtn" onClick={()=>changeNumGames(20)}>L20</button>
                                <button className="last30-games numGamesBtn" onClick={()=>changeNumGames(30)}>L30</button>
                            </span>
                        </div>
                        <div id="chart-div">
                            <GameAnalyzerChart chartType={chartType} data={chartData} options={chartOptions} chartLabel={chartLabel} horizontalLine={horizontalLine}/>
                        </div>
                        <div id='overview-div'>
                            <ResultsOverview resultSets={results} strNumGames={`L${numGames}`} changeNumGames={changeNumGames} apiError={apiError}/>
                        </div>
                    </>
                ) : (
                    <div id='placeholder-div'>
                        {apiError ? (
                            <span>Data Unavailable</span>
                        ) : (
                            <Spinner id="spinner" animation="border" variant="dark" />
                        )}
                    </div>
                )}

            </div>
        </div>
    );
};

export default GameAnalyzer;