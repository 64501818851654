import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';

// reducers
import userReducer from './userSlice';
import appReducer from './applicationSlice';
import mlbReducer from './mlbSlice';
import nflReducer from './nflSlice';


const userPersistConfig = {
  key: 'user',
  storage: localforage
};
const appPersistConfig = {
  key: 'app',
  storage: localforage
};
const mlbPersistConfig = {
  key: 'mlb',
  storage: localforage
};
const nflPersistConfig = {
  key: 'nfl',
  storage: localforage
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedAppReducer = persistReducer(appPersistConfig, appReducer);
const persistedMlbReducer = persistReducer(mlbPersistConfig, mlbReducer);
const persistedNflReducer = persistReducer(nflPersistConfig, nflReducer);


const ignoredActions = [
  'persist/PERSIST',
];

const store = configureStore({
    reducer: {
      user: persistedUserReducer,
      app: persistedAppReducer,
      mlb: persistedMlbReducer,
      nfl: persistedNflReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ignoredActions
      }
    })
});

const persistor =  persistStore(store)

export default store;
export {persistor};
