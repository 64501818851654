import React, { useState, useEffect } from 'react'
import { mlbLeagues, mlbTeams } from '../../../../../utils/mlbUtils';
import { nflConferences, nflTeams } from '../../../../../utils/nflUtils';
import Select from 'react-select';

const TeamSelector = (props) => {
    const [critVal, setCritVal] = useState({
        teams: [],
        label: "",
        type: "",
        homeAway: ""
    });
    const [err, setErr] = useState(false);
    const [initTeams, setInitTeams] = useState([]);
    const [critIdx, setCritIdx] = useState(null);
    const [groupedOptions, setGroupedOptions] = useState([]);
    const [sportInfo, setSportInfo]= useState(null)
    const [teams, setTeams] = useState([]);

    // run when sysContext changes
    useEffect(()=>{
        const sport = props.sysContext.sport;
        if(sport === "mlb"){
            setSportInfo({
                teams: mlbTeams,
                groups: mlbLeagues
            });
        }else if(sport === "nfl"){
            setSportInfo({
                teams: nflTeams,
                groups: nflConferences
            });
        };
    },[props.sysContext]);

    // run when sport info changes - create option groups for select
    useEffect(()=>{
        if(sportInfo){
            let tmpGroups = [];
            for(const group in sportInfo.groups){
                for(const subGroup in sportInfo.groups[group]){
                    const options = sportInfo.groups[group][subGroup].map(team => ({
                        value: team, 
                        label: sportInfo.teams[team]
                    }));

                    tmpGroups.push({
                        label: `${group} ${subGroup}`,
                        options: options
                    });
                };
            };
            setGroupedOptions(tmpGroups);
        };
    },[sportInfo]);

    // run when init crit changes
    useEffect(()=> {
        // check if user is editing an existing criteria
        if(props.initCrit){
            const critCopy = {...props.initCrit.crit};
            setCritVal(critCopy);
            setTeams(critCopy.teams);
            setInitTeams(critCopy.teams);
            setCritIdx(props.initCrit.index);
        };
    },[props.initCrit]);

    // set submit function in parent component
    const validateSubmit = (e) => {
        e.preventDefault();
        // make sure criteria is not null
        if(critVal.teams.length > 0){
            if(critIdx !== null){
                const sameLength = critVal.teams.length === initTeams.length
                const sameTeams = critVal.teams.every(element => initTeams.includes(element));
                if(!sameLength || !sameTeams){
                    props.sysContext.changeCrit(critVal, critIdx);
                }
            }else{
                props.sysContext.changeCrit(critVal);
            };
            props.sysContext.setStep(2);
        }else{
            setErr(true);
        };
    };

    // Function to generate criteria label
    const generateLabel = () => {
        let label = "";
        const homeAwayLbl = props.homeAway === "home" ? "Home team" : "Away team";
        if(teams.length === 1){
            label = `${homeAwayLbl} is ${sportInfo.teams[teams[0]]}`;
        }else if(teams.length > 1){
            label = `${homeAwayLbl} is one of `;
            const lastIdx = teams.length-1;
            teams.forEach((team, i) => {
                const long = sportInfo.teams[team];
                if(i !== lastIdx){
                    label = label+long+", ";
                }else{
                    label = label+long;
                };
            });
        };

        return label;
    };

    // run when teams or homeAway prop changes - update crit
    useEffect(()=>{
        const tmpCrit = {...critVal};
        tmpCrit.homeAway = props.homeAway;
        tmpCrit.type = `team.${props.homeAway}-team-sel`;
        tmpCrit.teams = teams;
        const label = generateLabel();
        tmpCrit.label = label;
        setCritVal(tmpCrit);
    },[teams, props.homeAway]);

    const handleChange = (sel) => {
        const values = sel ? sel.map(opt => opt.value) : [];
        setTeams(values);
    };

    return (
        <>
            <div id='header-row'>
                <h2>
                    {props.homeAway === "home" ? ("Home ") : ("Away ")} Team Selector
                </h2>
            </div>
            <Select 
                isMulti
                options={groupedOptions}
                classNamePrefix="team-select-option"
                className='sysField'
                value={groupedOptions.reduce((acc, group) => {
                    acc.push(...group.options.filter(option => teams.includes(option.value)));
                    return acc;
                }, [])}
                onChange={handleChange}
                closeMenuOnSelect={false}
            />
            {err && (<p className='errMsg'>Please make a selection, or click cancel to go back.</p>)}
            <div id='submit-btns'>
                <button className="secondaryBtn" id="cancelCritBtn" onClick={()=>props.cancel()}>Cancel</button>
                <button className="primaryBtn" id="saveCritBtn" onClick={(e)=>validateSubmit(e)}>Save Criteria</button>
            </div>
        </>
    );
};

export default TeamSelector;