import React, { useState, useEffect } from 'react';
import { Form, FloatingLabel, OverlayTrigger, Popover, Button} from 'react-bootstrap';
import NflCriteria from './NflCriteria';
import {ReactComponent as HelpIcon} from '../../../images/icons/help.svg';

const NflSystem = (props) => {    
    const [errs, setErrs] = useState([]);

    // run whenever errs changes
    useEffect(()=>{
        // get all elements previously in error state and remove class
        let prevErrs = document.getElementsByClassName("err");
        while(prevErrs.length){
            prevErrs[0].classList.remove("err");
        };
        // add err class based on updated errs
        errs.forEach(err=>{
            console.log(err)
            document.getElementById(err).classList.add("err");
        });
    },[errs]);

    // handle bet changing and set bet and its label 
    const handleBetChange = (bet, label) => {
        props.setBet(bet);
        props.setBetLabel(label);
    };
     
    // function to validate system
    const validateSystem = ()=>{
        let tmpErrs = [];
        // needs a name, at least 1 criteria, and a bet
        if(props.name.length < 1 ){
            tmpErrs.push("name");
        };
        if(props.criteria.length < 1){
            tmpErrs.push("nflCriteriaDiv");
        };
        if(props.bet.length < 1){
            tmpErrs.push("bet");
        }

        setErrs(tmpErrs);
        if(tmpErrs.length > 0){
            return false;
        }else return true;    
    };

    //Function to create system record in database
    const showBacktest = (e) => {
        // prevent form from clearing
        e.preventDefault();
        // validate system 
        let tf = validateSystem();
        if(tf) props.backtest();
    }

    return (
        <div className='page-section'>
            <div id='sysFormTopRow'>
                {props.pageType === "edit" ?
                    (<h2>Edit System</h2>)
                    :
                    (<h2>Create System</h2>)
                }
                <OverlayTrigger trigger="click" placement='left' rootClose={true}
                    overlay={
                        <Popover>
                            <Popover.Header>Creating a System</Popover.Header>
                            <Popover.Body>
                                <p>Follow these steps:</p> 
                                <ul>
                                    <li>Name the system</li>
                                    <li>Add at least one criteria</li>
                                    <li>Choose a bet to backtest against</li>
                                </ul>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <HelpIcon alt="help-icon" id="help-icon" className='button'/>
                </OverlayTrigger>
            </div>
            <Form id="systemForm">
                {props.pageType =="new" && (
                <div className="switch-wrapper">
                    <div className="switch-radio">
                        <input type="radio" id="private" name="systemType" checked={props.sysType == "private"} onChange={()=>props.setSysType("private")}/>
                        <label for="private" >Private</label>
                    </div>
                    <div className="switch-radio">
                        <input type="radio" id="public" name="systemType" checked={props.sysType == "public"} onChange={()=>props.setSysType("public")}/>
                        <label for="public">Public</label>
                    </div>
                </div>
                )}
                <Form.Group>
                    <FloatingLabel label="Enter System Name" >
                        <Form.Control id="name" type="text" placeholder='Enter System Name' value={props.name} onChange={(e)=>props.setName(e.target.value)}/>
                    </FloatingLabel>
                </Form.Group>
                <Form.Group>
                    <NflCriteria crit={props.crit} addCriteria={props.addCriteria} updateIdx={props.updateIdx} setUpdateIdx={props.setUpdateIdx} criteria={props.criteria} removeCrit={props.removeCrit}/>
                </Form.Group>
                <FloatingLabel label='Choose Bet' id="bet-div">
                    <Form.Control id="bet" as="select" value={props.bet} onChange={(e)=>handleBetChange(e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                        <option value=""></option>
                        <option value={"home.ml"} key={"home.ml"} >{"home ml"}</option>
                        <option value={"home.spread"} key={"home.spread"} >{"home spread"}</option>
                        <option value={"away.ml"} key={"away.ml"} >{"away ml"}</option>
                        <option value={"away.spread"} key={"away.spread"} >{"away spread"}</option>
                        <option value={"over"} key={"over"} >{"over"}</option>
                        <option value={"under"} key={"under"} >{"under"}</option>
                    </Form.Control>
                </FloatingLabel>            
                <Button className='primaryBtn' id="backtestBtn" onClick={(e)=>showBacktest(e)} >
                    Backtest System
                </Button>
            </Form>
        </div>
    )
}

export default NflSystem