import '../../../scss/pages/NflSystem/createNflSystem.scss';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import apiService from '../../../utils/apiService';
import BacktestDisp from './BacktestDisp';
// Components 
import NflSystem from './NflSystem';
import AuthProtected from '../../HOCs/AuthProtected';

const BuildSystem = () => {
    const [system, setSystem] = useState({});
    const [upcomingMatches, setUpcomingMatches] = useState([]);
    const [step, setStep] = useState(1);
    const [name, setName] = useState('');
    const [sysType, setSysType] = useState('private');
    const [criteria, setCriteria] = useState([]);
    const [updateIdx, setUpdateIdx] = useState(-1);
    const [bet, setBet] = useState("");
    const [betLabel, setBetLabel] = useState("");
    const [id, setId] = useState("");
    const location = useLocation();
    const pageType = location.state.pageType;

    // Run when component is rendered, if system is passed - update system, if no system is passed - create new system
    useEffect(()=>{
        if(pageType === "edit" || pageType === "view"){
            let system = location.state.system;
            setName(system.name);
            setCriteria(system.criteria);
            setBet(system.bet);
            setId(system._id);
        };
    },[pageType]);
    
    //Method to show system
    function dispSys(){
        setStep(1);
    };

    //Method to show backtesting
    function dispBacktest(){
        setStep(2);
    };

    // function to add new or update criteria
    const addCriteria = (crit, i) => {
        console.log(crit)
        let tmpCrit = criteria;
        if(i !== -1){
            tmpCrit[i] = crit;
        }else tmpCrit.push(crit);
        setCriteria(tmpCrit);
    };

    // function to remove criteria 
    const removeCrit = (i) =>{
        let tmpCrit = [...criteria];
        tmpCrit.splice(i, 1);
        setCriteria(tmpCrit);
    }

    // function to send system for backtesting
    const backtest = async () => {
        let tmpSystem = {
            name: name, 
            sport: "nfl",
            criteria: criteria, 
            bet: bet,
            betLabel: betLabel,
            units: 1,
            sysType: sysType
        };
        
        // send system to application for backtesting results
        const response = await apiService('nfl/backtest-system', 'POST', tmpSystem);
        setSystem({...system, matches: response.system_matches});
        setUpcomingMatches(response.upcoming_matches);
        dispBacktest();
    };

    switch(step){
        case 1:
            return (
                <NflSystem name={name} bet={bet} setBetLabel={setBetLabel}
                        criteria={criteria} setName={setName} 
                        setBet={setBet} backtest={backtest} removeCrit={removeCrit}
                        pageType={location.state.pageType} addCriteria={addCriteria} 
                        updateIdx={updateIdx} setUpdateIdx={setUpdateIdx}
                        sysType={sysType} setSysType={setSysType}
                />
            )
        case 2: 
            return (
                <BacktestDisp dispSys={dispSys} 
                    system={system}
                    upcomingMatches={upcomingMatches}
                    id={id}
                    pageType={pageType} 
                />
            )
    };
    return(<></>)
};

export default AuthProtected(BuildSystem);