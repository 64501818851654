import React, {useState, useEffect} from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { isString } from 'lodash';

const StatSelection = (props) => {
    const [step, setStep] = useState(1);
    const [cat, setCat] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const subCats = ["passing", "receiving", "rushing", "general", "tackles", "fumbles"]
    // run on render, if stat is already prefilled - set step accordingly
    useEffect(()=>{
        if(props.stat !== null && props.stat !== "" && props.stat !== undefined){
            // get cat and subcat 
            const catArr = props.stat.split(".");
            const tmpCat = catArr[1];
            setCat(tmpCat);
            const tmpSubCat = catArr[2];
            if(subCats.includes(tmpSubCat)){
                setSubCat(tmpSubCat);
            }else{
                setSubCat("general")
            }
            setStep(3);
        };
    },[]);

    // Step 1 selection
    const handleStatCatSelection = (val) => {
        setCat(val);
        setStep(2);
    };

    // Step 2 subcategory selection
    const handleStatSubCatSelection = (val) => {
        setSubCat(val);
        setStep(3);
    };
    
    const prevStep = () => {
        setStep(step-1);
    };

    return (
        <>
            {props.oneOrTwo === 1 && (
                <h6 id="instr">Pick your first stat</h6>
            )}
            {props.oneOrTwo === 2 && (
                <h6 id="instr">Pick your second stat</h6>
            )}
            {step === 1 && 
                <>
                    <div id="critStep">
                        <Button className='secondaryBtn' onClick={()=>handleStatCatSelection("off")}>Offensive</Button>
                        <Button className='secondaryBtn' onClick={()=>handleStatCatSelection("def")}>Defensive</Button>
                        {/* <Button className='secondaryBtn' onClick={()=>handleStatCatSelection("st")}>Special Teams</Button>
                        <Button className='secondaryBtn' onClick={()=>handleStatCatSelection("penalties")}>Penalties</Button> */}
                    </div>
                    <div id="critBtns">
                        <Button onClick={()=>props.back()}>Back</Button>
                    </div>
                </>
            }
            {step === 2 &&
                <>  
                    <div id="critStep">
                        {cat === "off" && 
                            <>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("passing")}>Passing</Button>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("rushing")}>Rushing</Button>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("receiving")}>Receiving</Button>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("general")}>General</Button>
                            </>
                        }
                        {cat === "def" && 
                            <>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("passing")}>Passing</Button>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("tackles")}>Tackles</Button>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("fumbles")}>Fumbles</Button>
                                <Button className='secondaryBtn' onClick={()=>handleStatSubCatSelection("general")}>General</Button>
                            </>
                        }
                        {/* {cat === "st" && 
                            <></>
                        }
                        {cat === "penalties" && 
                            <></>
                        } */}
                    </div>
                    <div id="critBtns">
                        <Button onClick={()=>prevStep()}>Back</Button>
                    </div>
                </>            
            }
            {step === 3 && 
                <>
                    <div id="critStep">
                        {cat === "off" && 
                            <>
                                {subCat === "passing" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home Offensive Passing Stats</option>
                                            {Object.keys(props.offStats.passing).map(stat=>{
                                                return <option key={stat} value={`home.off.passing.${stat}`}>home {props.offStats.passing[stat]}</option>
                                            })}
                                            <option disabled className='dropdown-cat'>Away Offensive Passing Stats</option>
                                            {Object.keys(props.offStats.passing).map(stat=>{
                                                return <option key={stat} value={`away.off.passing.${stat}`}>away {props.offStats.passing[stat]}</option>
                                            })}
                                        </Form.Control>
                                    </FloatingLabel> 
                                }
                                {subCat === "rushing" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home Offensive Rushing Stats</option>
                                            {Object.keys(props.offStats.rushing).map(stat=>{
                                                return <option key={stat} value={`home.off.rushing.${stat}`}>home {props.offStats.rushing[stat]}</option>
                                            })}
                                            <option disabled className='dropdown-cat'>Away Offensive Rushing Stats</option>
                                            {Object.keys(props.offStats.rushing).map(stat=>{
                                                return <option key={stat} value={`away.off.rushing.${stat}`}>away {props.offStats.rushing[stat]}</option>
                                            })}
                                        </Form.Control>
                                    </FloatingLabel>
                                }
                                {subCat === "receiving" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home Offensive Receiving Stats</option>
                                            {Object.keys(props.offStats.receiving).map(stat=>{
                                                return <option key={stat} value={`home.off.receiving.${stat}`}>home {props.offStats.receiving[stat]}</option>
                                            })}
                                            <option disabled className='dropdown-cat'>Away Offensive Receiving Stats</option>
                                            {Object.keys(props.offStats.receiving).map(stat=>{
                                                return <option key={stat} value={`away.off.receiving.${stat}`}>away {props.offStats.receiving[stat]}</option>
                                            })}
                                        </Form.Control>
                                    </FloatingLabel>
                                }
                                {subCat === "general" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home General Offensive Stats</option>
                                            {Object.keys(props.offStats).map(stat=>{
                                                if(isString(props.offStats[stat])){
                                                    return <option key={stat} value={`home.off.${stat}`}>home {props.offStats[stat]}</option>
                                                }
                                            })}
                                            <option disabled className='dropdown-cat'>Away General Offensive Stats</option>
                                            {Object.keys(props.offStats).map(stat=>{
                                                if(isString(props.offStats[stat])){
                                                    return <option key={stat} value={`away.off.${stat}`}>away {props.offStats[stat]}</option>
                                                }                                        
                                            })}
                                        </Form.Control>
                                    </FloatingLabel>
                                }
                            </>
                        }
                        {cat === "def" && 
                            <>
                                {subCat === "passing" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home Defensive Passing Stats</option>
                                            {Object.keys(props.defStats.passing).map(stat=>{
                                                return <option key={stat} value={`home.def.passing.${stat}`}>home {props.defStats.passing[stat]}</option>
                                            })}
                                            <option disabled className='dropdown-cat'>Away Defensive Passing Stats</option>
                                            {Object.keys(props.defStats.passing).map(stat=>{
                                                return <option key={stat} value={`away.def.passing.${stat}`}>away {props.defStats.passing[stat]}</option>
                                            })}
                                        </Form.Control>
                                    </FloatingLabel> 
                                } 
                                {subCat === "tackles" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home Defensive Tackling Stats</option>
                                            {Object.keys(props.defStats.tackles).map(stat=>{
                                                return <option key={stat} value={`home.def.tackles.${stat}`}>home {props.defStats.tackles[stat]}</option>
                                            })}
                                            <option disabled className='dropdown-cat'>Away Defensive Tackling Stats</option>
                                            {Object.keys(props.defStats.tackles).map(stat=>{
                                                return <option key={stat} value={`away.def.tackles.${stat}`}>away {props.defStats.tackles[stat]}</option>
                                            })}
                                        </Form.Control>
                                    </FloatingLabel> 
                                } 
                                {subCat === "fumbles" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home Defensive Fumble Stats</option>
                                            {Object.keys(props.defStats.fumbles).map(stat=>{
                                                return <option key={stat} value={`home.def.fumbles.${stat}`}>home {props.defStats.fumbles[stat]}</option>
                                            })}
                                            <option disabled className='dropdown-cat'>Away Defensive Fumble Stats</option>
                                            {Object.keys(props.defStats.fumbles).map(stat=>{
                                                return <option key={stat} value={`away.def.fumbles.${stat}`}>away {props.defStats.fumbles[stat]}</option>
                                            })}
                                        </Form.Control>
                                    </FloatingLabel> 
                                }
                                {subCat === "general" && 
                                    <FloatingLabel label='Stat' id="stat-div">
                                        <Form.Control id="stat"  defaultValue={props.stat}  as="select" onChange={(e)=>props.handleStatSelection(props.oneOrTwo, e.target.value, e.target.options[e.target.selectedIndex].innerText)}>
                                            <option hidden value=""></option>
                                            <option disabled className='dropdown-cat'>Home General Defensive Stats</option>
                                            {Object.keys(props.defStats).map(stat=>{
                                                if(isString(props.defStats[stat])){
                                                    return <option key={stat} value={`home.def.${stat}`}>home {props.defStats[stat]}</option>
                                                }
                                            })}
                                            <option disabled className='dropdown-cat'>Away General Defensive Stats</option>
                                            {Object.keys(props.defStats).map(stat=>{
                                                if(isString(props.defStats[stat])){
                                                    return <option key={stat} value={`away.def.${stat}`}>away {props.defStats[stat]}</option>
                                                }
                                            })}
                                        </Form.Control>
                                    </FloatingLabel> 
                                } 
                            </>
                        }
                        {/* {cat === "st" && 
                            <></>
                        }
                        {cat === "penalties" && 
                            <></>
                        } */}
                    </div>
                    <div id="critBtns">
                        <Button onClick={()=>prevStep()}>Back</Button>
                        <Button onClick={()=>props.next(`stat${props.oneOrTwo}`)}>Next</Button>
                    </div>
                </>
            }
        </>
    )

}

export default StatSelection;