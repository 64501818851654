import React, {useState, useEffect} from 'react'
import { Chart, LinearScale, Title, LineElement,  CategoryScale, Tooltip } from "chart.js";
import { Line } from 'react-chartjs-2';
const WinPerChart = (props) => {
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);

    useEffect(()=>{
        if(props.sznWL.length > 0){
            Chart.register(LinearScale, LineElement, CategoryScale, Title, Tooltip );
            // build datasets and labels arrays
            let labels = [], prof = [], total_prof = 0;
            for(const szn of props.sznWL){
                // add prof of season to total profit 
                total_prof += parseFloat(szn.prof);
                prof.push(total_prof);
                labels.push(szn.season);
            };
            // chart options 
            const tmpOptions = {
                scales: {
                    y :{
                        grid:{
                            display:false
                        }
                    },
                    x :{
                        grid:{
                            display:false
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Total Profit (units)',
                        position: 'bottom',
                        padding:{
                            top: 10,
                        }
                    }
                }
            }
            setOptions(tmpOptions);

            // data 
            let tmpData = {
                labels: labels,
                datasets: [
                    {
                        fill: {
                            target: 'origin',
                            below: 'rgba(255, 45, 85, .75)',
                            above: 'rgba(52, 199, 89, .75)'
                        },
                        data: prof,
                        pointRadius: 0,
                        tension: 0.4,
                        borderWidth: 1,
                        borderColor:"#dcdfe0"
                    }
                ] 
            };
            setData(tmpData);
        };
    },[props.sznWL])
    
    if(options && data){
        return (
            <Line options={options} data={data} />
        );
    }else{
        return(<></>)
    }

};

export default WinPerChart;